.edit-sources {
  .modal-content {
    background: $grey-color-light-bg;
  }

  &.modal-dialog {
    max-width: 940px;
  }

  .sources-filter {
    margin: 16px 0 0;
  }
}
