// Colors
$Actors : #9F55A1;
$Actors-alfa : rgba(159,85,161,0.7);
$Stories : #AD6A05;
$Stories-alfa : rgba(173,106,5,0.7);
$Threats : #FF4040;
$Threats-alfa : rgba(255,64,64,0.7);
$Solutions : #1DB534;
$Solutions-alfa : rgba(29,181,52,0.7);
$Companies : #5D61E7;
$Companies-alfa : rgba(93,97,231,0.7);
$Experts : #15AFD1;
$Experts-alfa : rgba(21,175,209,0.7);
$Breaches : #E617D1;
$Breaches-alfa : rgba(230,23,209,0.7);

.category-buttons {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
	  padding: 0 16px;

    @media screen and (max-width: 767px) {
  		margin-top: 0;
  	}
}

.basic{
    padding: 5px 5px !important;
    font-size: 11px !important;
}

.cat-button{
  background: rgba(255,255,255,.75);
	// border-radius: 5px;
	// border: 1px solid #dcdcdc;
  display:inline-block;
  cursor: pointer;
	color: #C7C7C7;
	font-size: 12px;
	padding: 3px 10px;
	text-decoration:none;
  margin-right: 8px;
  @media screen and (max-width: 940px) {
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 3px 4px;
    font-size: 11px;
  }
}

.Breaches {
	color: $Breaches;
  border: 1px $Breaches-alfa solid;
  border-radius: 3px;
  &:hover, &:focus {
    box-shadow: none;
    outline: none;
    background: $Breaches;
    color: #fff;
    text-decoration: none;
  }
}

.Stories {
	color: $Stories;
  border: 1px $Stories-alfa solid;
  border-radius: 3px;
  &:hover, &:focus {
    box-shadow: none;
    outline: none;
    background: $Stories;
	  color: #fff;
    text-decoration: none;
  }
}

.Actors {
	color: $Actors;
  border: 1px $Actors-alfa solid;
  border-radius: 3px;
  &:hover, &:focus {
    box-shadow: none;
    outline: none;
    background: $Actors;
    color: #fff;
    text-decoration: none;
  }
}

.Threats {
	color: $Threats;
  border: 1px $Threats-alfa solid;
  border-radius: 3px;
  &:hover, &:focus {
    box-shadow: none;
    outline: none;
    background: $Threats;
    color: #fff;
    text-decoration: none;
  }
}

.Solutions {
	color: $Solutions;
  border: 1px $Solutions-alfa solid;
  border-radius: 3px;
  &:hover, &:focus {
    box-shadow: none;
    outline: none;
    background: $Solutions;
    color: #fff;
    text-decoration: none;
  }
}

.Companies {
	color: $Companies;
  border: 1px $Companies-alfa solid;
  border-radius: 3px;
  &:hover, &:focus {
    box-shadow: none;
    outline: none;
    background: $Companies;
    color: #fff;
    text-decoration: none;
  }
}

.Experts {
	color: $Experts;
  border: 1px $Experts-alfa solid;
  border-radius: 3px;
  &:hover, &:focus {
    box-shadow: none;
    outline: none;
    background: $Experts;
    color: #fff;
    text-decoration: none;
  }
}

.sort-by {
  display: inline-block;
  font-size: 12px;
  color: #000;
  margin-bottom: 0;
}

.sort-order {
  padding: 0;
}

.toggle-icons {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  padding-top: 2px;
  color: #414141;

  i {
    &.active {
      color: #3B5998;
    }
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
}
