.mittre-table-popup {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  max-width: 98%;

  .modal-content {
    width: 100%;
    display: block;
  }

  &__table {
    max-width: 100%;
    overflow: auto;
    max-height: 85%;
    border: 1px solid $stroke-color;
  }

  .modal-header .close {
    top: -16px;
    right: -10px;
    opacity: 1;
    position: absolute;

    span:first-child {
      background: $grey-color-light;
      opacity: 1;
      border-radius: 50%;
      opacity: 1;

      &:before,
      &:after {
        height: 20px;
        top: 6px;
      }
    }

    &:hover {
      span:first-child {
        background: $theme-color;

        &:before,
        &:after {
          background-color: $white-color;
        }
      }
    }
  }

  &__head {
    display: block;
    padding-top: 10px !important;
    position: relative;

    img {
      width: 60px;
      height: 42px;
      margin-right: 8px;
      object-fit: cover;
      border-radius: 4px;
    }

    i {
      color: $grey-color-secondary;

      &:hover {
        color: $theme-color;
      }

      &.active {
        color: $theme-color;

        &:hover {
          color: darken($theme-color, 10%);
        }
      }
    }

    p {
      margin-bottom: 0;
    }
  }

}

.mittre-table-popup__table-head {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  min-height: 50px;


  &-number {
    display: block;
    padding-right: 6px;
    white-space: nowrap;
    font-weight: 600;
  }

  &-label {
    font-weight: bold;
  }

  &-th {
    padding: 12px;
    width: 7.14%;
    min-width: 110px;
    text-transform: uppercase;
    color: $dark-grey;
    font-size: 10px;
    white-space: pre-wrap;
    word-break: keep-all;
    background: rgba(216, 222, 231, .5);
    border-right: 1px solid $stroke-color;
    border-bottom: 1px solid $stroke-color;
    line-height: 1.25;
    letter-spacing: normal;
  }

}

.mittre-table-popup__table-body {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  //height: calc(100vh - 50px);
  white-space: normal;

  &-td {
    min-width: 110px;
    width: 7.14%;
    padding: 16px 8px;
    color: $black;
    background: white;
    border-right: transparent;
    text-align: left;

    &:last-of-type {
      border-right: none;
    }

    .stage-card-content {
      overflow: hidden;
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 8px;
    }

    .stage-card-content-inner {
      cursor: pointer;
      padding: 8px 0 0;
      margin: 0 auto 12px;
      text-align: center;
      border: 1px solid $dm-text-light-grey;
      border-radius: 6px;

      &.mitre {
        border-top: 3px solid $red-color !important;
      }

      &.trukno {
        border-top: 3px solid #185cee !important;
      }

      p {
        text-align: left;
        font-size: 12px;
        margin-bottom: 8px;
        letter-spacing: -.01px;
      }

      &:hover {
        border-color: $theme-color !important;
      }
    }

    .stage-card-content-inner__foter {
      padding: 4px 8px;
      margin-top: 8px;
      border-radius: 0 0 6px 6px;
      background: $grey-color-light-bg;
    }

    .stage-card-content__description {
      display: none;
    }

    img {
      display: none;
      // width: 28px !important;
      // height: 28px !important;
      object-fit: cover;
      border-radius: 50%;
      margin-bottom: 0;
    }

    .number {
      display: block;
      padding: 0 2px;
      margin-left: 4px;
      font-size: 8px;
      border: 1px solid $border-light-grey;
      border-radius: 4px;
      line-height: 1.4;
      white-space: wrap;
    }
  }
}

.toggles-list {
  .nav-link {
    padding: 0;
    margin: 0 4px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    color: $theme-text-color-medium;
    font-size: 14px;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      color: $theme-color;
    }

    &.active {
      background: $grey-color-light-bg;
      color: $theme-color;
      border-radius: 3px;
    }
  }
}

.stage-content {
  max-height: 60vh;
  overflow: overlay;
}

.stage-content::-webkit-scrollbar {
  display: none;
}