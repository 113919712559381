video {
  outline: none!important;
  &:focus {
    outline:none !important;
  }
}
.hero-section {
  padding: 80px 0;
  height: calc(100vh - 60px);
  background: #fff;
  position: relative;
  @media screen and (max-width: 991px) {
    text-align: center;
    height: auto;
    padding-top: 40px;
  }

  h1 {
    font-weight: bold;
    font-size: 64px;
    @media screen and (max-width: 1024px) {
      font-size: 48px;
    }
    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
    strong {
      font-weight: 900;
      color: #3B5998;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 28px;
    margin-top: 24px;
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  @keyframes bounce {
  	0%, 100%, 20%, 50%, 80% {
  		-webkit-transform: translateY(0);
  		-ms-transform:     translateY(0);
  		transform:         translateY(0)
  	}
  	40% {
  		-webkit-transform: translateY(-8px);
  		-ms-transform:     translateY(-8px);
  		transform:         translateY(-8px)
  	}
  	60% {
  		-webkit-transform: translateY(-4px);
  		-ms-transform:     translateY(-4px);
  		transform:         translateY(-4px)
  	}
  }

  .toggle-next-section {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;

    &:hover {
      -webkit-animation-name: bounce;
    	animation-name: bounce;
    	-webkit-transform-origin: center bottom;
    	transform-origin: center bottom;
    }
    i {
      font-size: 36px;
    }
  }

  video {
    box-shadow: 0 0 16px rgba(0,0,0,.10);
  }
}

.img-shadow {
  display: inline-block;
  box-shadow: 0 0 18px rgba(0,0,0,.25);
}

.about-us-section, .simple-section {
  padding: 80px 0;
  min-height: 100vh;
  @media screen and (max-width: 991px) {
    min-height: inherit;
    padding: 40px 0;
  }
}

.simple-section {
  background: #fff;
  h2 {
    font-size: 56px;
    font-weight: bold;
    margin-bottom: 16px;
    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
  }

  h3 {
    font-weight: 300;
    font-size: 24px;
    margin-bottom: 60px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
}

.about-us-cards-list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 40px;
  @media screen and (max-width: 767px) {
    padding-top: 20px;
  }
}

.about-us-card {
  width: 220px;
  height: 220px;
  text-align: center;
  border-radius: 2px;
  padding: 20px 16px;
  margin: 16px;

  @media screen and (max-width: 767px) {
    width: 47%;
    height: 180px;
    padding: 8px;
    margin: 4px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    margin: 0 0 8px;
  }

  h3 {
    font-weight: 700;
    font-size: 28px;
    margin: 0 0 8px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
    @media screen and (max-width: 360px) {
      font-size: 24px;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
    @media screen and (max-width: 360px) {
      font-size: 18px;
    }
  }

  .number {
    font-size: 48px;
    font-weight: 900;
    margin-bottom: 4px;
    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
    @media screen and (max-width: 360px) {
      font-size: 32px;
    }
  }

  span {
    display: block;
    line-height: 1.25;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }

  }

  &.threats {
    border: 2px solid #FF4040;
    .number {
      color: #FF4040;
    }
    h3 {
      color: #FF4040;
    }
  }
  &.actors {
    border: 2px solid #9F55A1;
    .number {
      color: #9F55A1;
    }
    h3 {
      color: #9F55A1;
    }
  }
  &.stories {
    border: 2px solid #AD6A05;
    .number {
      color: #AD6A05;
    }
    h3 {
      color: #AD6A05;
    }
  }
  &.solutions {
    border: 2px solid #1DB534;
    .number {
      color: #1DB534;
    }
    h3 {
      color: #1DB534;
    }
  }
  &.experts {
    border: 2px solid #15AFD1;
    .number {
      color: #15AFD1;
    }
    h3 {
      color: #15AFD1;
    }
  }
  &.companies {
    border: 2px solid #5D61E7;
    .number {
      color: #5D61E7;
    }
    h3 {
      color: #5D61E7;
    }
  }
}

.about-us-description {
  font-size: 28px;
  line-height: 1.5;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    padding-top: 0;
    text-align: center;
  }
  strong {
    color: #3B5998;
  }
  h2 {
    font-size: 56px;
    font-weight: bold;
    margin-bottom: 16px;

    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
  }

  h3 {
    font-weight: 300;
    font-size: 24px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

}

.video-blog-section {
  padding: 80px 0;
  background: #fff;
  @media screen and (max-width: 991px) {
    padding: 40px 0;
  }

  h2 {
    font-weight: 700;
    font-size: 48px;
    text-align: center;
    margin-bottom: 30px;
    @media screen and (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
}


.blog-card {
  display: inline-flex;
  justify-content: center;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  img {
    max-height: 230px;
  }

  h4 {
    font-weight: 700;
    font-size: 24px;
    margin: 16px 0;
    text-align: center;
  }

  p {
    font-size: 18px;
  }

  .btn {
    width: 200px;
    margin-top: 16px;
  }

  .date {
    font-size: 12px;
    display: block;
    margin-top: 8px;
    opacity: .5;
  }
}

.webinar-section {
  padding: 80px 0;
  background: #fff;
  @media screen and (max-width: 991px) {
    padding: 40px 0;
    overflow-x: hidden;
  }

  .slick-slider {
    padding: 0 20px;
  }
  h2 {
    font-weight: 700;
    font-size: 48px;
    text-align: center;
    margin-bottom: 30px;
    @media screen and (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
}

.teams-section {
  background: transparent;
  @extend .video-blog-section;
}

.team-block {
  position: relative;
  margin: 16px;
  @media screen and (max-width: 767px) {
    margin: 8px;
  }
  &-description {
    padding: 12px;
    background: #fff;
    position: relative;
    top: -30px;
    left: -10px;
    z-index: 2px;
    width: 95%;
    box-shadow: 0 0 6px rgba(0,0,0,.15);

    h3 {
      margin-bottom: 4px;
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.contact-us-section {
  padding: 80px 0;
  background: #3B5998;
  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }
  h2 {
    font-weight: 700;
    font-size: 48px;
    text-align: center;
    margin-bottom: 40px;
    color: #fff;
    @media screen and (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }
  form {
    max-width: 780px;
    margin: 0 auto;
  }

  .form-control {
    background: transparent;
    border: 1px solid rgba(255,255,255,1);
    color: #fff;
    &:focus {
      background: #fff;
      color: #3B5998;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba(255,255,255,.5);
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: rgba(255,255,255,.5);
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: rgba(255,255,255,.5);
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: rgba(255,255,255,.5);
    }
  }

  .btn-primary {
    background: #FF4040;
  }
}

.targeted-users {
  padding: 80px 0;
  min-height: 100vh;

  @media screen and (max-width: 991px) {
    padding: 40px 0;
    min-height: auto;
  }

  @media screen and (max-width: 767px) {
    padding: 40px 0;
    min-height: auto;
  }

  h2 {
    font-size: 56px;
    font-weight: bold;
    margin-bottom: 16px;
    line-height: 1.5;
    text-transform: capitalize;
    @media screen and (max-width: 767px) {
      font-size: 32px;
      text-align: center;
      padding-bottom: 0;
      padding-top: 0;
      margin-bottom: 16px;
    }
  }

  h3 {
    font-weight: 300;
    font-size: 24px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
}

.targeted-users-list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  padding-top: 40px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    padding-top: 30px;
  }

  .targeted-users-item {
    width: 50%;
    padding: 16px;
    position: relative;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 8px;
    }

    img {
      object-fit: cover;
      min-height: 200px;
    }

    &:after {
      content: "";
      background: rgba(0,0,0,.5);
      position: absolute;
      left: 16px;
      right: 16px;
      top: 16px;
      bottom: 16px;
      @media screen and (max-width: 767px) {
        left: 8px;
        right: 8px;
        top: 8px;
        bottom: 8px;
      }
    }

    .targeted-users-title {
      position: absolute;
      padding: 16px;
      bottom: 8px;
      left: 8px;
      right: 8px;
      font-size: 20px;
      z-index: 2;
      color: #fff;
      font-weight: 600;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
}

.to-top {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: fixed;
  right: 20px;
  bottom: 70px;
  z-index: 20;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0,0,0,.5);
  text-decoration: none!important;
  &:hover {
    text-decoration: none!important;
  }
}

.feedback {
  background-color: #3B5998;
  color: white;
  transform: rotate(-90deg);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  position: fixed;
  right: -30px;
  bottom: 50%;
  z-index: 20;
  border-radius: 3px 3px 0 0;
  text-decoration: none!important;
  @media screen and (max-width: 767px) {
    font-size: 10px;
    right: -25px;
  }
  &:hover {
    color: white;
    background-color: darken(#3B5998, 10%);
  }

}

.main-area .to-top {
  bottom: 30px;
}


.video {
  width: 100%;
  height: 500px;
}

.socials {
  display: none;
  flex-flow: column;
  position: fixed;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  z-index: 2;
  &.show-large {
    @media screen and (min-width: 1366px) {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
    }
  }
  @media screen and (max-width: 991px) {
    position: relative;
    top: 0;
    left: 0;
    flex-flow: row;
    transform: translateY(0);
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    margin: 8px;
    padding: 8px;
    border-radius: 50%;
    background: #212529;
    @media screen and (max-width: 991px) {
      width: 24px;
      height: 24px;
      padding: 6px;
      margin: 0 6px;
    }
    &:hover {
      background: #3B5998;
    }
    &.facebook {
      padding: 10px;
      @media screen and (max-width: 991px) {
        padding: 8px;
      }
    }
  }

  &.secondary {
    position: absolute;
    top: -80px;
    right: 0;
    transform: none;
    padding-top: 30px;
  }
}

.slick-prev {
  height: 50px;
  width: 50px;
  font-size: 50px;
  left: -30px;
  @media screen and (max-width: 991px) {
    left: -15px;
  }
}

.slick-next {
  height: 50px;
  width: 50px;
  font-size: 50px;
  right: -30px;
  @media screen and (max-width: 991px) {
    right: -15px;
  }
}

.slick-next:before{
  content: "keyboard_arrow_right";
  color: #212529;
  font-size: 50px;
  opacity: .3;
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
}

.slick-prev:before {
  content: "keyboard_arrow_left";
  color: #212529;
  font-size: 50px;
  opacity: .3;
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
}

.footer {
  padding: 8px 0;
  background: #212529;
  .socials {
    position: relative;
    flex-flow: row;
    left: 0;
    top: 0;
    transform: none;
  }
}
