h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
  color: $theme-text-color;
  font-family: $roboto;
}

.list-simple {
  li {
    white-space: normal;

    &:before {
      content: normal;
    }
  }
}

h3 {
  font-size: 18px;
  margin-bottom: 16px;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.text-semibold {
  font-weight: 500 !important;
}

.text-bold {
  font-weight: 600 !important;
}

.text-normal {
  font-weight: 400 !important;
}

.text-link {
  &:hover {
    text-decoration: underline;
  }
}

.text-overflow-50 {
  display: inline-block;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-overflow-100 {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//TEXT COLORS

.text-purple {
  color: $purple-color !important;
}

.text-theme {
  color: $theme-color !important;
}

.text-green {
  color: $green-color !important;
}

.text-red {
  color: #ff1e1a !important;
}

.text-light {
  color: $theme-text-color-medium !important;
}

.text-blue {
  color: #f68014 !important;
}

.text-white {
  color: #fff !important;
}

.text-light-grey {
  color: $grey-color-secondary !important;
}

.text-light-grey-md {
  color: $grey-color-md !important;
}

.text-underline {
  text-decoration: underline !important;
  transition: 0.3s;

  &:hover {
    text-decoration: none !important;
  }
}

.text-highliten {
  background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  p {
    font-weight: bold !important;
    color: #3b5998 !important;
  }
}

//FONT SIZE
.text-sm {
  font-size: 14px !important;
}

.text-sx-xs {
  font-size: 9px !important;
}

.text-xs {
  font-size: 12px !important;
}

.text-md {
  font-size: 16px !important;
}

.text-lg {
  font-size: 18px !important;

  @media screen and (max-width: 767px) {
    font-size: 16px !important;
  }
}

.text-xl {
  font-size: 20px !important;

  @media screen and (max-width: 767px) {
    font-size: 16px !important;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.text-transform-normal {
  text-transform: inherit !important;
}

.title-primary {
  font-size: 48px !important;
  font-weight: 400 !important;

  @media screen and (max-width: 767px) {
    font-size: 28px !important;
  }
}

.title-secondary {
  font-size: 36px !important;

  @media screen and (max-width: 767px) {
    font-size: 24px !important;
  }
}

.title-section {
  font-size: 32px !important;

  @media screen and (max-width: 767px) {
    font-size: 24px !important;
  }
}

.list-bullet {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-flow: column;

  li {
    display: inline-flex;
    padding: 8px 24px;
    white-space: pre-wrap;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 18px;
      left: 0;
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 16px;
      border-radius: 50%;
      background: $stroke-color;
    }

    a {
      display: inline-block;
    }
  }
}

//LINES CONTROL
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.selected-icon {
  color: $theme-color;
}

.not-selected-icon {
  color: $grey-color-secondary;
}

.add-item {
  color: $theme-text-color-medium;

  &:hover {
    color: $theme-color;
  }
}

.title-underline {
  position: relative;

  &:after {
    content: "";
    height: 2px;
    width: 100%;
    background: $theme-color;
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
  }
}

.white-space {
  white-space: pre-wrap;
}

.visibility-hidden {
  visibility: hidden;
}