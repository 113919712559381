.expert-header {
  background-color: #f8f8f8;
  border-top: 2px solid #15AFD1;
  padding: 10px 16px;
}

.ui.star.rating .icon {
font-size: 14px;
width: 16px;
}

.expert-firstRow {
  display: flex;
  align-items: baseline;
}
.expert-category-type {
  color: #15AFD1;
  font-size: 12px;
  margin-right: 30px;
}

.expert-button {
  background-color:#15AFD1;
  border-radius:4px;
  border:1px solid #dcdcdc;
  display:inline-block;
  cursor:pointer;
  color:#fff;
  font-size:12px;
  padding:6px 10px;
  text-decoration:none;
  margin-left: auto;
}
.expert-button:active {
position:relative;
top:1px;
}
.expert-button.active {
background-color:#5d4df5 !important;
color: white;
}

.expert-auther-data {
  display: flex;
}

.expert-auther {
  color: #414141;
  font-weight: 600;
  font-size: 16px;
}
.expert-auther-image {
  width: 25px;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 10px;
}


.expert-writer {
  color: #7F808A;
  font-size: 12px;
}
