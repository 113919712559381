.MuiSlider-root.MuiSlider-colorPrimary {
  color: $border-light-grey;

  .MuiSlider-thumb {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background: $theme-color;
    border: 3px solid $white-color;
    box-shadow: 0px 0px 8px 2px $theme-color;

    &.MuiSlider-active,
    &:hover {
      background: $theme-color;
      border-color: $white-color;
      box-shadow: 0px 0px 0px 2px $theme-color;
    }

    &.Mui-disabled {
      background: grey;
      border-color: black;
      box-shadow: 0px 0px 0px 2px grey;
    }
  }

  .PrivateValueLabel-label-25 {
    color: $dark-grey;
  }

}

.MuiSwitch-colorPrimary.Mui-checked {
    color: $theme-color!important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: $theme-color!important;
}