.detailed-tooltip {
  &__img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }

  &__number {
    padding: 2px 4px;
    font-size: 12px;
    border: 1px solid $border-color !important;
    border-radius: 5px;
  }

  &__breaches {
    width: 36px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: url("../../../images/shield-solid.svg") 0 0 no-repeat;
    background-size: cover;
    color: $white-color;
    cursor: pointer;
  }

  &.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: $grey-color-light !important;
  }
  &.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: $grey-color-light !important;
  }
  &.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: $grey-color-light !important;
  }
  &.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: $grey-color-light !important;
  }

  &__header {
    padding: 8px 16px;
    border-bottom: 1px solid $border-color;
    font-weight: 600;
  }

  &__body {
    padding: 16px;
  }

  &__footer {
    padding: 8px 16px;
    background: $grey-color-light-bg;
    border-radius: 5px;
  }
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  border-bottom-color: #f4f5f8;
}

.react-datepicker-popper {
  z-index: 2;
}


.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range,
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
  background: $theme-color!important;
}