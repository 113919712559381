// Colors
$Actors : #9F55A1;
$Actors-alfa : rgba(159,85,161,0.7);
$Stories : #AD6A05;
$Stories-alfa : rgba(173,106,5,0.7);
$Threats : #FF4040;
$Threats-alfa : rgba(255,64,64,0.7);
$Solutions : #1DB534;
$Solutions-alfa : rgba(29,181,52,0.7);
$Companies : #5D61E7;
$Companies-alfa : rgba(93,97,231,0.7);
$Experts : #15AFD1;
$Experts-alfa : rgba(21,175,209,0.7);
$Breaches : #E617D1;
$Breaches-alfa : rgba(230,23,209,0.7);

.related-item {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 0;

    h4, .date {
      padding-top: 6px;

      @media screen and (max-width: 991px) {
        padding-top: 0;
      }
    }

    .category-buttons {
      padding-left: 0;
    }

    .card-footer-action-btns {
      padding-left: 0;
    }
}

.related-image{
    height: 60px;
    width: 60px;
    border-radius: 3px;
    margin-bottom: 10px;
    margin-right: 6px;
    object-fit: cover;
}

.related-text {
  width: calc(100% - 60px - 6px);
}

.related-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: none;
&.Breaches {
    color: $Breaches;
    &:hover {
      background: none;
      color: $Breaches;
    }
}
&.Stories {
    color: $Stories;
    &:hover {
      background: none;
      color: $Stories;
    }
}
&.Threats {
    color: $Threats;
    &:hover {
      background: none;
      color: $Threats;
    }
}
&.Actors {
    color: $Actors;
    &:hover {
      background: none;
      color: $Actors;
    }
}
&.Solutions {
    color: $Solutions;
    &:hover {
      background: none;
      color: $Solutions;
    }
}
&.Companies {
    color: $Companies;
    &:hover {
      background: none;
      color: $Companies;
    }
}
&.Experts {
    color: $Experts;
    &:hover {
      background: none;
      color: $Experts;
    }
}
}

.btn-tooltip {
  display: inline-block;
  padding: 4px 10px;
  margin: 4px;
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid transparent;
  color: #fff!important;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: $grey-color-light;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: $grey-color-light;
}

.tooltip {
  color: #fff!important;
  &.show {
    opacity: 1;
  }
}

.tooltip-inner {
    max-width: 400px;
    padding: 12px 16px;
    color: #fff!important;
    text-align: center;
    background-color: $grey-color-light;
    border-radius: .25rem;
    opacity: 1!important;

    hr {
      opacity: .2;
    }
}
