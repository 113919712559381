
.source-item-card {
  padding: 16px 16px 8px;
  margin-bottom: 16px;
  background: none;
  border: none;
  box-shadow: 0 0 6px rgba(0,0,0,.1);
  position: relative;
  border-radius: 5px;
  cursor: pointer;

  &__btn-top {
    padding: 4px 18px 5px;
    position: absolute;
    top: 0;
    right: 0;
    color: $white-color;
    font-size: 12px;
    border-radius: 0 5px 0 5px;
    display: none;

    &.blue {
      background: $theme-color;
      &:hover {
        background: darken($theme-color, 10%);
      }
    }

    &.remove {
      background: $red-color;

      &:hover {
        background: darken($red-color, 20%);
      }
    }
  }

  &:hover {
    .source-item-card__btn-top.blue {
      display: block;
    }
  }

  &__content {
    border-radius: 5px 5px 0 0;
  }

  &__logo {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    padding: 0;
    text-align: center;
    background: $white-color;
    border-radius: 5px 5px 0 0;

    img {
      max-height: 120px;
      max-width: 30%;
    }
  }

  &__footer {
    padding: 12px;
    text-align: center;
    position: relative;

    h3 {
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__footer-tags {
    display: inline-flex;
    padding: 4px 8px;
    margin-right: 8px;
    margin-top: 8px;
    background: $grey-color-secondary;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 500;
    color: $white-color-70;

    &.selected {
      background: $theme-color;
      color: $white-color;
    }
  }

  &__footer-tags-list {
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
    background: $grey-color-light-bg;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }



  &-list-view {
    background: $white-color;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $grey-color-light;


    &.selected {
      background: $white-color;
      border-top: 1px solid rgba(55, 153, 243, 0.5);
    }

    &:hover {
      background: $white-color;
      box-shadow: -2px 0 10px rgba(0,0,0,.1);
    }

    .card-body {
      padding: 12px;
    }

    h3 {
      font-family: $roboto;
      font-size: 16px;
    }

    .source-item-logo {

    }
  }
}


.feed-manager-filters {
  padding: 16px;
  &-item {
    display: block;
    padding: 6px 8px;
    margin-bottom: 8px;
    list-style: none;
    border-radius: 3px;

    &:before {
      content: normal;
    }

    &:hover {
    }

    &.selected {

      .check-icon {
        color: $theme-color;
      }

      .star-icon {
        color: $border-light-grey;
        font-size: 20px;
      }
    }
  }
}

.sources-filter {
  margin: 16px 24px 0;
  padding: 8px 16px;
  border-radius: 5px;
  background: $white-color;
  box-shadow: 0 1px 3px rgba(0,0,0,.15);

  .react-select-container .react-select__control {
    height: 43px;
  }

  .btn {
    min-width: 64px;
  }
}

.sources-title {
  margin: 0 24px;
  padding-top: 24px;
  font-weight: bold;
  span {
    color: $dm-text-grey;
  }
}

.edit-source-icon {
  position: absolute;
  font-size: 18px;
  top: 10px;
  left: 20px;
  color: $border-light-grey;
  // z-index: 100;
  cursor: pointer;
}
