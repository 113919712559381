.landing-page {
  h1,
  h2,
  h3 {
    font-family: $montserrat;
    font-weight: 600;
  }

  h1 {
    padding: 24px 0;
    font-size: 28px;
    line-height: 1.5;
    color: $black;
    position: relative;

    strong {
      margin-top: 16px;
      font-size: 42px;
      @media screen and (max-width: 767px) {
        font-size: 32px;
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 38px;
    }

    span {
      display: block;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.5;
    }
  }

  h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 24px;

    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
  }

  h3 {
    padding-bottom: 16px;
    font-size: 36px;
    color: $theme-color;

    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }

  h4 {
    font-size: 24px;
    font-weight: 500;

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
}

.container-sm {
  max-width: 800px;
  margin: 0 auto;
}

.landing-hero-section {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  //height: 100vh;
  padding: 100px 0 120px;
  position: relative;
  background: $white-color;

  &__card {
    padding: 32px;
    border: 1px solid $grey-color-light;
    background: $bg-light;
    border-radius: 6px;

    p {
      font-size: 24px;
      color: $black;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 120px 0 50px;
  }

  img {
    border-radius: 12px;
  }

  .beta-release-label {
    font-size: 12px;
    letter-spacing: 2px;
  }

  &-right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 140px 0;

    @media screen and (max-width: 767px) {
      position: relative;
      padding: 60px 0;
    }

    .beta-release {
      display: block;
      text-align: left;
      padding-top: 220px;
      margin-bottom: 8px;
      font-weight: bold;

      @media screen and (max-width: 991px) {
        padding-top: 170px;
        text-align: center;
      }

      @media screen and (min-width: 768px) and (max-width: 1280px) {
        padding-top: 180px;
        text-align: center;
      }
    }
  }

  h3 {
    font-size: 24px;
    line-height: 1.5;
    color: $theme-color;
  }

  h4 {
    font-size: 20px;
    font-weight: normal;
  }
}

.beta-features-list-wrapper {
  position: relative;
  width: 100%;
  padding: 30px 36px;
  z-index: 3;

  &:after {
    content: "";
    display: block;
    background: url("../../../../images/landing/beta-release-img.svg") center 0 no-repeat;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;

    @media screen and (max-width: 767px) {
      left: 16px;
      right: 16px;
      background: url("../../../../images/landing/beta-release-img-mobile.svg") top center no-repeat;
      background-size: contain;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      background: url("../../../../images/landing/beta-release-img-mobile.svg") top center no-repeat;
      background-size: 85%;
    }
  }
}

.beta-features-list {
  list-style: none;

  li {
    margin-bottom: 16px;

    a {
      width: 136px;
      height: 136px;
      padding: 12px;
      margin-right: 16px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      text-align: center;
      white-space: pre-wrap;
      background: $white-color;
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.05);
      font-weight: 400;
      letter-spacing: 0.25px;
      position: relative;
      transition: 0.3s;

      @media screen and (max-width: 767px) {
        width: 100px;
        height: 100px;
      }

      &:hover {
        text-decoration: none;
        color: $black;
        margin-top: -8px;
      }
    }

    img {
      height: 64px;

      @media screen and (max-width: 767px) {
        height: 52px;
      }
    }

    &:before {
      content: normal;
    }

    span {
      display: block;
      padding-top: 4px;
      line-height: 1.25;
      font-size: 12px;
    }

    .btn {
      min-width: 288px;
    }
  }
}

.stream-line-section {
  padding: 60px 0;
  position: relative;
  background: $grey-color-light-bg url("../../../../images/landing/streamline-decoration.svg") 100% 0 no-repeat;

  @media screen and (max-width: 767px) {
    background: none;
    padding: 0 0 30px;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  // &:after {
  //   content: "";
  //   display: block;
  //   background: $grey-color-light-bg;
  //   position: absolute;
  //   height: 160px;
  //   width: 100%;
  //   top: -80px;
  //   left: 0;
  //   right: 0;
  //   transform: rotate(-4deg);
  //   z-index: 0;
  //
  //   @media screen and (max-width: 767px) {
  //     top: -120px;
  //   }
  // }
}

h2.slider-title {
  font-weight: 600;

  span {
    font-weight: 300;
    font-size: 20px;
  }
}

.stream-line-list {
  margin: 24px 0;
  list-style: none;

  &-item {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    padding: 16px;
    margin-bottom: 24px;
    transition: 0.3s;
    cursor: pointer;

    &:before {
      content: normal;
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 0;
      margin-bottom: 12px;
      color: $dark-grey;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
  }

  &-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: $theme-color;
    color: $white-color;

    @media screen and (max-width: 767px) {
      width: 40px;
      height: 40px;
    }

    .material-icons {
      font-size: 36px;
    }
  }

  &-content {
    width: calc(100% - 60px);
    padding-left: 24px;
    white-space: pre-wrap;
    font-size: 16px;

    @media screen and (max-width: 767px) {
      width: calc(100% - 40px);
      padding-left: 16px;
      font-size: 16px;
    }

    a {
      &:hover {
        color: $theme-color;
      }
    }
  }
}

.breach-analyser {
  padding: 100px 0;
  background: $grey-color-light-bg url("../../../../images/landing/breach-analyzer-bg.svg") 0 50% no-repeat;
  background-size: 20%;
  border-top: 1px solid $stroke-color;

  @media screen and (max-width: 767px) {
    padding: 30px 0;
    background: none;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 60px 0;
  }
}

.threat-curator {
  background: $white-color;
}

.team-boards {
  padding: 100px 0;
  background: $white-color;

  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 60px 0;
  }
}

.news-feed {
  padding: 40px 0 0;

  img {
    width: 100%;
  }
}

.active-slide {
  .stream-line-list-item {
    background: $white-color;
    box-shadow: 0 0px 16px rgba(0, 0, 0, 0.1);
  }
}

.join-team {
  padding: 80px 0;
  background: $white-color;

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }
}

.testimonials-section {
  padding: 80px 0;

  @media screen and (max-width: 767px) {
    padding: 40px 0 80px;
  }
}

.testimonial {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-flow: column;
  padding: 36px 28px;
  font-size: 16px;

  @media screen and (max-width: 991px) {
    padding: 16px;
  }

  &-text__name {
    padding: 0 24px 8px;
    font-size: 18px;
    display: block;
    text-align: right;
  }

  p {
    padding: 16px 24px 0;
    letter-spacing: 0.5px;
    font-size: 18px;
    //font-family: $montserrat;
  }
}

.slick-dots {
  margin-top: 24px;

  li {
    &:before {
      content: normal;
    }

    button {
      width: 16px;
      height: 2px;
      padding: 0;
      position: relative;
      background: $grey-color-md;
      border: none;
      text-indent: -99999px;
    }

    &.slick-active {
      button {
        background: $dark-grey;
      }
    }
  }
}

.how-it-works-section {
  padding: 80px 0;
  position: relative;

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }

  @media screen and (min-width: 1365px) {
    &:before {
      content: "";
      display: block;
      width: 174px;
      height: 70px;
      background: $grey-color-light-bg url("../../../../images/landing/how-it-works-decoration-right.svg") 0 0 no-repeat;
      position: absolute;
      bottom: 30px;
      left: 0;
      background-size: contain;
    }

    &:after {
      content: "";
      display: block;
      width: 174px;
      height: 70px;
      background: $grey-color-light-bg url("../../../../images/landing/how-it-works-decoration-left.svg") 0 0 no-repeat;
      position: absolute;
      top: 30px;
      right: 0;
      background-size: contain;
    }
  }

  img {
    width: 100%;
    max-width: 940px;
    box-shadow: 0 0 48px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }

  h2 {
    color: $dark-grey;
    font-weight: 400;
    margin-bottom: 50px;

    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
}

.testimonial-section {
  padding: 80px 0;
  background: $white-color;

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }
}

.why-trukno-section {
  padding: 80px 0;

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }
}

.footer {
  padding: 60px 0 0;
  background: #102a43;
  color: $white-color;

  @media screen and (max-width: 767px) {
    padding: 40px 0 0;
  }

  h3 {
    color: $white-color;
  }

  p {
    font-size: 16px;
  }

  &-bottom {
    background: darken(#102a43, 5%);
  }

  ul {
    li {
      &:before {
        content: normal;
      }
    }
  }
}

.square-socials {
  list-style: none;

  li {
    margin: 0 4px;

    &:before {
      content: normal;
    }

    a {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $white-color;
      border-radius: 3px;
      border: 2px solid transparent;

      &:hover {
        border: 2px solid $theme-color;
      }
    }
  }
}

//NEW LANDING PAGE SECTIONS:
.feature-board {
  padding: 80px 0;
  color: $white-color;
  background: #243b53;

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }

  &--cyber-feed {
    background: #f0f4f8;
    color: $white-color;

    .feature-board {
      &__header {
        color: $theme-text-color;

        h2 {
          color: $theme-text-color;

          @media screen and (max-width: 767px) {
            font-size: 36px;
          }

          span {
            color: $theme-text-color;
          }
        }
      }

      &__content {
        p {
          color: $theme-text-color;
        }
      }
    }
  }

  &__header {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    margin-bottom: 48px;

    @media screen and (max-width: 767px) {
      display: block;
      margin-bottom: 24px;
    }

    &-icon {
      font-size: 80px;

      @media screen and (max-width: 991px) {
        font-size: 40px;
        margin-bottom: 6px;
      }
    }

    h2 {
      font-size: 48px;
      margin-bottom: 0;
      color: $white-color;

      span {
        font-weight: 400;
        color: $white-color;
      }
    }

    p {
      font-size: 24px;
      font-family: $montserrat;

      @media screen and (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 0;
      }
    }
  }

  &__item {
    display: flex;
    margin-bottom: 30px;

    @media screen and (max-width: 991px) {
      margin-bottom: 16px;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    padding: 8px;
    background: $theme-color;
    border-radius: 5px;

    @media screen and (max-width: 991px) {
      width: 42px;
      height: 42px;
    }
  }

  &__content {
    width: calc(100% - 64px);
    padding-left: 24px;
  }

  &__title {
    color: $white-color !important;
    margin-bottom: 0;
    font-size: 24px !important;

    @media screen and (max-width: 991px) {
      font-size: 20px !important;
    }
  }

  &__description {
    font-size: 16px;
  }
}

.threat-for-everyone {
  padding: 80px 0;
  background: $grey-color-light-bg;

  &__card {
    height: 100%;
    padding: 24px 16px;
    background: $white-color;
    border-radius: 6px;
    font-size: 16px;
    line-height: 1.5;
  }

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }

  &__title {
    padding-bottom: 8px;
    text-align: center;
    font-size: 48px;
  }

  &__video {
    position: relative;
    cursor: pointer;

    img {
      border-radius: 6px;
    }
  }

  &__video-play-btn {
    display: inline-block;
    position: absolute;
    bottom: 16px;
    right: 16px;

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      background: #fff;
      width: 20px;
      height: 20px;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 1;
    }

    svg {
      position: relative;
      z-index: 2;
    }
  }
}

.uncover-unknown {
  padding: 80px 0;
  background: #d9e2ec;

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }

  p {
    font-size: 16px;

    strong {
      font-size: 18px;
      font-family: $montserrat;
    }
  }
}

.contact-us {
  padding: 80px 0;
  background: #243b53;

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }

  h2 {
    color: $white-color;
  }

  p {
    color: $white-color;
    font-size: 18px;
  }

  ul {
    li {
      list-style: none;
    }
  }

  &__form {
    padding: 24px 24px 36px;
    border-radius: 5px;

    @media screen and (max-width: 991px) {
      max-width: 550px;
      margin: 0 auto;
    }

    h3 {
      color: $black;
    }
  }
}

.join-us-form {
  .form-control {
    width: 280px;
    height: 55px;

    @media screen and (max-width: 991px) {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
    }
  }

  .btn {
    height: 53px;
    width: 120px;
    margin-left: -14px;

    @media screen and (max-width: 991px) {
      width: 100%;
      max-width: 320px;
      margin: 16px auto 0;
      font-size: 18px;
    }
  }
}

.team-card {
  height: 100%;
  max-width: 320px;
  margin: 0 auto;
  padding: 32px 24px;
  border-radius: 4px;

  &--blue {
    background: rgba(55, 153, 243, 1);
  }

  &--red {
    background: rgba(234, 84, 84, 1);
  }

  &--black {
    background: #2e4b69;
  }

  h4 {
    color: $white-color;
    margin-bottom: 24px;
    text-align: center;
    font-size: 28px;
  }

  ul {
    display: flex;
    flex-flow: column;

    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 12px;
      color: $white-color;
      font-size: 18px;
      white-space: initial;

      &:before {
        content: normal;
      }
    }
  }
}

.plans-section {
  padding: 80px 0;
  color: $white-color;
  background: #102a43;
}

.demo-hero-section {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 20px;
  min-height: 100vh;

  h1 {
    @media screen and (max-width: 991px) {
      font-size: 48px;
    }
  }

  h3 {
    @media screen and (max-width: 991px) {
      font-size: 20px;
    }
  }
}

.boards-section {
  padding: 120px 0;
  background: $white-color;

  @media screen and (max-width: 767px) {
    padding: 60px 0;
  }

  .boards-section__title {
    color: $theme-text-color;
  }

  .boards-section__description {
    font-size: 18px;
    line-height: 1.5;
  }

  &__image {
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
    border-radius: 16px;
  }
}

.integration-section {
  padding: 100px 0;
  background: $bg-light;

  @media screen and (max-width: 767px) {
    padding: 60px 0;
  }
}

.request-a-demo-section {
  padding: 80px 0;
  background: #102a43;

  @media screen and (max-width: 767px) {
    padding: 60px 0;
  }

  h2 {
    color: $white-color;
  }
}

.video-shadow {
  box-shadow: 0 0 19px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 0;

  video {
    display: block;
  }

  .muted-icon {
    cursor: pointer;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 10px;
    right: 10px;
    opacity: 0.6 !important;
    z-index: 10;

    &:hover {
      opacity: 1 !important;
    }
  }
}
