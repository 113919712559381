.header-items{
    display: block;
    font-size: 10px;
    letter-spacing: -.2px;
    margin: 0;
    font-weight: 600;
    padding: 16px 2px;
    text-transform: capitalize;
    @media screen and (max-width: 1200px) {
      font-size: 7px;
      transform: rotate(-15deg);
    }
}

.titles {
    display: flex;
    justify-content: space-between;
}
