.views {
  display: inline-flex;
  font-size: 12px;
  color: #828282;
  i {
    font-size: 16px;
    margin-right: 4px;
    position: relative;
    top: 1px;
  }
}

.story-header {
    background-color: #f8f8f8;
    border-top: 2px solid #AD6A05;
    padding: 10px 16px;
    .ui.star.rating .icon {
      font-size: 14px;
      width: 16px;
    }

    .btn-outline-secondary {
      background: #AD6A05;
      color: #fff;
      border: none;
      &:focus, &:active, &:hover {
        background: darken(#AD6A05, 10%);
      }
    }
}

.breach-header {
    background-color: #f8f8f8;
    border-top: 2px solid #E617D1;
    padding: 16px;
}

.story-firstRow {
    display: flex;
    align-items: baseline;

    @media screen and (max-width: 767px) {
      flex-flow: column;
    }
}
.story-category-type {
    color: #AD6A05;
    font-size: 14px;
    margin-right: 24px;
    margin-bottom: 6px;
    font-weight: 600;
}

.breach-category-type {
    color: #E617D1;
    font-size: 14px;
    margin-right: 24px;
    margin-bottom: 6px;
    font-weight: 600;
}
.views {
  display: inline-flex;
  font-size: 12px;
  color: #828282;
  i {
    font-size: 16px;
    margin-right: 4px;
    position: relative;
    top: 1px;
  }
}

.story-date {
    color: #3B5998;
    font-weight: bold;
    font-size: 14px;
    margin-left: auto;
    text-transform: uppercase;
    @media screen and (max-width: 414px) {
      font-size: 10px;
    }
}

.story-source {
    cursor: pointer;
    text-decoration: underline;
    color: #333;
    font-size: 14px;
    margin-bottom: 4px;
}

.rating {
  i {
    outline: none;
    box-shadow: none;
    cursor: pointer;
  }
}

.story-writer {
  cursor: pointer;
    color: #7F808A;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
}
