.our-mission-section {
  margin-top: 87px;
  padding: 80px 0;
  background: linear-gradient(90deg, #FFFFFF 0%, #FAFBFD 40.9%, #E6EEF3 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }

}

.problem-section {
  padding: 80px 0;
  background: linear-gradient(90deg, #E6EEF3 0%, #FAFBFD 56.77%, #FFFFFF 100%), #FFFFFF;
  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }
}

.solution-section {
  padding: 80px 0;
  background: linear-gradient(90deg, #FFFFFF 0%, #FAFBFD 40.9%, #E6EEF3 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }
}


.img-fixed-width {
  @media screen and (max-width: 767px) {
    max-width: 80px;
  }
}
