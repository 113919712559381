.label-indicator {
  display: inline-block;
  width: 40px;
  height: 5px;
  border-radius: 5px;

  &.critical {
    background: $red-color;
  }

  &.important {
    background: $purple-color;
  }
}

.bages-list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin-bottom: 12px;
  li {
    padding: 6px 8px;
    color: $white-color;
    border-radius: 3px;
    margin-right: 8px;
    line-height: 1;
    font-weight: 500;
    font-size: 12px;
    &:before {
      content: normal;
    }

    &.critical {
      background: $red-color;
    }

    &.important {
      background: $purple-color;
    }
  }
}

.ioc-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $red-color;
  font-size: 8px;
  position: absolute;
  right: -26px;
  top: -3px;
  color: $white-color;
}

.badge-custom {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  background: $grey-color-light;
  border-radius: 3px;
  &--rounded {
    border-radius: 43px;
  }

  &--active {
    background: $theme-color !important;
    color: $white-color;
  }
}
