.multi-select {
  button {
    &:hover {
      border: 1px solid $theme-color!important;
    }
  }

  .react-select-container .react-select__option {
    display: flex;
    align-items: center;
    &:hover {
      background: $grey-color-light-bg;
    }
  }
}
