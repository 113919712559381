@import "../base/mixins";

.landing-page {
  +.dark-mode {
    display: none;
  }
}

.dark-mode {
  min-height: 100vh;
  color: $white-color;
  background: $dm-primary-grey;

  h1,
  h1,
  h3,
  h4,
  h5,
  ul li {
    color: $white-color;
  }

  .border-right {
    border-right: 1px solid #3c4049 !important;
  }

  .btn {
    color: $white-color;
  }

  .btn-secondary {
    background: $dark-grey;
    border: 1px solid transparent;

    &:focus,
    &:hover {
      background: $dm-text-grey;
    }
  }

  .btn-link.grey {
    color: $white-color-70;

    &:hover {
      color: $theme-color;
    }
  }

  .text-white-dark-mode {
    color: $white-color;
  }

  .form-control {
    background: $dm-grey;
    color: $white-color;
    border: 1px solid $dm-text-grey !important;

    @include inputPlaceholder() {
      color: $dm-text-grey;
    }

    &:focus {
      border: 1px solid rgba(255, 255, 255, 75) !important;
    }

    &.input-error {
      border: 1px solid rgb(156, 38, 38) !important;
    }
  }

  .radiomark {
    border: 1px solid $dm-light-color;
  }

  .btn-linkedin {
    color: $white-color;
    border: 1px solid $dm-text-grey !important;

    &:hover {
      background: $dm-grey;
    }
  }

  .divider span {
    background: $dm-bg-grey;
    color: $white-color;
  }

  //DETECTIONS 
  .detections-back {
    color: $dark-theme-seecondary-color;

    &:hover {
      color: $theme-color;
    }
  }

  .detection-subheader {
    background: #222228;
  }

  .procedure-list-item-content {
    color: $white-color;
  }


  .detections-list-item {
    background: $theme-grey-color;
    border: 1px solid $theme-grey-color;

    &.selected {
      border-color: $white-color;
      background: $dark-theme-seecondary-bg;
    }

    &-content {
      color: $dark-theme-seecondary-color;
    }

    &-logo {
      background: $black;
    }
  }


  .detection-button {
    background: rgba(255, 255, 266, 0.05);
    color: rgba(255, 255, 255, .5);

    &:hover {
      color: $theme-color;
    }
  }

  .detections-tabs {
    background: #000;

    .nav-item {
      color: $white-color;

      &.active,
      &:hover {
        background: $dark-theme-primary-bg;
        color: $white-color;
      }


    }

    .tab-content {
      background: $dark-theme-primary-bg;
    }
  }


  .detection-rule-card {
    background: $dm-bg-grey;
    border: 1px solid rgba(255, 255, 255, .1);

    &.selected {
      background: $dark-theme-seecondary-bg;
      border: 1px solid rgba(255, 255, 255, 1);
    }
  }

  .detection-rule-card-title {
    color: rgba(255, 255, 255, .9);
  }

  .detection-rule-card-content {
    color: $dark-theme-seecondary-color;
  }

  .detections-logo {
    color: rgba(255, 255, 255, .8);
  }

  .detection-rule-card-number {
    background: rgba(255, 255, 255, .1);
  }

  .detection-rule-card-date {
    color: rgba(255, 255, 255, .8);
  }

  .detection-rule-title {
    font-size: 14px;
  }

  .detection-rule-filter {
    color: #53535B;
  }

  .detections-tabs-right-header {
    background: #222228;
  }

  .procedure-list-item {
    color: $white-color;
  }

  .procedure-list-number {
    border: 1px solid rgba(255, 255, 255, .1);
    color: rgba(255, 255, 255, .7);
  }

  .procedure-list-content {
    color: rgba(255, 255, 255, .7);
  }

  .chain-card {
    background: #1E1E1E;
    border: 1px solid rgba(255, 255, 255, .2);
    color: #fff;

    .chain-card-number {
      border-color: rgba(255, 255, 255, .2);
    }

    &.blue {
      background: #53BDF8;
      color: #0E0E10;

      .chain-card-number {
        border-color: rgba(0, 0, 0, .2);
      }
    }

    &.purple {
      background: #AC6AFF;
      color: #fff;

      .chain-card-number {
        border-color: rgba(0, 0, 0, .2);
      }
    }
  }

  .detection-chains-filter {
    background: #000;

    &.active,
    &:hover {
      background: #FD4A19;
    }
  }

  .editor-wrapper {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, .25);
    background: #19191C;
  }

  .editor-wrapper-header {
    border-bottom: 1px solid rgba(255, 255, 255, .1);

    .checkbox-container {
      color: rgba(255, 255, 255, .57);
    }
  }

  .editor-action-link {
    color: #757577;

    &:hover {
      color: $theme-color;
    }
  }

  .editor-body {
    &:after {

      background: rgba(255, 255, 255, .1);
    }
  }

  .forgot-password,
  .need-account a,
  .need-account span {
    color: $white-color;
  }

  .label--uppercase {
    color: $border-light-grey;
  }

  .custom-dropdown-select {
    background: $dm-bg-grey;
    color: $white-color;
  }

  .react-select-container {
    .react-select {
      color: $white-color;

      &__input {
        input {
          color: $white-color !important;
        }
      }

      &__control {
        background: $dm-grey;
        color: $white-color;
        border: 1px solid $dm-text-grey !important;
      }

      &__single-value {
        color: $white-color;
      }

      &__menu {
        &-list {
          background: $dm-grey;
        }
      }

      &__option {
        background: $dm-grey;

        &--is-focused {
          background: $dm-bg-light-grey;
          color: $white-color;
        }
      }

      &__placeholder {
        color: $dm-text-grey;
      }

      &__dropdown-indicator {
        svg {
          fill: $dm-text-grey;
        }
      }

      &__multi-value {
        background: $dm-bg-light-grey;

        &__label {
          color: $white-color;
        }
      }
    }
  }

  .multi-select {
    button {
      +div {
        background: $dm-grey !important;
      }
    }

    .react-select__option {
      &--is-selected {
        color: $white-color !important;
      }

      &:hover {
        background: $dm-bg-grey;
      }
    }
  }

  .sort-order {
    svg {
      color: $white-color !important;
    }
  }

  .text-light-grey-md {
    color: $white-color-70 !important;
  }

  .text-light-grey {
    color: $white-color-70 !important;
  }

  .border-top {
    border-top: 1px solid $theme-text-color !important;
  }

  .border-bottom {
    border-bottom: 1px solid $theme-text-color !important;
  }

  .dropdown-menu {
    background: $dm-primary-grey;
    border: 1px solid $dm-dark-grey;

    .dropdown-item {
      color: $white-color;

      &:nth-child(even) {
        background: $dm-secondary-grey;
      }

      &:hover {
        background: $dm-dark-grey;
        color: $theme-color;
      }
    }
  }

  .card {
    background: $dm-bg-grey;

    &--secondary {
      background: $dm-grey;
    }
  }

  .dashboard-card-left {
    background: $dm-secondary-grey !important;

    .react-select-container {
      .react-select {
        &__control {
          background: $dm-grey;
          color: $white-color;
          border: 1px solid $dm-text-grey;
        }

        &__single-value {
          color: $white-color;
        }

        &__menu {
          border: 1px solid $dm-text-grey;
          border-radius: 4px;

          &-list {
            background: $dm-grey;
          }
        }

        &__option {
          background: $dm-grey;

          &--is-focused {
            background: $dm-bg-light-grey;
            color: $white-color;
          }
        }

        &__placeholder {
          color: $dm-text-grey;
        }
      }
    }

    .react-select-container--solid-blue {
      .react-select__control {
        .react-select__single-value {
          color: $white-color;
        }

        .react-select__dropdown-indicator svg {
          fill: $white-color;
        }
      }
    }
  }

  .horizontal-card {
    background: $dm-bg-grey !important;
  }

  .bgr-grey {
    background: $dm-secondary-grey !important;
  }

  .text-light {
    color: $dm-light-color !important;
  }

  //DASBOARD STYLES

  .dashboard-content {
    background: $dm-grey;
  }

  .dashboard-header {
    background: $dm-grey;
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  }

  .header-icon {
    background: $dark-theme-seecondary-bg;
  }

  .news-search-filter {
    color: $white-color;

    input {
      border: 1px solid rgba(255, 255, 255, .10);

      &:focus {
        border: 1px solid rgba(255, 255, 255, .20);
      }
    }

    .checkbox-container {
      background: $dm-grey;
    }
  }

  .news-search-filter {
    input {
      @include placeholder {
        color: $dm-text-grey;
      }

      color: $white-color;
    }
  }

  .mobile-search {
    @include placeholder {
      color: $dm-text-grey;
    }

    background: $dm-dark-grey;
    color: $white-color;
  }

  .dashboard-card {
    &-left {
      .card-header {
        background: $dm-secondary-grey;
        border-bottom: 1px solid $dm-dark-grey;
      }
    }

    .card-header {
      background: $dm-bg-grey;
    }
  }

  .dashboard-header-nav-link {
    color: $white-color;

    &.active {
      background: transparent;
      color: $white-color;
    }

    .material-icons {
      color: $border-light-grey;
    }
  }

  .dashboard-card-sm-vertical {

    background: $dm-bg-grey;
  }

  .dashboard-card--table {
    .card-header {
      background: $dm-primary-grey;
    }

    .card-body {
      border-bottom: 1px solid $dm-primary-grey;

      &:nth-child(odd) {
        background: $dm-secondary-grey;
      }

      &:hover {
        background: darken($dm-secondary-grey, 5%);
      }

      &:last-child {
        border: none;
      }
    }
  }

  .list-view-item {
    .dashboard-card-sm-vertical {
      background: $dm-bg-grey;
    }
  }

  .news-page .dashboard-card-sm-vertical-title {
    color: $white-color;

    a {
      color: $white-color;
    }
  }

  .news-page .dashboard-card-sm-vertical-body {
    color: $white-color-70;
  }

  .news-page .dashboard-card-sm-vertical-footer {
    color: $white-color-70;
  }

  .top-description:after {
    background: $dm-bg-grey;
  }

  .tiles-view {
    .dashboard-card-sm-vertical {
      background: $dm-dark-grey;
    }
  }

  .news-page .compact-list-view-item .dashboard-card-sm-vertical {
    border-bottom: 2px solid $dm-bg-light-grey;
  }

  .dashboard-filters-panel-item {
    background: $dm-primary-grey;
    border-right: 1px solid $dm-dark-grey;
    border-bottom: 1px solid $dm-dark-grey;
  }

  .chart-card {
    background: $dm-bg-grey;
    border: 1px solid transparent;
  }

  .dashboard-indicator-card {
    background: $dm-bg-grey;
    border: 1px solid transparent;
    color: $white-color;
  }

  .dashboard-table .table thead tr th {
    background: $dm-dark-grey;
    border: 1px solid $dm-bg-light-grey;
    color: $white-color;
    vertical-align: middle;
  }

  .dashboard-table .table tbody tr td {
    background: $dm-bg-grey;
    border: 1px solid $dm-bg-light-grey;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    color: $dm-text-light-grey;
  }

  .dashboard-table .table tbody tr:nth-child(even) td {
    background: $dm-secondary-grey;
  }

  .share-block {
    border: 1px solid $dm-bg-grey;

    ul {
      background: $dm-dark-grey;
      border: 1px solid transparent;

      li {
        color: $dm-text-light-grey;

        &:hover {
          background: $dm-grey;
          color: $theme-color;
        }

        a {
          color: $dm-text-light-grey !important;

          &:hover {
            background: transparent;
            color: $theme-color !important;
          }
        }
      }
    }
  }

  .stage-card {
    background: $dm-primary-grey;
    color: $white-color;
    border-right: 1px solid $dm-dark-grey;

    &-title {
      color: $white-color;
      border-top: 1px solid $dm-dark-grey;
      border-bottom: 1px solid $dm-dark-grey;
    }
  }

  .news-page-filter-panel .nav-link.active {
    background: $dm-dark-grey;
  }

  .dashboard-indicator-card {
    &-counter {
      color: $white-color-70;
    }

    // svg {
    //   path {
    //     fill: $white-color-70;
    //   }
    // }
  }

  //BOARD STYLES
  .news-page-filter-panel {
    background: $dm-grey;
    border-bottom: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }

  .collapsible-sidebar .sidebar-toggle {
    background: $dm-dark-grey;
    border: 1px solid $dm-dark-grey;
    color: $white-color;

    @media screen and (max-width: 767px) {
      border: transparent;
      background: transparent;
    }
  }

  .dashboard-sidebar {
    background: $dm-dark-grey;
    border-right: 1px solid $dm-secondary-grey;

    &-menu-item {
      color: $dm-text-grey;

      svg {
        fill: $dm-text-grey;
      }

      &:hover {
        color: $white-color;

        svg {
          fill: $white-color !important;
        }
      }



      &.active {
        color: $white-color;
      }
    }
  }

  .collapsible-sidebar {
    background: $dm-primary-grey;
    border-right: 1px solid $dm-dark-grey;

    .card {
      background: transparent;
    }
  }

  .left-menu-dropdown {
    color: $grey-color-md;
  }

  .collapsible-content {
    background: $dm-secondary-grey;
  }

  .news-section {
    background: $dm-bg-grey;
  }

  .article-block {
    background: $dm-bg-light-grey;

    p,
    span {
      color: $white-color;
    }
  }

  .board-comments-list-item {
    padding-left: 16px;

    &:hover {
      background: $dm-bg-light-grey;
    }

    p {
      color: $white-color;
    }
  }

  .add-comment-block {
    background: $dm-dark-grey;
  }

  .news-search-filter input {
    background: $dm-dark-grey;
  }

  .quick-message,
  .board-message-form {
    background: $dm-bg-grey;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);

    .form-control {
      background: $dm-bg-grey;
      border: 1px solid transparent !important;

      &:focus {
        border: 1px solid $theme-color !important;
      }
    }

    .btn-link {
      text-decoration: none;
    }
  }

  .sidebar-right-content {
    border: none;
    border-left: 1px solid $dm-bg-light-grey;
  }

  .my-boards-list li {
    &.active {
      &:before {
        background: $dm-bg-light-grey;
        border: transparent;
      }
    }
  }

  .sfButtons {
    background: $dm-dark-grey;
    color: $white-color;
  }

  .selected-filters-container {
    background: $dm-bg-light-grey;
    color: white;
  }

  //MODAL
  .modal-content {
    background: $dm-bg-grey;
    color: $white-color;

    h2 {
      color: $white-color;
    }
  }

  .right-curtain .modal-content {
    background: $dm-dark-grey;
  }

  .modal .modal-header .close span:first-child:before,
  .modal .modal-header .close span:first-child:after {
    background-color: $white-color;
  }

  .modal-dialog.add-details .modal-header .close span:first-child {
    background: $dm-bg-grey;

    &:after,
    &:before {
      background-color: $dm-text-light-grey;
    }
  }

  .modal-footer {
    border: none;
    background: $dm-dark-grey;
  }

  .shared-block {
    background: $dm-bg-light-grey;

    h3 {
      color: $dm-light-color;
    }
  }

  .modal-right-sidebar {
    background: $dm-grey;
  }

  .channels-list {
    li {
      color: $dm-text-light-grey;

      &:hover,
      &.selected {
        background: $dm-bg-grey;
      }
    }
  }

  .add-item {
    color: $dm-text-grey;
    font-size: 24px;

    &:hover {
      color: $theme-color;
    }
  }

  .MuiCheckbox-root {
    color: $dm-text-light-grey !important;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $theme-color !important;
  }

  .tooltip-container {
    background: $dm-bg-light-grey;
    border: none;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    color: $white-color;
  }

  .table-item-title {
    color: $white-color;
  }

  .mittre-table-popup {
    &__head {
      color: $white-color;
      border-bottom: 1px solid $dm-dark-grey;
      background: $dm-dark-grey;

      a {
        color: $dm-light-color;

        &:hover {
          color: $theme-color;
        }

        &.active {
          color: $theme-color;

          &:hover {
            color: darken($theme-color, 10%);
          }
        }
      }
    }
  }

  .mittre-table-popup__table {
    border: 1px solid $dm-bg-light-grey;
  }

  .mittre-table-popup__table-head {
    &-th {
      color: $white-color;
      background: $dm-primary-grey;
      border-right: 1px solid $dm-bg-light-grey;
      border-bottom: 1px solid $dm-bg-light-grey;

      &:last-of-type {
        border-right: none;
      }
    }

    &-number {
      color: $dm-text-light-grey;
    }
  }

  .mittre-table-popup__table-body {
    &-td {
      color: $white-color;
      background: $dm-secondary-grey;
      border-right: transparent;
      text-align: center;

      &:last-of-type {
        border-right: none;
      }
    }

    .stage-card-content-inner {
      background: $dm-primary-grey;
      border-color: $dm-text-grey;
    }

    .stage-card-content-inner__foter {
      background: $dm-secondary-grey;
    }
  }

  .tooltip-row:hover,
  .tooltip-row-selected {
    background: $dm-dark-grey;
    color: $white-color;
    border-radius: 4px;
  }

  .popover-body {
    background: $dm-bg-grey;
    color: $white-color;
  }

  .popover-header {
    background: $dm-bg-grey;
    color: $white-color;
    border-bottom: 1px solid $dm-dark-grey;
  }

  .bs-popover-auto[x-placement^="right"]>.arrow::after,
  .bs-popover-right>.arrow::after,
  .bs-popover-auto[x-placement^="right"]>.arrow::before,
  .bs-popover-right>.arrow::before {
    border-right-color: $dm-grey;
  }

  .bs-popover-auto[x-placement^="left"]>.arrow::before,
  .bs-popover-left>.arrow::before,
  .bs-popover-auto[x-placement^="left"]>.arrow::after,
  .bs-popover-left>.arrow::after {
    border-left-color: $dm-grey;
  }

  .bs-popover-auto[x-placement^="top"]>.arrow::after,
  .bs-popover-top>.arrow::after {
    border-top-color: $dm-bg-grey;
  }

  .personal-labels-list li:hover .personal-labels-list__edit {
    color: $white-color;
  }

  .tooltip>.tooltip-inner {
    background: $dm-dark-grey;
    color: $white-color !important;
  }

  .tooltip.bs-tooltip-right .arrow:before {
    border-right-color: $dm-dark-grey !important;
  }

  .tooltip.bs-tooltip-left .arrow:before {
    border-left-color: $dm-dark-grey !important;
  }

  .tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: $dm-dark-grey !important;
  }

  .tooltip.bs-tooltip-top .arrow:before {
    border-top-color: $dm-dark-grey !important;
  }

  .source-item-card__footer-tags-list {
    background: $dm-bg-grey;
  }

  .source-item-card__footer-tags {
    background: $dm-bg-light-grey;
    color: $grey-color-md;
  }

  .sources-filter {
    background: $dm-bg-grey;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  }

  .sources-title {
    color: $dm-text-light-grey;

    span {
      color: $dm-text-light-grey;
    }
  }

  .story-type-list {
    &__item {
      color: $dm-text-light-grey;

      &--selected {
        background: $dm-bg-grey;
      }
    }
  }

  #popover-basic-table {
    border-radius: 5px;
    background: $dm-bg-light-grey !important;
  }

  .tooltip-table {
    border-radius: 5px;
    background: $dm-bg-light-grey !important;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
  }

  .tooltip-row {
    border-bottom: 1px solid $dm-grey;

    &:last-of-type {
      border: none;
    }
  }

  .board-comments__action-btns {
    border: 1px solid $dm-bg-grey;
    background: $dm-secondary-grey;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);

    span {
      color: $dm-text-light-grey;

      &:hover {
        background: $dm-bg-grey;
        color: $theme-color;
      }
    }
  }

  .board-message {
    &__button {
      background: $dm-bg-light-grey;
      color: $grey-color-md;

      &:hover {
        color: $theme-color;
      }
    }
  }

  .detailed-tooltip {
    &.tooltip.bs-tooltip-right .arrow:before {
      border-right-color: $dm-dark-grey !important;
    }

    &.tooltip.bs-tooltip-left .arrow:before {
      border-left-color: $dm-dark-grey !important;
    }

    &.tooltip.bs-tooltip-bottom .arrow:before {
      border-bottom-color: $dm-dark-grey !important;
    }

    &.tooltip.bs-tooltip-top .arrow:before {
      border-top-color: $dm-dark-grey !important;
    }

    .tooltip-inner {
      padding: 0;
      background: $dm-dark-grey;
      color: $white-color !important;
      border: 1px solid $dm-bg-light-grey;
    }

    &__header {
      border-bottom: 1px solid $dm-bg-light-grey;
    }

    .text-light {
      color: $dm-text-light-grey !important;
    }

    &__footer {
      background: $dm-secondary-grey;
    }
  }

  .mittre-table-popup {
    .modal-header .close {
      span:first-child {
        background: $dm-bg-light-grey;
      }

      &:hover {
        span:first-child {
          background: $theme-color;
        }
      }
    }
  }

  .toggles-list {
    .nav-link {
      &.active {
        background: $dm-secondary-grey;
      }
    }
  }

  .sticky-filters {
    background: $dm-grey;
  }

  .edit-sources {
    .source-item-card {
      background: $dm-primary-grey;
    }
  }

  .review-form {
    background: $dm-secondary-grey;
  }

  .col-form-label {
    color: $dm-text-light-grey;
  }

  .__floater__body {

    h2,
    h3 {
      color: $white-color;
    }
  }

  .__floater__arrow {
    >span {
      svg {
        fill: #31353b;

        polygon {
          fill: #31353b;
        }
      }
    }
  }

  .mobile-nav {
    a {
      color: $white-color;
    }
  }

  .modal .edit-sources-body {
    &__left {
      background: $dm-primary-grey;
    }
  }

  .breach-item-page__action-btns {
    background: $dm-grey;

    li {
      a {
        color: $white-color;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }

  .item-page-header {
    background: $dm-grey;
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  }

  .related-threats-table__row {
    &:hover {
      background: $dm-grey;
    }
  }

  .dashboard-filters {
    &__toggle {
      background: $dm-bg-light-grey;
    }
  }

  .tag-item {
    background: $dm-secondary-grey;
  }

  .tag-item-dark {
    background: $dm-secondary-grey;
  }

  .stage-card-content__header-threats {
    background: url("../../../images/shield-solid-transparent.svg") 0 0 no-repeat;
    background-size: cover;
  }

  .dashboard-table-info {
    border: 1px solid $dm-bg-light-grey;
    color: $white-color;

    a {
      color: $white-color;

      &:hover {
        color: $theme-color;
      }
    }

    &__head {
      background: $dm-primary-grey;

      .dashboard-table-info__row {
        border-bottom: 1px solid $dm-bg-light-grey;
      }
    }
  }

  .filter-tabs {
    &__item {
      color: $dm-text-light-grey;

      &--active {
        color: $white-color;
      }
    }
  }

  .view-nav {
    .nav-link {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      padding: 0;
      margin: 4px;
      line-height: 1;
      background: $dm-dark-grey;
      border-radius: 4px;
      color: $border-light-grey;

      &.active {
        color: $theme-color;
      }
    }
  }

  .track-vertical {
    top: 2px;
    bottom: 2px;
    right: 2px;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.111);

    .thumb-vertical {
      position: relative;
      display: block;
      width: 100%;
      cursor: pointer;
      border-radius: inherit;
      background-color: rgba(123, 154, 255, 0.418);
    }
  }

  .technology-list {
    background: $dm-secondary-grey;

    &__item {
      border-left: 1px solid $dm-text-grey;
      color: $white-color;

      &:first-child {
        border-left: none;
      }
    }
  }

  .subsription-section {
    background: $dm-dark-grey;
    color: $white-color;

    .form-control {
      background: $white-color;
    }
  }

  .news-tabs-sorting {
    &__items {
      background: $dm-dark-grey;
      color: $white-color;

      &--active {
        background: $theme-color;
        color: $white-color;
      }
    }
  }

  .custom-dashboard-card {
    &.isOver {
      border: 1px dashed white;
    }

    background: $dm-bg-grey;
    border: 1px solid $dm-bg-grey;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);

    &__header-toggle {
      color: $white-color;
    }

    &__header-filter {
      color: $white-color;
    }

    &__table {
      color: $white-color;

      tbody {
        tr {
          td {
            a {
              color: $white-color;
            }
          }
        }
      }
    }

    &.add-card {
      background: $dm-grey;
      border: 2px solid $dm-grey;
    }
  }

  .pagination {
    .page-link {
      color: $dm-text-light-grey;
    }

    .page-item {
      &.active {
        .page-link {
          background: $dm-secondary-grey;
          color: $white-color;
        }
      }
    }
  }

  .article-block-top {
    &__dropdown-toggle {
      .btn-link {
        color: $white-color;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }

  .add-tag-btn {
    background: $dm-bg-light-grey;

    &:hover {
      background: $theme-color;
      color: $white-color;
    }
  }

  .setting-curtain {
    background: $dm-bg-grey;

    &.open {
      transform: translateX(0);
    }

    &__header {
      background: $dm-bg-light-grey;
    }

    &__header-close {
      &:hover {
        color: $theme-color;
      }
    }
  }

  .add-card {
    background: $dm-bg-light-grey;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);

    &:hover {
      border: 2px solid $theme-color;
    }

    &__title {
      border-bottom: 1px solid $dm-light-color;
    }
  }

  .related-Ioc {
    span {
      color: $white-color !important;
    }
  }

  .related-Count {
    span {
      color: $white-color !important;
    }
  }

  .share-block-general {
    ul {
      li {
        &:hover {
          background: $dm-grey;
        }
      }
    }
  }

  .malware-card {
    border: 1px solid $dm-bg-light-grey;
    background: $dm-primary-grey;

    &__bottom-breach-icon {
      background: url("../../../images/shield-solid-transparent.svg") 50% no-repeat;
      background-size: contain;
    }
  }

  .card-header {
    background: $dm-secondary-grey;
  }

  .profile-page {
    &__body {
      &-left {
        border-right: 1px solid $dm-bg-light-grey;

        .nav-pills {
          border-top: 1px solid $dm-bg-light-grey;

          .nav-link {
            color: $white-color;
          }
        }
      }

      &-right {
        padding: 16px;

        .table-bordered {
          th {
            border: 1px solid $dm-text-grey;
            border-bottom: none;
          }

          td {
            border: 1px solid $dm-text-grey;
          }
        }
      }

      .tab-content {
        max-width: 800px;
        margin: 0 auto;
      }
    }

    .table {
      color: $white-color;
    }

    .payment-card {
      background: $dm-secondary-grey;
      border: 1px solid $dm-bg-light-grey;

      &__exp {
        color: $dm-text-light-grey;
      }
    }
  }

  .ndv-analysis-toggle .toggles-container .nvd-toggle.active {
    background: $dm-dark-grey;
  }

  .react-datepicker__input-container input {
    background: $dm-grey;
    color: $white-color;
    border: 1px solid $dm-text-grey !important;

    @include inputPlaceholder() {
      color: $dm-text-grey;
    }

    &:focus {
      border: 1px solid $theme-color !important;
    }
  }

  .bs-popover-auto[x-placement^="bottom"]>.arrow::after,
  .bs-popover-bottom>.arrow::after {
    border-bottom-color: #31343c;
  }

  .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
  .bs-popover-bottom .popover-header::before {
    border-bottom-color: #31343c;
  }


}

.checkbox-container--mobile {
  @media screen and (max-width: 991px) {
    background: $dm-dark-grey;
    color: $white-color;
  }
}

.dark-mode {
  .horizontal-chart-element--title {
    color: $white-color-70;
  }

  .horizontal-chart-element {
    color: $white-color-70;
  }

  .advanced-filters__sidebar {
    background: $dm-grey;
  }

  .badge-custom {
    background: #434954;
  }

  .btn-secondary-action {
    background: $blue-color;
    border: 1px solid $blue-color;
    color: $theme-color !important;

    &:hover {
      background: darken($blue-color, 0.8);
      background: 1px solid darken($blue-color, 0.8);
    }

    &:focus {
      background: darken($blue-color, 0.7);
      background: 1px solid darken($blue-color, 0.7);
    }
  }

  .add-card {
    height: 100%;
    background: #25272d;
  }

  .max-model-box {
    background: #31343c;
    border: 1px solid #31343c;
  }

  .right-curtain {
    .modal-close {
      background: $dm-dark-grey;
    }
  }

  .search-row {
    background: $dm-bg-grey;
    border-bottom: 1px solid $dm-dark-grey;
  }

  .search-row-list-item {
    a {
      color: $white-color;
      background: $dm-grey;
    }

    &.selected {
      a {
        color: $theme-color;
      }
    }
  }

  .color-legend {
    background: #202227;
    border-color: #666c7b;
    color: $white-color;
  }

  .procedure-box-main .news-tile-card {
    .dashboard-card-sm-vertical {
      background: $dm-grey;
    }
  }


  .threat-actors-count {
    border: 1px solid #fff;
  }

}


.dark-mode {
  .react-datepicker {
    background: #202227;
    border-color: #666c7b;
    color: $white-color;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name,
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: $white-color;
  }

  .react-datepicker__header,
  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background: $dm-grey;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover {
    background: $dm-grey;
  }

  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background: $dm-bg-grey;
  }
}

.dark-mode {
  .header-count {
    background: $theme-grey-color;
  }
}

.dark-mode {
  .procedures-wrapper {
    background: #0e0e0f;
  }
}

.dark-mode {
  .header-search-filter {
    border: 1px solid rgba(255, 255, 255, .1);
    background: $dm-primary-grey;
  }

  .header-search {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    input {
      padding-top: 8px;
      padding-bottom: 8px;
      background: none;
      border: none;
      color: $white-color;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}