.wide-modal {
    @media screen and (min-width: 1024px) {
        min-width: 1084px;
        max-width: 1084px;
    }
}

.extra-wide-modal {
    @media screen and (min-width: 1024px) {
        min-width: 1284px;
        max-width: 1284px;
    }
}

.advanced-filters {
    @media screen and (min-width: 10224px) {
        min-width: 1084px;
    }

    .modal-header {
        padding: 6px 12px !important;
        //border-bottom: 1px solid #4F5460;
    }

    &__title {
        display: flex;
        align-items: center;

        &-text {
            margin-bottom: 0;
            padding-left: 10px;
        }
    }
}

.advanced-filters__sidebar {
    padding: 24px;
}

.advanced-filters__content {
    padding: 24px;
    z-index: 2000;
    .card {
        border: none;
    }
}