.views {
  display: inline-flex;
  font-size: 12px;
  color: #828282;
  i {
    font-size: 16px;
    margin-right: 4px;
    position: relative;
    top: 1px;
  }
}

.solution-header {
    background-color: #f8f8f8;
    border-top: 2px solid #1DB534;
    padding: 10px 16px;
    .ui.star.rating .icon {
      font-size: 14px;
      width: 16px;
    }
}
.solution-firstRow {
    display: flex;
    align-items: baseline;
}
.solution-category-type {
    color: #1DB534;
    font-size: 12px;
    margin-right: 30px;
}

.solution-date {
    color: #414141;
    font-weight: 600;
    font-size: 12px;
    /* margin-left: auto; */
}

.solution-auther-data {
    display: flex;
}

.solution-auther {
    color: #414141;
    font-weight: 600;
    font-size: 14px;
}
.solution-auther-image {
    width: 25px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 10px;
}

.solution-writer {
    color: #7F808A;
    font-size: 12px;
}
