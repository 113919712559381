.views {
  display: inline-flex;
  font-size: 12px;
  color: #828282;
  i {
    font-size: 16px;
    margin-right: 4px;
    position: relative;
    top: 1px;
  }
}

.actor-header {
    background-color: #f8f8f8;
    border-top: 2px solid #9F55A1;
    padding: 10px 16px;
    .ui.star.rating .icon {
      font-size: 14px;
      width: 16px;
    }
}
.actor-firstRow {
    display: flex;
    align-items: baseline;
}
.actor-category-type {
    color: #9F55A1;
    font-size: 12px;
    margin-right: 30px;
}

.actor-date {
    color: #3B5998;
    font-weight: bold;
    font-size: 12px;
    margin-left: auto;
}

.actor-source {
    color: #414141;
    font-weight: 600;
    font-size: 14px;
}

.actor-writer {
    color: #7F808A;
    font-size: 12px;
}
