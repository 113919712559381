.resourses-section {
  padding: 110px 0;

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 767px) {
    padding: 100px 0;
  }
}

.nav-horizontal {
  .nav-item {
    .nav-link {
      margin: 8px;
      border-radius: 4px;
      background: $white-color;
      transition: 0.3s;

      &:hover {
        background: $theme-color;
        color: $white-color;
      }

      &.active {
        background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
        color: $white-color;
      }
    }
  }
}
