.item-type-list {
  @extend .story-type-list;
}

.sidebar-right-content {
  height: calc(100vh - 96px);
  border: 1px solid $stroke-color;
}

.my-boards-list {
  @extend .story-type-list;

  .number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $grey-color-secondary;
    width: 16px;
    height: 16px;
    font-size: 12px;
    color: $white-color;
    border-radius: 50%;
  }

  li {
    position: relative;

    span {
      z-index: 2;
    }
    &.active {
      color: $theme-color;
      &:before {
        content: "";
        position: absolute;
        left: -8px;
        right: -8px;
        bottom: 0;
        top: 0;
        background: $white-color;
        border: 1px solid $grey-color-md;
        border-radius: 3px;
        z-index: 0;
        opacity: .75;
      }

    }
  }
}

.my-boards-filter-avatar {
  display: inline-block;
  margin-right: 8px;
  img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.my-boards-filter-img {
  display: inline-block;
  margin-right: 8px;
  img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.personal-labels-list {
  @extend .story-type-list;
  &-item {
    width: calc(100% - 24px);
    color: $white-color;
    font-weight: 500;
    padding: 4px 0;
    border-radius: 3px;
    position: relative;

    &:hover {
      color: $white-color!important;
    }
    &.blue {
      background: $light-blue;
    }

    &.grey {
      background: $theme-text-color;
    }

    &.critical {
      background: $red-color;
    }

    &.important {
      background: $purple-color;
    }

    &.informative {
      background: $theme-color;
    }
  }

  .label-text {
    display: inline-block;
    width: calc(100% - 30px);
    padding-left: 12px;
    white-space: pre-wrap;
  }

  .label-count {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    background: rgba(255,255,255,.2);
    z-index: 3;
  }

  &__edit {
    width: 24px;
    display: none;
  }

  li {
    &:hover {
      .personal-labels-list__edit {
        display: flex;
        justify-content: center;
        color: $dark-grey;
      }
    }
  }
}

.news-section {
  background: $white-color;
  padding: 16px 0 0;
  margin-top: 16px;
}

.article-block {
  padding: 16px;
  background: $grey-color-light-bg;
  border-radius: 3px;
  overflow-x: hidden;

  &__left {
    width: 160px;
  }

  &__right {
    width: calc(100% - 84px);
  }

  img {
    min-height: 84px;
    object-fit: cover;
  }

  h3 {
    white-space: pre-wrap;
  }

  p {
    font-size: 16px;
    white-space: pre-wrap;
  }

  p, span {
    color: $dark-grey;
  }

  &__count {
    position: absolute;
    left: 8px;
    top: 8px;
  }
}

.board-comments-section {
  margin-top: 16px;
  margin-left: 16px;
  border-left: 3px solid $theme-color;
  h5 {
    font-weight: 500;
    margin-top: 16px;
  }
}

.board-comments-list {
  list-style: none;

  &-item {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    white-space: normal;
    padding: 8px 16px;
    position: relative;
    border-radius: 4px;

    &:hover {
      background: $grey-color-light-bg;
      .board-comments__action-btns {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
      }
    }

    &:before {
      content: normal;
    }

    &:last-child {
      .border-bottom {
        border: none!important;
      }
    }
  }

  &-body {
    width: calc(100% - 48px - 16px);

    p {
      color: $dark-grey;
      margin-top: 4px;
      margin-bottom: 16px;

      a {
        color: $theme-color;
      }
    }
  }

  &-avatar {
    display: inline-block;
    width: 48px;
    height: 48px;
    img {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      object-fit: cover;
    }
  }
}


.profile-bgr {
  height: 96px;
  padding: 30px 16px 0;
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;

  &:after {
    content: "";
    background: rgba(0,0,0,.45);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &:hover {
    .profile-edit-btn {
      display: block;
    }
  }

  .profile-edit-btn {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    color: $white-color;
    cursor: pointer;
    z-index: 2;
  }

}

.profile-body {
  position: relative;
  z-index: 2;
}

.board-profile-picture {
  display: inline-block;
  position: relative;
  bottom: -20px;
  img {
    width: 56px;
    height: 56px;
    border-radius: 3px;
    border: 2px solid $white-color;
  }

  &:hover {
    .board-profile-edit-btn {
      display: block;
    }
  }

  .board-profile-edit-btn {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    color: $white-color;
    cursor: pointer;
  }
}

.board-profile-info {
  width: calc(100% - 56px);
  padding-left: 8px;
  color: $white-color;

  ul {
    li {
      color: $white-color;
      font-size: 10px;
      &:before {
        content: "|";
        color: $white-color;
      }

      &:first-child {
        padding-left: 0;
        &:before {
          content: normal;
        }
      }
    }
  }
}

.add-comment-block {
  background: $grey-color-light;
  padding-right: 12px;
  .form-control {
    border: none;
    background: none;
    padding: 12px 16px;
  }

  .btn {
    padding: 6px;
    .material-icons {
      font-size: 20px;
    }
  }
}

.quick-message, .board-message-form {
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
  background: $white-color;
  border-radius: 5px;
  border: 1px solid $border-color;

  .btn-link {
    &:hover {
      text-decoration: none;
    }
  }

  &.hidden {
    display: none;
  }

  &.shown {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }

  .form-control {
    border: 1px solid transparent;
    background: none;
    padding: 12px 16px;

    &:focus {
      border: 1px solid $theme-color!important;
    }
  }

  .btn {
    padding: 6px;
    .material-icons {
      font-size: 20px;
    }
  }
}

// .chat-container {
//   transform: rotate(180deg);
//   direction: rtl;
// }

// .message {
//   transform: rotate(180deg);
//   direction: ltr;
// }

.board-comments__action-btns {
  display: none;
  padding: 2px 5px;
  position: absolute;
  right: 10px;
  top: -15px;
  z-index: 2;
  border: 1px solid $grey-color-light-bg;
  background: $white-color;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  span {
    display: block;
    padding: 2px;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    color: $theme-text-color-medium;

    &:hover {
      color: $theme-text-color;
      background: $grey-color-light;
    }
  }
}

.message {
  .news-section {
    .article-block {
      overflow: inherit;
      position: relative;
      box-shadow: none;
      border-radius: 0;
      border-left: 3px solid $theme-color;
      &:hover {
        .board-comments__action-btns {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
        }
      }
    }
  }
}

.board-message {
  &__buttons-row {
    margin-top: 16px;
    padding-left: 16px;

    .form-control {
      padding: 4px 8px;
      height: 32px;
      font-size: 14px;
    }
  }

  &__button {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    margin-right: 12px;
    padding: 4px 8px;
    cursor: pointer;
    background: $grey-color-light-bg;
    border-radius: 4px;
    color: $theme-text-color-medium;

    &:hover {
      color: $theme-color;
    }

    span {
      display: inline-block;
      margin-right: 8px;
    }
  }
}

.board-view__image {
  width: 180px;
  img {
    width: 180px;
    border-radius: 4px;
  }
}

.technology-list-simple {
  display: flex;

  &__item {
    padding: 4px 8px;
    text-align: center;
  }

  span {
    display: block;
    line-height: 1;
    font-size: 10px;
  }
}

.article-block-top {
  &__dropdown-toggle {
    .btn-link {
      padding: 0;
      margin-left: -8px;
      text-align: left;
      color: $theme-text-color;

      &:hover {
        color: $theme-color;
      }

      &:after {
        content: normal;
      }
    }
  }

   p {
     white-space: pre-wrap;
   }

   .dropdown-item {
     display: flex;
     align-items: center;
   }
}

.add-new-tag-block {
  display: flex;
  align-items: center;

  .form-control {
    height: 27px;
  }

  .btn-primary, .btn-secondary {
    height: 25px;
    padding: 4px 12px;
    font-size: 14px;
    line-height: 1;
  }
}
