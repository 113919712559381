.add-exp-wrapper {
  margin: 0 -14px;
}

.add-exp {
    flex:1;
    text-align: center;
    margin-bottom: 20px;
  	background-color: #f5f5f5;
  	border-radius: 0px;
  	display:inline-block;
  	cursor:pointer;
  	color:#666666;
  	font-size: 16px;
  	font-weight: 600;
  	padding: 6px 24px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
    &:hover {
      color: #3B5998;
    }

    &.active {
      background: #3B5998;
      color: #fff;
    }
}
