.filter-stars {
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    a {
      text-decoration: underline;
      color: #3B5998;
    }
}
