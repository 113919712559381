.main-content{
    text-align: left !important;
    width: 100% !important;

    .card-footer {
      background: #fff;
      border-top: 1px solid #d8dce5;
      color: #828282;
      padding-left: 0;
      padding-right: 0;
      box-shadow: none;
      span, a {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        color: #828282;
        font-size: 14px;
        margin: 0 2px;
        &:hover {
          color: #3B5998;
        }
        i {
          position: relative;
          top: 1px;
          margin-right: 6px;
          font-size: 18px;
        }
      }

      .likes, .favorite {
        .material-icons {
          color: #3B5998;
        }

        .material-icons-outlined {
          color: #828282;
        }
      }
    }

    .filter-stars {
      padding-left: 15px;
      padding-right: 15px;
    }
}

.card-footer {
  height: auto;
}

.card-footer-action-btns {
  padding: 0px 15px 0;
  align-items: center;
  a, span {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    color: #828282;
    &:hover {
      text-decoration: none;
      color: #3B5998;
    }

    i {
      position: relative;
      top: 1px;
      margin-right: 6px;
      font-size: 18px;
    }
  }

  .likes {
    i {
      top: 3px!important;
    }
  }

  .comments {
    i {
      top: 2px!important;
    }
  }
}

.show-all {
  text-decoration: underline;
  cursor: pointer;
}

.image-area{
    display: flex;
    align-items: flex-start;
    padding: 16px;
    ul {
      white-space: pre-wrap;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }
}

.tile-top{
    cursor: pointer;
    color: grey;
    font-size: 14px;
    &:hover{
      text-decoration: underline;
    }
}

.item-title {
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
    line-height: 1.25;
    @media screen and (max-width: 767px) {
  		font-size: 16px;
  	}
}

.item-image {
    margin: 5px;
    width: 140px;
    height: 100px;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0,0,0,.15);
    object-fit: cover;

    @media screen and (max-width: 767px) {
  		width: 100px;
      height: 60px;
  	}

    a {
      &:hover {
        text-decoration: none;
      }
    }
}


.titleAndTileTop {
  width: calc(100% - 140px);
  padding-right: 15px;

  @media screen and (max-width: 767px) {
    width: calc(100% - 100px);
  }
}

hr {
    border: 1px solid lightgray;
    border-bottom: 0;
}

.item-date {
  font-size: 12px;
  color: #828282;
  padding-left: 8px;
  padding-top: 4px;
  position: relative;
  top: 2px;
  p {
    margin: 0 4px 0 0;
  }
}

.infinite-scroll-component  {
  overflow: inherit!important;


  &.table-view {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}


a {
  color: black;
  text-decoration: none;
}

.text-xs {
  font-size: 12px;
}

.text-md {
  font-size: 14px;
}

.text-lightgrey {
  color: rgba(65, 65, 65, 0.75);
}

.dropdown-menu {
  border-radius: 0;
}

.block-card {
  background: #fff;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 767px) {
    box-shadow: none;
    background: transparent;
  }
  &-header {
    padding: 16px 20px;
    background: #f8f9fa;
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
    h3 {
      font-weight: 600;
      font-size: 20px;
    }
  }
  &-body {
    padding: 20px;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
}

.profile-picture {
  width: 100px;
  border-radius: 50%;
  border: 2px solid #3B5998;
}

.profile-name {
  h2 {
    font-size: 32px;
    font-weight: 500;
    margin: 0;
  }
  p {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
}

.card-custom {
  background: #fff;
  border-radius: 4px;
  @media screen and (max-width: 767px) {
    background: none;
  }
}

.edit-profile {
  padding-top: 20px;
}


.feedback-item {
  box-shadow: 0 0 6px rgba(0,0,0,.15);
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 16px;
  cursor: pointer;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid #3B5998;
  }
  img {
    max-width: 48px;
    margin-right: 16px;
    @media screen and (max-width: 767px) {
      margin-right: 10px;
    }
  }

  h3 {
    margin-bottom: 6px;
  }
  p {
    color: #6c757d;
  }
}


.exact-match {
  position: absolute;
  left: -120px;
  top: 8px;
  padding: 8px;
  @media screen and (max-width: 575px) {
    padding-left: 0;
    position: relative;
    top: 0;
    left: 0;
    background: none;
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
    align-items: flex-start!important;
    justify-content: flex-start!important;
  }

  .checkbox-container {
    padding-left: 0;
    padding-right: 28px;

    .checkmark {
      left: auto;
      right: 0;
    }
  }
}

.show-toggle {
  @media screen and (max-width: 767px) {
		display: flex;
  }
  margin-bottom: 10px;
}

.main-area {
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: flex-start;
}

.left-side {
    flex-basis: 15%;
    margin: 0 !important;
    text-align: center;
}
.item-list {
    flex-basis: 70%;
    margin: 0 !important;

}
.right-side {
    flex-basis: 15%;
    margin: 0 !important;
    text-align: center;

}
