.box-area {
    font-family: 'Open Sans', sans-serif;
    background-color: #f8f9fa;
}

.main-item {
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: #fff;
}

.current-relations{
    flex-basis: 20%;
}

.choose-relations{
    flex-basis: 60%;
}

.selected-relations{
    flex-basis: 20%;
}

.item-image-small{
    width: 50px;
    height: 50px;
}

.exist-relation-title {
    margin-bottom: 0;
    margin-top: 20px;
    font-weight: 600;
}

.title {
    font-size: 12px;
    color: rgb(68, 66, 66);
}

.category-type {
    margin-bottom: 5px;
    font-size: 12px;
}

.section-title {
    background-color: #3B5998;
    color: #fff;
    padding: 5px;
}