.sfButtons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    border-radius: 25px;
    background: transparent;
  	border:1px solid $grey-color-secondary;
    cursor: pointer;
    color: $grey-color-secondary;
    font-size: 12px;
    padding: 4px 10px;
    margin: 2px 2px;
    text-decoration: none;
    &:hover, &:focus {
      box-shadow: none;
      outline: none;
      border-color:  $theme-color;
      color:  $theme-color;
    }
    &:first-child {
      margin-left: 0;
    }
    span {
      width: 90%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    i {
      font-size: 12px;
      padding-left: 6px;
    }
    &.active {
      background:  $theme-color !important;
    }
}
