// Colors
$Actors : #9F55A1;
$Actors-alfa : rgba(159,85,161,0.7);
$Stories : #AD6A05;
$Stories-alfa : rgba(173,106,5,0.7);
$Threats : #FF4040;
$Threats-alfa : rgba(255,64,64,0.7);
$Solutions : #1DB534;
$Solutions-alfa : rgba(29,181,52,0.7);
$Companies : #5D61E7;
$Companies-alfa : rgba(93,97,231,0.7);
$Experts : #15AFD1;
$Experts-alfa : rgba(21,175,209,0.7);
$Breaches : #E617D1;
$Breaches-alfa : rgba(230,23,209,0.7);

.top-filters {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    @media screen and (max-width: 767px) {
  		display: none;
      margin-top: 10px;
  	}
}

.topFilter-button {
	background: rgba(255,255,255,.75);
	border-radius: 0!important;
	border:1px solid #dcdcdc;
  border-right: none;
	display:inline-block;
	cursor:pointer;
	color: #333;
	font-size: 12px;
	padding: 6px 24px;
	text-decoration:none;

  @media screen and (max-width: 991px) {
		margin-bottom: 4px;
    margin-left: 4px;
    border-right: 1px solid #dcdcdc;
	}
  &:hover {
    background: #fff;
  }
  &:hover, &:focus {
    box-shadow: none;
    outline: none;
  }
  &:last-child {
    border-right: 1px solid #dcdcdc;
  }
}

.topFilter-button.Stories {
	background-color:$Stories !important;
	color: white;
}
.topFilter-button.Threats {
	background-color:$Threats !important;
	color: white;
}
.topFilter-button.Actors {
	background-color:$Actors !important;
	color: white;
}
.topFilter-button.Solutions {
	background-color:$Solutions !important;
	color: white;
}
.topFilter-button.Companies {
	background-color:$Companies !important;
	color: white;
}
.topFilter-button.Experts {
	background-color:$Experts !important;
	color: white;
}

.selected-buttons {
	text-align: center;
	margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
}

.favorite-button {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  background: #fff;
  color: #333;
  @media screen and (max-width: 767px) {
    display: none;
    border: 1px solid #dcdcdc;
    color: #333;
  }
  i {
    font-size: 16px;
  }
}

.clearFilters-button {
  display: inline-block;
  padding: 4px;
  font-size: 12px;
  background: #3B5998;
  color: #fff;
  left: auto;
  cursor: pointer;
  margin-top: 12px;
  @media screen and (max-width: 767px) {
    text-align: left;
    padding-bottom: 6px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.dropdown-menu {
  .mt-20 {
    margin-top: 20px;
  }
  .favorite-button {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }

  .clearFilters-button {
    display: inline-flex;
  }
}
