.btn,
button {
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.btn-lg {
  width: 270px;
}

.btn-xxs {
  font-size: 12px !important;
}

.action-buttons {
  font-size: 12px;
  color: rgb(158, 158, 158);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;
}

.btn-primary {
  font-size: 16px;
  padding: 15px 32px 14px;
  border-radius: 4px;
  color: #fff;
  transition: 0.3s;
  font-weight: bold;
  background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
  border-radius: 2px;
  border: 1px solid transparent;
  transition: 0.3s;
  min-width: 140px;
  line-height: 16px;
  position: relative;

  &--rounded {
    border-radius: 50px;
  }

  span {
    position: relative;
    z-index: 2;
  }

  &:hover {
    border: 1px solid transparent;
    box-shadow: none;
    outline: none;
    color: #fff;
    background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);

    &:after {
      content: "";
      position: absolute;
      display: block;
      left: 6px;
      right: 6px;
      bottom: 0;
      top: 14px;
      background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
      opacity: 0.5;
      filter: blur(10px);
      z-index: -1;
    }
  }

  &:focus {
    outline: none;
    border: 1px solid transparent;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
  }

  &.btn-xs {
    padding: 4px 10px;
  }

  &.btn-sm {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1;
    width: auto;
    min-width: auto;
    @media screen and (max-width: 1280px) {
      font-size: 12px;
    }
  }

  &[disabled] {
    background: $grey-color-md;
    border-color: $grey-color-md;
  }

  &.btn-custom {
    padding: 12px 24px;
    font-size: 16px;
  }
}

.btn-info {
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 500;
  color: #fff;
  background-color: $purple-color;
  border-color: $purple-color;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
    padding: 8px 10px;
  }

  &:hover,
  &:focus {
    background: darken($purple-color, 10%);
    border: 1px solid darken($purple-color, 10%);
    box-shadow: none;
    outline: none;
  }
}

.btn-outline-primary {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  padding: 20px 32px 16px;
  background: linear-gradient(79.65deg, rgb(71 47 36) -1.34%, rgb(74 33 36) 65.87%);
  border-radius: 4px;
  border: 1px solid #f34343 !important;
  color: #fff;
  transition: 0.3s;
  font-weight: bold;
  position: relative;
  z-index: 1;
  outline: none;
  box-shadow: none;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
    z-index: -1;
    transition: opacity 0.3s linear;
    opacity: 0;
  }

  &:hover,
  &:focus {
    color: #fff;

    &:before {
      opacity: 1;
    }
  }

  &:active {
    background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
    border: 1px solid #f34343 !important;
  }

  &.btn-md {
    padding: 12px 20px 13px;
    font-size: 14px;
    @media screen and (max-width: 1280px) {
      font-size: 12px;
    }
  }

  &.btn-sm {
    padding: 6px 8px;
    font-size: 14px;
    @media screen and (max-width: 1280px) {
      font-size: 12px;
    }
  }
}

.btn-outline-light {
  display: inline-flex;
  align-items: center;
  font-family: "Acumin Pro";
  border: 1px solid $white-color;
  color: $white-color !important;
  padding: 15px 16px 13px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 4px;
  background: transparent;
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    border-color: transparent !important;
    background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
    color: #fff !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.btn-outline-white {
  border: 1px solid $grey-color-md;
  color: $theme-text-color !important;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 5px;
  background: transparent;
  @media screen and (max-width: 1200px) {
    font-size: 14px;
    padding: 8px 10px;
  }

  &:hover,
  &:focus {
    border-color: darken($theme-color, 10%) !important;
    background: darken($theme-color, 10%) !important;
    color: #fff !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.btn-outline-info {
  border: 1px solid $white-color;
  color: $white-color !important;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 5px;
  background: transparent;
  &:hover,
  &:focus {
    border-color: darken($theme-color, 10%) !important;
    color: darken($theme-color, 10%) !important;
    background: transparent;
    box-shadow: none !important;
    outline: none !important;
  }

  &.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 14px;
    @media screen and (max-width: 1280px) {
      font-size: 12px;
    }
  }
}

.btn-linkedin {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 1;
  border-radius: 5px;
  color: $dark-grey;
  border: 1px solid rgba(65, 65, 65, 0.25);

  &:hover,
  &:focus {
    background: $grey-color-light-bg;
    box-shadow: none;
    outline: none;
  }
}
.btn-link {
  color: $theme-color;
  font-size: 16px;
  border: none;
  background: none;
  &:hover {
    text-decoration: underline;
  }

  &.grey {
    color: $grey-color-secondary;

    &:hover {
      color: $theme-color;
      text-decoration: none;
    }
  }
}

.btn.btn-lg {
  padding: 16px 20px;
  font-size: 18px;
  font-weight: 500;
}

.btn-rounded {
  border-radius: 50px !important;
}

.btn-secondary {
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 500;
  background: $grey-color-secondary;
  border: 1px solid $grey-color-secondary;
  color: $white-color;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
    padding: 8px 10px;
  }

  &:hover {
    background: $grey-color-md;
    color: $white-color;
    border: 1px solid $grey-color-md;
  }

  &:focus {
    background: $grey-color-md;
    border: 1px solid $grey-color-md;
    color: $theme-text-color;
  }

  &.btn-sm {
    font-size: 14px;
    padding: 5px 8px;
    @media screen and (max-width: 1280px) {
      font-size: 12px;
    }
  }
}

.btn-secondary-action {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 500;
  background: $white-color;
  border: 1px solid $theme-color;
  color: $theme-color !important;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
    padding: 8px 10px;
  }

  &:hover {
    color: $white-color !important;
    background: darken($blue-color, 0.8);
    border: 1px solid darken($blue-color, 0.8);
  }

  &:focus {
    color: $white-color !important;
    background: darken($blue-color, 0.7);
    border: 1px solid darken($blue-color, 0.7);
  }
}

.btn-toggle {
  display: inline-block;
  position: relative;
  margin: 0 8px;
  font-weight: bold;

  &:hover {
    color: $theme-color;
  }

  &.active {
    color: $theme-color;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: $theme-color;
    }
  }
}

.share-list {
  list-style: none;
  &--rounded {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 4px;
    margin-right: 8px;
    border-radius: 50%;
    list-style: none;
    cursor: pointer;

    &:before {
      content: normal;
    }

    &.linkedin {
      background: #0a66c2;
      color: $white-color;
      &:hover {
        background: darken(#0a66c2, 10%);
      }
    }

    &.twitter {
      background: #1c95e0;
      color: $white-color;
      &:hover {
        background: darken(#1c95e0, 10%);
      }
    }

    &.bookmark {
      background: $theme-color;
      color: $white-color;
      &:hover {
        background: darken($theme-color, 10%);
      }
    }
  }
}

.add-tag-btn {
  height: 25px;
  display: inline-block;
  padding: 6px 8px;
  border-radius: 3px;
  margin-right: 8px;
  line-height: 1;
  font-weight: 500;
  font-size: 12px;
  background: #e4e7ee;
  cursor: pointer;

  &:hover {
    background: $theme-color;
    color: $white-color;
  }

  &--lg {
    display: flex;
    align-items: center;
    height: 32px;
    width: calc(100% - 32px);
    margin-bottom: 8px;
  }
}

.btn-outline-secondary {
  border: 1px solid $grey-color-md;
  font-size: 14px;
  padding: 8px 16px;
  &.btn-sm {
    line-height: 1;
  }

  &.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 14px;
    @media screen and (max-width: 1280px) {
      font-size: 12px;
    }
  }
}

.dropdown-toggle {
  &.no-arrow {
    &:after {
      content: normal;
    }
  }
}

.btn-simple-text-light {
  display: inline-flex;
  flex-flow: column;
  align-items: center;
  font-family: "Acumin Pro";
  font-size: 14px;
  color: $white-color;
  padding: 10px 16px 8px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 4px;
  background: transparent;
  transition: 0.3s;
  cursor: pointer;
  position: relative;

  &:hover {
    &::after {
      content: "";
      display: block;
      height: 2px;
      width: 100%;
      background: #fff;
      transition: 0.3s;
    }
  }
}

.btn-gradient {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  padding: 20px 32px 16px;
  background: linear-gradient(79.65deg, rgb(71, 47, 36) -1.34%, rgb(74, 33, 36) 65.87%);
  border-radius: 4px;
  border: 1px solid #f34343;
  color: #fff;
  transition: 0.3s;
  font-weight: bold;
  position: relative;
  z-index: 1;

  &--sm {
    padding: 12px 16px 13px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
    z-index: -1;
    transition: opacity 0.3s linear;
    opacity: 0;
  }

  &:hover,
  &:focus {
    color: #fff;

    &:before {
      opacity: 1;
    }
  }
}

.btn-solid-grey {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  padding: 20px 32px 16px;
  background: rgb(26, 26, 26);
  border: 1px solid rgb(26, 26, 26);
  border-radius: 4px;
  color: #fff;
  transition: 0.3s;
  font-weight: bold;

  &:hover {
    color: #fff;
    border: 1px solid rgb(255, 255, 255);
    background: transparent;
  }

  &:focus {
    color: #fff;
    border: 1px solid rgb(255, 255, 255);
    background: rgb(26, 26, 26);
  }
}

.btn-gradient-blue {
  font-size: 16px;
  padding: 15px 32px 14px;
  border-radius: 4px;
  color: #fff;
  transition: 0.3s;
  font-weight: bold;
  background: linear-gradient(315.33deg, #3b5aff 17.82%, #8fa1ff 101.18%);
  border-radius: 2px;
  border: none;
  transition: 0.3s;
  min-width: 140px;
  line-height: 16px;
  position: relative;
  z-index: 1;

  &--rounded {
    border-radius: 50px;
  }

  span {
    position: relative;
    z-index: 2;
  }

  &:hover {
    border: none;
    box-shadow: none;
    outline: none;
    color: #fff;
    background: linear-gradient(315.33deg, #3b5aff 17.82%, #8fa1ff 101.18%);

    &:after {
      content: "";
      position: absolute;
      display: block;
      left: 6px;
      right: 6px;
      bottom: 0;
      top: 14px;
      background: linear-gradient(315.33deg, #3b5aff 17.82%, #8fa1ff 101.18%);
      opacity: 0.5;
      filter: blur(10px);
      z-index: -1;
    }
  }

  &:focus {
    outline: none;
    border: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(79.65deg, #3b5aff -1.34%, #8fa1ff 65.87%);
  }
}

.btn-gradient-red {
  font-size: 16px;
  padding: 15px 32px 14px;
  border-radius: 4px;
  color: #fff;
  transition: 0.3s;
  font-weight: bold;
  background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
  border-radius: 2px;
  border: none;
  transition: 0.3s;
  min-width: 140px;
  line-height: 16px;
  position: relative;
  z-index: 1;

  &--rounded {
    border-radius: 50px;
  }

  span {
    position: relative;
    z-index: 2;
  }

  &:hover {
    border: none;
    box-shadow: none;
    outline: none;
    color: #fff;
    background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);

    &:after {
      content: "";
      position: absolute;
      display: block;
      left: 6px;
      right: 6px;
      bottom: 0;
      top: 14px;
      background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
      opacity: 0.5;
      filter: blur(10px);
      z-index: -1;
    }
  }

  &:focus {
    outline: none;
    border: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
  }
}

.btn-text-gradient {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0;
  border: none;
  background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;

  span {
    display: inline-flex;
    line-height: 2;
    padding-top: 4px;
    padding-right: 6px;
  }

  &:hover {
    border: none;
    background: linear-gradient(79.65deg, #ff1a1a -1.34%, #f68014 65.87%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
