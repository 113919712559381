.right-sidebar-block {
  position: relative;
  margin-bottom: 12px;
  &:first-child {
    margin-top: 40px;
  }
    h4 {
      font-weight: 900;
      margin: 8px 0;
      font-size: 16px;
      color: #3B5998;
    }

    &-item {
      background: #fff;
      .item-image {
        margin: 0;
        width: 100%;
        border-radius: 4px 4px 0 0;
      }

      .play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
      &-content {
        padding: 12px;
        .btn {
          padding: 4px 20px;
          margin-top: 10px;
          margin-bottom: 8px;
        }
        .date {
          display: block;
          margin-bottom: 12px;
          opacity: .5;
          font-size: 12px;
        }
      }
    }
}

.right-sidebar {
  margin-top: 40px;
}
