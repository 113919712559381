.detections-back {
    display: inline-flex;
    align-items: center;
    color: $dark-grey;
    font-weight: 500;
    gap: 16px;

    &:hover {
        color: $theme-color;
        text-decoration: none;
    }
}

.detection-subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    background: $white-color;
}

.detections-list {
    display: inline-flex;
    gap: 4px;
}

.detections-list-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 182px;
    padding: 4px 14px;
    background: $grey-color-light-bg;
    flex: 1;
    font-size: 12px;
    border-radius: 4px;
    position: relative;
    border: 1px solid $grey-color-light-bg;

    // &.marked {
    //     &:after {
    //         content: "";
    //         width: 18px;
    //         height: 18px;
    //         background: url("../../../../images/trandge-badge.png") 0 0 no-repeat;
    //         background-size: contain;
    //         position: absolute;
    //         top: 0;
    //         right: 0;
    //         border-radius: 0 4px 0 0;
    //     }
    // }

    &.selected {
        border-color: $grey-color-secondary;
        background: $grey-color-light;
    }

    &-content {
        display: inline-flex;
        flex: 1;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $dark-grey;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.2;
    }

    &-logo {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #000;
    }
}

.detection-buttons-wrap {
    display: flex;
    gap: 4px;
}

.detection-button {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $grey-color-light-bg;
    ;
    color: $dark-grey;

    &:hover {
        color: $theme-color;
    }
}

.detections-tabs {
    padding: 8px;
    background: $grey-color-light;

    .nav-item {
        padding: 9px 12px;
        color: $dark-grey;
        border-radius: 0;
        font-size: 12px;
        font-weight: 500;
        border: none;

        &.active,
        &:hover {
            background: $white-color;
            color: $dark-grey;
            border: none;
        }


    }

    .tab-content {
        background: $white-color;
    }
}


.detections-tabs-wrapper {
    display: flex;
}

.detections-tabs-left {
    width: 40%;
    height: calc(100vh - 36px - 115px);
    overflow: auto;
}

.detections-tabs-right {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: calc(100vh - 36px - 115px);
    overflow: hidden;
}

.detection-rule-card {
    padding: 12px 16px;
    margin-bottom: 4px;
    background: $grey-color-light-bg;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, .1);

    &.selected {
        background: $grey-color-light;
        border: 1px solid $dark-grey;
    }
}

.detection-rule-card-title {
    color: $dark-grey;
    font-size: 14px;
}

.detection-rule-card-content {
    font-size: 12px;
    color: $dark-grey;
}

.detections-logo {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    gap: 4px;
    font-size: 10px;
    color: $dark-grey;
    font-weight: 500;
}

.detections-logo-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background: $black;
    border-radius: 50%;
}

.detection-rule-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detection-rule-card-number {
    background: $bg-light;
    font-size: 10px;
    padding: 4px 8px;
    margin-right: 8px;
}

.detection-rule-card-date {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    color: $dark-grey;
}

.detection-rule-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
}

.detection-rule-title {
    font-size: 14px;
    color: $dark-grey;
}

.detection-rule-filter {
    color: #53535B;
    cursor: pointer;
}

.detections-tabs-right-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background: #d7dce6;
}

.procedure-list {
    display: flex;
    gap: 16px;
    width: 88%;
}

.procedure-list-item {
    display: flex;
    flex: 1;
    width: 50%;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: $white-color;
}

.procedure-list-item-content {
    display: flex;
    gap: 8px;
    width: calc(100% - 100px);
    color: $dark-grey;
}

.procedure-list-number {
    display: inline-flex;
    justify-content: center;
    width: 100px;
    padding: 4px 8px;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 2px;
    font-size: 12px;
    color: $dark-grey;
}

.procedure-list-title {
    display: inline-flex;
    white-space: nowrap;
    width: 75px;
}

.procedure-list-content {
    display: inline-block;
    color: $dark-grey;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.editor-wrapper {
    display: flex;
    flex-flow: column;
    flex: 1;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, .25);
    background: $bg-light;
    overflow: hidden;
}

.editor-wrapper-header {
    height: 50px;
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    .checkbox-container {
        font-size: 12px;
        line-height: 16px;
        color: $dark-grey;
        ;
    }

    .checkbox-container .checkmark:after {
        width: 5px;
        height: 10px;
    }

    .checkmark {
        width: 16px;
        height: 16px;
    }
}

.editor-action-link {
    display: inline-flex;
    align-items: center;
    margin-left: 16px;
    gap: 5px;
    font-size: 12px;
    color: $dark-grey;
    cursor: pointer;

    &:hover {
        color: $theme-color;
    }
}

.editor-body {
    padding: 0;
    position: relative;
    flex: 1;
    overflow: auto;

    &:after {
        content: "";
        display: block;
        width: 1px;
        position: absolute;
        left: 40px;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .1);
    }
}

.editor-row {
    display: flex;
    align-items: start;
}

.editor-number {
    padding: 2px 16px;
}

.editor-row-definition {
    color: #50e173;
}

.editor-row-value {
    color: #74b0f5;
}

.editor-row-content {
    display: flex;
    align-items: start;
    gap: 4px;
    padding: 2px 16px;
    font-size: 12px;
}

.detection-chains-header-left {
    display: flex;
    align-items: start;
    gap: 16px;
}

.detection-chains-header {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
}

.detection-chains-title {
    font-weight: bold;
}

.detection-chains-body {
    padding: 0 12px 12px;
}

.detection-chains-title {}

.detection-chains-filters {
    display: flex;
    align-items: center;
    gap: 8px;
}

.detection-chains-filter {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    line-height: 1;
    cursor: pointer;

    &.active,
    &:hover {
        background: #FD4A19;
        color: #fff;
    }
}

.detection-chains-number {
    display: inline-block;
    padding: 4px 12px;
    color: #FB3416;
    background: rgba(251, 52, 22, 0.22);
    border-radius: 25px;
}

.chain-card {
    width: 100px;
    min-height: 120px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: end;

    img {
        display: block !important;
        height: auto !important;
        max-width: 16px;
    }

    &-number {
        display: inline-flex;
        padding: 2px 4px;
        font-size: 8px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
    }
}

.detection-chain-rule-card {
    &:hover {
        .urls-window {
            display: block !important;
        }
    }
}

.events-window {
    font-size: 12px;
    position: absolute;
    top: 10px;
    left: -10px;
    background: red !important;
}

.urls-window {
    display: none;
    font-size: 12px;
    position: absolute;
    top: -60px;
    right: -50px;
    background: #000000 !important;
    z-index: 10;
}

.events-window-top-row {
    font-size: 12px;
    position: absolute;
    top: -15px;
    left: 10px;
    background: red !important;
}

.chain-card {
    padding: 6px;
    margin-bottom: 8px;
    border-radius: 8px;
    background: $bg-light;
    border: 1px solid $border-color;

    &.blue {
        background: #53BDF8;
        color: #0E0E10;

        .chain-card-number {
            border-color: rgba(0, 0, 0, .2);
        }
    }

    &.purple {
        background: #AC6AFF;
        color: #fff;

        .chain-card-number {
            border-color: rgba(0, 0, 0, .2);
            color: #0E0E10;
        }
    }

    &.disabled {
        opacity: .5;
    }
}

.chain-card-content {
    display: block;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: left;
    font-size: 12px;
    line-height: 1.2;
    word-break: break-word;
}

.procedures-wrapper {
    padding: 15px;
    display: flex;
    flex-flow: column;
    flex: 1;
    background: $bg-light;
    border-radius: 10px;
}

.link-area {
    gap: 8px;
    font-size: 16px;
    color: $grey-color-secondary;
    background-color: $theme-grey-color;
    min-height: 50px;
    padding: 10px;
}