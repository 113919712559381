i.icon {
    padding: 0 20px;
}

.expert-outline{
    fill: blue;
    width:18px;
    height: 18px;
    margin-left: 20px;
}

.comment-icon {
    width: 20px;
    height: auto;
    margin-right: 5px;
}
