@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

@font-face {
    font-family: "Acumin Pro";
    src: url("../../../fonts/AcuminPro-Bold.eot");
    src: url("../../../fonts/AcuminPro-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../../fonts/AcuminPro-Bold.woff2") format("woff2"),
        url("../../../fonts/AcuminPro-Bold.woff") format("woff"),
        url("../../../fonts/AcuminPro-Bold.ttf") format("truetype"),
        url("../../../fonts/AcuminPro-Bold.svg#AcuminPro-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Acumin Pro";
    src: url("../../../fonts/AcuminPro-Regular.eot");
    src: url("../../../fonts/AcuminPro-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../../fonts/AcuminPro-Regular.woff2") format("woff2"),
        url("../../../fonts/AcuminPro-Regular.woff") format("woff"),
        url("../../../fonts/AcuminPro-Regular.ttf") format("truetype"),
        url("../../../fonts/AcuminPro-Regular.svg#AcuminPro-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
