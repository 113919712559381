.profile-page {
  &__header {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__body {
    padding: 0 16px;
    .h-100 {
      @media screen and (min-width: 992px) {
        min-height: calc(100vh - 67px);
      }
    }

    &-left {
      padding: 16px 0;
      border-right: 1px solid $border-color;

      .nav-pills {
        padding: 0 16px;
        margin-top: 24px;
        border-top: 1px solid $border-color;

        .nav-link {
          margin: 8px 0;
          &:hover {
            color: $theme-color;
          }
          &.active {
            background: $theme-color;
            &:hover {
              background: darken($theme-color, 10%);
              color: $white-color;
            }
          }
        }
      }
    }

    &-right {
      padding: 16px;

      .table-bordered {
        th {
          border: 1px solid $grey-color-light;
          border-bottom: none;
        }

        td {
          border: 1px solid $grey-color-light;
        }
      }
    }

    .tab-content {
      max-width: 800px;
      margin: 0 auto;
    }
  }

  &__avatar {
    width: 64px;
    height: 64px;
    margin: 0 auto;
    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }
  }
}

.payment-card {
  width: 300px;
  padding: 16px;
  display: inline-flex;
  background: $bg-light;
  border: 1px solid $border-color;
  border-radius: 4px;

  &__edit {
    // width: 300px;
    padding-bottom: 4px;
    // text-align: right;
    cursor: pointer;
    color: $theme-color;
    &:hover {
      text-decoration: underline;
    }
  }
  
  &__default {
    // width: 300px;
    padding-bottom: 4px;
    // text-align: left;
    cursor: pointer;
    color: $theme-color;
    &:hover {
      text-decoration: underline;
    }
  }

  &__number {
    font-weight: bold;
    font-size: 18px;
  }

  &__name {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 12px;
  }

  &__exp {
    color: $theme-text-color-medium;
    font-size: 12px;
  }

  &__logo {
    width: 68px;
  }

  &__body {
    padding: 0 16px;
    width: calc(100% - 68px);
  }
}
