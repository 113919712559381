.user-data {
    display: flex;
    align-items: center;
}

.user-name {
    margin: 0 10px;
}

.user-image {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #3B5998;
}

.login-Bar {
  .dropdown-menu {
    left: auto;
    right: 0;
  }
}

.custom-toggle {
  &:hover {
    text-decoration: none;
  }
}

.btn.menu-toggle {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  padding: 6px 0;
  background: none;
  border: none;
  color: #333;
  i {
    font-size: 30px;
  }
  &:hover, &:focus, &:active {
    color: #3B5998;
    background: none!important;
    border: none!important;
  }
  &:after {
    content: normal;
  }
}

.show {
  .menu-toggle {
    color: #3B5998!important;
    background: none!important;
  }
}

.action-button-menu {
  width: 100%;
  padding-top: 10px;
  border-top: 1px solid rgba(0,0,0,.05);
  a {
    padding: 6px 20px;
  }
}
