
.bg-light {
  height: 60px;
  background: #fff!important;
}

.navbar {
  &.normal {
    box-shadow: 0 0 4px rgba(0,0,0,.15);
  }
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
}

.beta {
  position: absolute;
  bottom: -8px;
  left: 44px;
  font-size: 12px;
  opacity: .75;
}

.navbar-light {
  .navbar-toggler {
    border: none;
    box-shadow: none;
    outline: none;
    &:hover, &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }

    .navbar-toggler-icon {
      width: 24px;
      height: 24px;
      background: url("../../../../images/bars.svg") 0 0 no-repeat;
      background-size: 24px;
    }
  }
}

.navbar-collapse {
  @media screen and (max-width: 991px) {
    position: fixed;
    right: -310px;
    width: 310px;
    height: 100%!important;
    top: 0;
    bottom: 0;
    padding: 30px 16px;
    background: #fff;
    box-shadow: 0 0 16px rgba(0,0,0,.15);
    z-index: 2;
    transition: .3s;
    overflow: auto;
    &.show {
      right: 0;
    }

    .btn {
      width: 100%;
      margin-top: 16px;
    }
  }

  .navbar-toggler-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 24px;
    height: 24px;
    opacity: 0.75;
    text-indent: -99999px;
    background: none!important;

    cursor: pointer;
    &:before, &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #333;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.nav-tabs {
  border: none;
  @media screen and (max-width: 991px) {
    padding-top: 20px;
  }
  .nav-item {
    padding: 0 8px;
    @media screen and (max-width: 1200px) {
      padding: 0 4px;
    }
    @media screen and (max-width: 991px) {
      padding: 8px;
    }

    .nav-link {
      font-size: 14px;
      padding-left: 8px;
      padding-right: 8px;
      border: none;
      position: relative;
      color: #333;
      text-transform: uppercase;
      &:hover {
        border: none;
        color: #3B5998;
      }
      &.active {
        border: none;
        background: none;
        color: #fff;
        @media screen and (max-width: 991px) {
      		color: #000;
      	}

        &[data-rb-event-key="Stories"] {
          @media screen and (max-width: 991px) {
        		color: #AD6A05;
        	}
        }

        &[data-rb-event-key="Actors"] {
          @media screen and (max-width: 991px) {
        		color: #9F55A1;
        	}
        }

        &[data-rb-event-key="Threats"] {
          @media screen and (max-width: 991px) {
        		color: #FF4040;
        	}
        }

        &[data-rb-event-key="Solutions"] {
          @media screen and (max-width: 991px) {
        		color: #1DB534;
        	}
        }

        &[data-rb-event-key="Companies"] {
          @media screen and (max-width: 991px) {
        		color: #5D61E7;
        	}
        }

        &[data-rb-event-key="Experts"] {
          @media screen and (max-width: 991px) {
        		color: #15AFD1;
        	}
        }

        &:after {
          content: "";
          position: absolute;
          bottom: -13px;
          top: -13px;
          left: 0;
          right: 0;
          z-index: -1;
          @media screen and (max-width: 991px) {
            content: normal;
          }
        }
      }

      &[data-rb-event-key="Stories"] {
        &:after {
          background: #AD6A05;
        }
      }

      &[data-rb-event-key="Actors"] {
        &:after {
          background: #9F55A1;
        }
      }

      &[data-rb-event-key="Threats"] {
        &:after {
          background: #FF4040;
        }
      }

      &[data-rb-event-key="Solutions"] {
        &:after {
          background: #1DB534;
        }
      }

      &[data-rb-event-key="Companies"] {
        &:after {
          background: #5D61E7;
        }
      }

      &[data-rb-event-key="Experts"] {
        &:after {
          background: #15AFD1;
        }
      }

      span {
        position: relative;
        z-index: 3;
      }
    }
  }
}

.mobile-toggler {
  display: inline-block;
  width: 20px;
  margin: 0 10px;
  @media screen and (min-width: 767px) {
    display: none;
  }
}

.mob-public-menu {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(0,0,0,.05);
  .nav-item {
    .nav-link {
      display: block;
      padding-top: 0px;
      padding-bottom: 0px;
      text-transform: inherit;
    }
  }
}

.public-menu {
  &.nav-tabs .nav-item .nav-link {
    &.active {
      color: #3B5998;
    }
  }
}

.home {
  color: #3B5998;
}
