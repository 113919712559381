.muislider-wrapper {
  margin-right: 0px;
  font-size: 16px;

  .MuiSlider-root.MuiSlider-colorPrimary {
    width: 150px;

    @media screen and (max-width: 767px) {
      width: 140px;
    }
  }

  .muislider-label {
    min-width: 60px;
    text-align: right;
    font-size: 12px;

    @media screen and (max-width: 1200px) {
      min-width: inherit;
      font-size: 10px;
    }
  }
}

.dashboard-header {
  @extend .news-page-filter-panel;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;

  @media screen and (max-width: 991px) {
    left: 0;
  }

  &-nav-link {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    margin-right: 8px;
    padding: 4px 8px;
    font-size: 16px;
    border-radius: 3px;
    font-weight: bold;
    color: $grey-color-secondary;
    border: 1px solid transparent;

    &.threats {
      border: 1px solid $red-color;
    }

    &.breaches {
      border: 1px solid $purple-color;
    }

    &.malware {
      border: 1px solid $theme-color;
    }

    svg {
      width: 20px;
      margin-left: 8px;
    }

    .material-icons {
      font-size: 18px;
      margin-left: 8px;
    }

    &.active {
      color: $dark-grey;
      background: $grey-color-light;
    }
  }

  .user-avatar {
    margin-right: 12px;

    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .user-dropdown-toggle {
    display: flex;
    align-items: center;
  }
}

.header-icon {
  display: inline-flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  margin-right: 6px;
  background: $grey-color-light;
  font-size: 16px;
}

.sticky-filters {
  //padding: 8px 0;
  position: sticky;
  top: 64px;
  background: $grey-color-light-bg;
  z-index: 100;
}

.dashboard-filters-panel {
  padding: 0;
}

.chart-card {
  width: 18vw;
  height: 250px;
  padding: 16px;
  background: $white-color;
  border: 1px solid $stroke-color;
  position: relative;

  &-header {
    padding-bottom: 12px;
  }
}

.dashboard-filters {
  &__toggle {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    font-size: 24px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: $bg-light;
    cursor: pointer;

    &:hover {
      color: $theme-color;
    }
  }
}

.dashboard-indicator-card {
  height: 117px;
  padding: 16px;
  margin-bottom: 16px;
  background: $white-color;
  border: 1px solid $stroke-color;
  cursor: pointer;
  position: relative;

  &.threat {
    margin-bottom: 0;

    &.active {
      &:before {
        content: "";
        width: 3px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: $red-color;
      }
    }
  }

  &.breaches {
    &.active {
      &:before {
        content: "";
        width: 3px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: $purple-color;
      }
    }
  }

  .material-icons {
    font-size: 42px;
    color: $grey-color-md;
  }

  &-title {
    display: block;
    padding-bottom: 8px;
    font-weight: bold;
  }

  &-counter {
    display: block;
    line-height: 1;
    color: $border-light-grey;
    font-size: 36px;
    font-weight: 300;
  }
}

.visibility-filter {
  margin-left: 8px;
  cursor: pointer;
}

.dashboard-filters-panel-item {
  height: 48px;
  //width: 12%;
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  border-right: 1px solid $stroke-color;
  border-bottom: 1px solid $stroke-color;
  background: $white-color;

  &.range {
    min-width: 300px;
  }

  .news-page-filter-panel-sort {
    width: 100%;

    .btn,
    button {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100% !important;
      box-shadow: none !important;
    }
  }

  .dropdown-toggle::after {
    content: normal;
  }

  .dropdown-menu {
    top: 3px !important;
    border: 1px solid $stroke-color;
    border-radius: 0;
    width: 100%;
    box-shadow: none;

    a {
      padding-left: 12px;
      padding-right: 12px;

      &:nth-child(even) {
        background: $grey-color-light-bg;
      }
    }
  }
}

.tooltip-container {
  position: relative;
  z-index: 200;
  background: $white-color;
  border-radius: 5px;
  border: 1px solid $stroke-color;
}

.menu-items-container {
  height: 600px;
  overflow: auto;
}

.tooltip-colorBox {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid $white-color;

  .material-icons {
    display: none;
    font-size: 12px;
  }
}

.tooltip-row:hover,
.tooltip-row-selected {
  background: $grey-color-light;
  color: $dark-grey;
  border-radius: 4px;
}

.tooltip-row-selected {
  .material-icons {
    display: block;
  }
}

.multi-items {
  height: 400px;
  overflow: auto;
}

.selected-filters-container {
  background: $white-color;
  border-radius: 30px;
}

.dashboard-card--table {
  .card-body {
    border-bottom: 1px solid $grey-color-light;

    &:last-child {
      border: none;
    }

    &:nth-child(odd) {
      background: $grey-color-light-bg;
    }

    &:hover {
      background: darken($grey-color-light-bg, 5%);
    }

    p {
      margin: 0;
    }
  }
}

.custom-dashboard-wrapper {
  padding: 12px 24px;
  min-height: calc(100vh - 67px);
}

.custom-dashboard-card-wrapper {
  width: 500px;
}

.title-edit-icon {
  display: none;
}

.custom-dashboard-card {
  background: $white-color;
  border-radius: 3px;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);

  &--sm-wrapper {
    width: 14.27%;
    padding: 0 12px;
  }

  &--sm {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  &-wrapper {
    padding: 12px;
  }

  &__header-action {
    display: flex;
  }

  &__header {
    height: 70px;
    // display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border-radius: 3px 3px 0 0;
  }

  &__body {
    height: 320px;
    padding: 16px;
    overflow: hidden;

    &.infinite {
      overflow: scroll;
    }

    &--auto {
      height: auto;
    }
  }

  &__footer {
    height: 34px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);

    &--no-shadow {
      box-shadow: none;
    }
  }

  &__header-title {
    display: flex;
    align-items: center;

    h3 {
      margin-bottom: 0;
      padding-bottom: 0px;
      font-size: 16px;
    }

    &:hover {
      .title-edit-icon {
        display: block;
      }
    }
  }

  &__header-toggle {
    display: inline-block;
    margin-left: 8px;
  }

  &__table {
    min-height: 360px;
    max-width: 100%;
    margin-bottom: 0;

    thead {
      tr {
        th {
          padding: 8px;
          font-size: 12px;
          border: none;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 8px;
          max-width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          border: none;
        }
      }
    }
  }
}

.setting-curtain {
  width: 340px;
  transform: translateX(340px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: $white-color;
  z-index: 200;
  overflow: auto;
  box-shadow: -6px 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s;

  &.open {
    transform: translateX(0);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    background: $bg-light;
    padding: 12px;
  }

  &__header-close {
    display: inline-block;
    margin-top: -8px;
    cursor: pointer;

    &:hover {
      color: $theme-color;
    }
  }

  &__header-title {
    display: flex;
    align-items: center;

    h3 {
      margin-bottom: 0;
    }
  }

  &__body {
    padding: 16px 12px;
  }
}

.horizontal-chart-element {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  color: $theme-text-color;

  &--left {
    width: 52%;
    line-height: 1;

    &:hover {
      color: $theme-color !important;
    }
  }

  &--right {
    width: 48%;
    overflow: hidden;
  }

  &--title {
    cursor: pointer;
    color: $theme-text-color;

    &:hover {
      color: $theme-color !important;
    }
  }
}

.colored-bar {
  position: relative;
  display: block;
  height: 6px;
  border-radius: 45px;

  //animation
  animation-name: nutritionAnimation;
  animation-duration: 800ms;
}

@keyframes nutritionAnimation {
  0% {
    left: -100px;
  }

  100% {
    left: 0;
  }
}

.search-cards-wrapper {
  display: flex;
  justify-content: center;
  padding: 16px 0;
}

/* SEARCH DASHBOARD CSS */

.max-model-box {
  background: $white-color;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
  margin-left: 38px 22px 0 0px;
  margin: 0 11px 16px 13px;
}

.max-model-box h3 {
  padding: -2px 4px 7px 6px !important;
  margin: 15px;
}

.model-title {
  display: flex !important;
  align-self: center !important;
  justify-content: space-between !important;
}

.table-popup-mitre {
  padding: 0px 16px 16px;
}

.table-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.table-icon button {
  background: transparent;
  border: 0;
  font-size: 15px;
  font-weight: 500;
}

.procedure-box {
  padding: 9px 0;
}

.threats-data p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  color: #96989c;
}

h3.align-items-center.title-underline {
  margin: 0;
}

.model-title.procedure-box-main {
  padding: 9px 0 21px 16px;
}

.procedure-box-main h3 {
  gap: 4px;
}

@media only screen and (max-width: 991px) {
  .custom-dashboard-card--sm-wrapper {
    width: 25%;
    padding-bottom: 21px;
  }

  ul.list-simple {
    white-space: break-spaces;
  }

  .search-cards-wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 16px 0;
    width: 100%;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .custom-dashboard-card--sm-wrapper {
    width: 33.33%;
    padding-bottom: 21px;
  }

  ul.list-simple {
    white-space: break-spaces;
  }

  .search-cards-wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 16px 0;
    width: 100%;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 575px) {
  .custom-dashboard-card--sm-wrapper {
    width: 100%;
    padding-bottom: 21px;
  }

  .news-search-filter {
    margin-left: 10px !important;
  }
}

.autocomplete-container {
  position: absolute;
  overflow: scroll;
  background: rgb(21, 21, 21);
  width: 75vw;
  // min-height: 300px;
  top: 70px;
  padding: 10px;
  box-shadow: -2px 0 10px rgba(85, 85, 85, 0.2);
  z-index: 1000;
}

.autocomplete-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.autocomplete-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.tiles-view--dashboard {
  margin: 0 0px;
}

.threats-data {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* NEXT PREVIOUS BUTTON */

.buttonwrap {
  display: flex;
  gap: 4px;
}

.buttonwrap button.btn.btn-primary {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #31343c;
  color: #fff;
  border: 1px solid #31343c;
}

.buttonwrap button.btn.btn-primary svg {
  color: #fff !important;
}

.dashboard-header .custombudge .badge-custom {
  margin-bottom: 0;
}