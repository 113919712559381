header {
  padding: 16px 0;
  background: $black;
  font-family: "Acumin Pro";

  .header-right {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
  }

  .simple-link {
    display: inline-block;
    padding: 8px 16px;
    font-size: 16px;
    color: $theme-text-color;

    &:hover {
      color: $theme-color;
    }
  }

  .navbar {
    position: relative;
    z-index: 2;
  }
}

.navbar-nav {
  .nav-link {
    padding: 12px 16px !important;
    font-size: 14px;
    color: $white-color;
    position: relative;
    transition: 0.3s;
    text-transform: uppercase;
    color: #fff;

    &:hover,
    &.active {
      background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      &:after {
        content: normal;
      }
    }
  }
}

.navbar-toggler {
  svg {
    color: #fff !important;
  }
}

.navbar-collapse {
  flex-grow: initial;
  background: #000;

  .btn-outline-light {
    width: auto;
  }
}

.landing {
  font-family: "Acumin Pro";
  overflow-x: hidden;
  background: #faf7f7;

  h1,
  h2,
  h3,
  h4 {
    font-family: "Acumin Pro";
    font-weight: bold;
  }

  .blue-bg-shadow {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 420px;
      height: 417px;
      top: 0;
      bottom: 0;
      right: 0;

      /* Blue */
      background: linear-gradient(315.33deg, #3b5aff 17.82%, #8fa1ff 101.18%);
      opacity: 0.25;
      filter: blur(100px);
    }

    video {
      position: relative;
      z-index: 4;
    }
  }

  .title-primary {
    padding: 24px 0;
    font-size: 48px !important;
    line-height: 1;
    font-weight: bold !important;
    position: relative;

    @media screen and (max-width: 767px) {
      font-size: 36px !important;
      line-height: 1.3;
      padding: 18px 0;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 36px !important;
      line-height: 1.3;
      padding: 20px 0;
    }

    &--light {
      color: $white-color;
    }

    &--dark {
      color: $black;
    }

    &--pink {
      color: #f0c2b3;
    }

    &--sm {
      font-size: 36px !important;

      @media screen and (max-width: 767px) {
        font-size: 24px !important;
        line-height: 1.3;
        padding-bottom: 16px;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 32px !important;
      }
    }
  }

  .section-dark {
    background: $black;
    padding: 16px 0;
  }

  .section-light-grey {
    padding: 80px 24px;
    background: #faf7f7;

    @media screen and (max-width: 767px) {
      padding: 24px 0px;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 60px 24px;
    }
  }

  .section-white {
    background: #fff;
  }

  .title-secondary {
    display: block;
    font-size: 16px !important;
    line-height: 1.5;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #f0c2b3;
    font-weight: bold;
    position: relative;

    @media screen and (max-width: 1024px) {
      font-size: 14px !important;
      line-height: 1.3;
    }
  }

  .section-description {
    font-size: 18px;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.7);

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 16px;
    }

    &--dark {
      color: $black;
    }
  }

  .text-grey {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .navbar-nav {
    .nav-link {
      padding: 12px 16px;
      font-size: 14px;
      color: $white-color;
      position: relative;
      transition: 0.3s;
      text-transform: uppercase;

      &:hover,
      &.active {
        background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        &:after {
          content: normal;
        }
      }
    }
  }

  .navbar-toggler {
    svg {
      color: #fff !important;
    }
  }

  .navbar-collapse {
    flex-grow: initial;
    background: transparent;

    @media screen and (max-width: 991px) {
      background: #000;
    }

    .btn-outline-light {
      width: auto;
    }
  }

  header {
    background: rgba(0, 0, 0, 0.8);

    &.sticky {
      padding: 16px 0;
    }

    .header-right {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
    }

    &:after {
      content: "";
      width: 678px;
      height: 681px;
      background: url("../../../../images/landing/content/red-shadow.png") 0 0 no-repeat;
      position: absolute;
      bottom: -136px;
      right: -340px;
      background-size: cover;
    }
  }

  .hero-video-wrapper {
    display: inline-block;
    position: relative;

    &:before {
      content: "";
      width: 678px;
      height: 681px;
      background: url("../../../../images/landing/content/blue-shadow.png") 0 0 no-repeat;
      position: absolute;
      top: -236px;
      left: -340px;
      background-size: cover;
    }

    &:after {
      content: "";
      width: 678px;
      height: 681px;
      background: url("../../../../images/landing/content/red-shadow.png") 0 0 no-repeat;
      position: absolute;
      bottom: -136px;
      right: -340px;
      background-size: cover;
    }
  }

  .landing-hero-section {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    //height: 100vh;
    padding: 120px 0 120px;
    position: relative;
    background: $black;

    @media screen and (max-width: 767px) {
      padding: 80px 0 100px;
    }

    .title-primary {
      max-width: 1000px;
      margin: 0 auto;
    }

    .text-highliten {
      color: $orange!important;
      font-size: 32px;
      padding-top: 16px;
    }

    .title-secondary {
      max-width: 700px;
      padding: 16px 0;
      margin: 0 auto;
      text-transform: none;
      font-weight: normal;
      letter-spacing: initial;
      font-size: 16px!important;

      span {
        display: inline-block;
        background: $black;
        padding: 0;
        position: relative;
        z-index: 2;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 24px;
      padding-left: 0;
      li {
        display: flex;
        font-size: 16px;
        padding-left: 0;
        white-space: pre-wrap;
        gap: 16px;
        color: #F0C2B3;

        &:before {
          content: normal;
        }
      }
    }
  }

  .shadow-lg {
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 750px;
      height: 750px;
      position: absolute;
      top: -100px;
      left: -200px;
      background: url("../../../../images/landing/content/top-right-shadow.png") 0 0 no-repeat;
      z-index: 0;
      background-size: contain;
    }

    img {
      position: relative;
      z-index: 2;
    }
  }

   .shadow-sm {
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 250px;
      height: 330px;
      position: absolute;
      top: -120px;
      left: -80px;
      background: url("../../../../images/landing/content/top-right-shadow.png") center no-repeat;
      z-index: 0;
      background-size: contain;
    }

    .text-left {
      position: relative;
      z-index: 2;
    }
  }
}

.attack-techniques-video {
  position: relative;
  z-index: 2;

  @media screen and (max-width: 767px) {
    width: 130%;
    margin-left: -60px;
  }
}

.what-matter-block {
  padding: 24px 32px;
  background: $white-color;
  box-shadow: 16px 29px 48px rgba(245, 138, 138, 0.12);
  backdrop-filter: blur(50px);
  position: relative;
  z-index: 2;

  &__wrapper {
    position: relative;

    &:after {
      content: "";
      width: 555px;
      height: 555px;
      background: url("../../../../images/landing/content/red-shadow.png") 0 bottom no-repeat;
      position: absolute;
      position: absolute;
      bottom: -340px;
      right: -270px;
      background-size: cover;
      z-index: 0;
    }
  }

  &__title {
    font-size: 16px;
    text-transform: uppercase;
  }

  &__content {
    font-size: 18px;
    color: $theme-text-color;
    margin-bottom: 0;
  }
}

.mitre-info-block {
  position: relative;
  z-index: 2;
}

.mittre-video-wrapper {
  margin-bottom: -220px;

  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
}

.mittre-video {
  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin-top: 60px;
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    margin-left: -100px;
  }
}

.slider-description {
  visibility: hidden;
  width: 80%;
  padding: 32px 48px;
  margin-left: 28px;
  border-radius: 8px 8px 0 0;
  background: rgba(255, 255, 255, 0.05);

  @media screen and (max-width: 1400px) {
    visibility: visible;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 16px;
  }

  &__text {
    display: block;
    padding-bottom: 8px;
    color: $white-color;
    font-size: 18px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  .learn-more {
    display: block;
    font-weight: bold;
    color: #f0c2b3;
    font-size: 18px;

    &:hover {
      color: #f68014;
      text-decoration: none;
      background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.tab-content {
  position: relative;
  z-index: 2;
}

.slider-tabs {
  justify-content: space-between;
  margin-bottom: 48px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 380px;
    height: 300px;
    left: -90px;
    top: -80px;
    background: url("../../../../images/landing/content/red-shadow.png") 0 0 no-repeat;
    background-size: cover;
    opacity: 0.5;
    z-index: 0;
  }

  .nav-link {
    padding: 12px 0;
    margin: 0 10px;
    color: $white-color;
    font-size: 15px;
    position: relative;
    border: none;

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
        linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    &.active {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
        linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      &:not(.last-childs) {
        &:after {
          content: "Dashboard";
          margin-left: 4px;
        }
      }

      &:before {
        content: "";
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
      }
    }
  }
}

.slick-slider {
  filter: drop-shadow(0px 29px 68px rgba(0, 0, 0, 0.52)) drop-shadow(0px 18.7963px 39.8241px rgba(0, 0, 0, 0.394815))
    drop-shadow(0px 11.1704px 21.6593px rgba(0, 0, 0, 0.315852)) drop-shadow(0px 5.8px 11.05px rgba(0, 0, 0, 0.26))
    drop-shadow(0px 2.36296px 5.54074px rgba(0, 0, 0, 0.204148))
    drop-shadow(0px 0.537037px 2.67593px rgba(0, 0, 0, 0.125185));
}

.slide-wrapper {
  padding: 24px;

  @media screen and (max-width: 1400px) {
    opacity: 1;
    max-width: 1170px;
    padding: 0;
  }
}

.slick-prev {
  position: absolute;
  left: 275px;
  top: 50%;
  z-index: 200;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  &:before {
    content: normal;
  }
}

.slick-next {
  position: absolute;
  right: 260px;
  top: 50%;
  z-index: 200;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  &:before {
    content: normal;
  }
}

.slick-slide {
  opacity: 0.3;
  padding: 0 30px;
  transition: 0.5s;

  > div {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 1400px) {
    opacity: 1;
  }

  &.slick-center {
    opacity: 1;

    .slider-description {
      visibility: visible;
    }

    > div {
      display: flex;
      justify-content: center;
    }
  }
}

.slider-box {
  position: relative;
  margin-bottom: 60px;

  &:after {
    content: "";
    /* Rectangle 237 */
    display: block;
    position: absolute;
    width: 420px;
    height: 417px;
    bottom: -150px;
    right: -200px;

    /* Blue */
    background: linear-gradient(315.33deg, #3b5aff 17.82%, #8fa1ff 101.18%);
    opacity: 0.1;
    filter: blur(100px);
  }

  .slick-slider {
    z-index: 2;
  }
}

.api-integration-section-wrapper {
  padding: 1px;
  background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
  border-radius: 8px;
  position: relative;
  z-index: 2;
  margin-bottom: -100px;
  box-shadow: 0px 51px 75px rgba(0, 0, 0, 0.0481481), 0px 33.0556px 43.9236px rgba(0, 0, 0, 0.0785185),
    0px 19.6444px 23.8889px rgba(0, 0, 0, 0.1), 0px 10.2px 12.1875px rgba(0, 0, 0, 0.121481),
    0px 4.15556px 6.11111px rgba(0, 0, 0, 0.151852), 0px 0.944444px 2.95139px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.16, 1, 0.3, 1), transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  transform: scale(0.96) translateY(24px);
}

.api-integration-section-wrapper-animated {
  padding: 1px;
  background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
  border-radius: 8px;
  position: relative;
  z-index: 2;
  margin-bottom: -100px;
  box-shadow: 0px 51px 75px rgba(0, 0, 0, 0.0481481), 0px 33.0556px 43.9236px rgba(0, 0, 0, 0.0785185),
    0px 19.6444px 23.8889px rgba(0, 0, 0, 0.1), 0px 10.2px 12.1875px rgba(0, 0, 0, 0.121481),
    0px 4.15556px 6.11111px rgba(0, 0, 0, 0.151852), 0px 0.944444px 2.95139px rgba(0, 0, 0, 0.2);
  opacity: 1;
  transform: scale(1) translateY(0);
}

.api-integration-section {
  background: #0e0e10 url("../../../../images/landing/content/banner.png") center right no-repeat;
  background-size: cover;
  border-radius: 8px;
  padding-left: 60px;

  @media screen and (max-width: 991px) {
    background: #000;
    padding-left: 12px;
  }
}

.api-integration-section {
  h2 {
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;

    @media screen and (max-width: 767px) {
      justify-content: center;
    }
  }

  &__list-item {
    list-style: none;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }

    &:before {
      content: "|";
      color: rgba(255, 255, 255, 0.2);
      display: inline-block;
      padding-left: 4px;

      @media screen and (max-width: 767px) {
        content: normal;
      }
    }

    &:first-child {
      &:before {
        content: normal;
      }
    }
  }

  img {
    mix-blend-mode: color-dodge;
  }
}

.features-section {
  padding: 80px 0 80px;

  @media screen and (max-width: 767px) {
    padding: 60px 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 100px 0 60px;
  }

  &__card {
    padding: 36px;
    height: 100%;
    box-shadow: 0px 43px 29px rgba(0, 0, 0, 0.019376), 0px 25.3184px 15.1264px rgba(0, 0, 0, 0.030688),
      0px 13.0032px 7.0992px rgba(0, 0, 0, 0.039312), 0px 5.2288px 3.1088px rgba(0, 0, 0, 0.050624),
      0px 1.1696px 1.3456px rgba(0, 0, 0, 0.07);
    background: #fff;
    border-radius: 4px;
    position: relative;
    z-index: 2;

    @media screen and (min-width: 320px) and (max-width: 1024px) {
      padding: 16px;
      max-width: 300px;
      margin: 0 auto;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    height: 108px;
  }

  &__title {
    color: #17181c;
  }

  &__content {
    color: #4f5460;
    font-size: 18px;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 16px;
    }
  }
}

.leader-card {
  box-shadow: 0px 43px 29px rgba(0, 0, 0, 0.019376), 0px 25.3184px 15.1264px rgba(0, 0, 0, 0.030688),
    0px 13.0032px 7.0992px rgba(0, 0, 0, 0.039312), 0px 5.2288px 3.1088px rgba(0, 0, 0, 0.050624),
    0px 1.1696px 1.3456px rgba(0, 0, 0, 0.07);

  border-radius: 4px;

  @media screen and (max-width: 767px) {
    margin-bottom: 16px;
  }

  &__body {
    padding: 36px;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 16px;
    }

    @media screen and (max-width: 767px) {
      padding: 20px;
    }

    p {
      margin-top: 30px;
      margin-bottom: 0;
      font-size: 20px;
      color: $black;
      line-height: 1.25;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 16px;
      }

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  &__footer {
    padding: 8px 16px;
    background: rgba(240, 194, 179, 0.2);
    border-radius: 0 0 4px 4px;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 8px;
    }

    strong {
      display: block;
      font-size: 16px;
      color: #29211f;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 12px;
      }
    }

    span {
      font-size: 16px;
      color: #7a5b52;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }
}

.landing-page-form {
  h5 {
    max-width: 220px;
    margin: 0 auto 30px;
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    position: relative;

    &:before {
      content: "";
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.2);
    }

    span {
      display: inline-block;
      background: #fff;
      position: relative;
      z-index: 2;
    }
  }

  .form-group-inline {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 4px;
    border-radius: 50px;
    border: 2px solid transparent;

    &:hover {
      border: 2px solid rgba(255, 26, 26, 0.2);
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      max-width: 600px;
      margin: 0 auto 24px;
    }

    .form-control {
      box-shadow: none;
    }

    button {
      height: 60px;
      margin-left: 4px;
    }
  }

  form {
    position: relative;
    z-index: 2;
  }

  .form-control {
    height: 60px;
    border: 1px solid transparent;
    background: #ffffff;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.0235556), 0px 6px 6.05185px rgba(0, 0, 0, 0.0364444),
      0px 2.5px 1.54815px rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    font-size: 16px;

    &:hover {
      box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.0235556), 0px 6px 6.05185px rgba(0, 0, 0, 0.0364444),
        0px 12px 12px rgba(0, 0, 0, 0.0235556), 0px 6px 6.05185px rgba(0, 0, 0, 0.0364444),
        0px 2.5px 1.54815px rgba(0, 0, 0, 0.06);
    }

    &:focus {
      box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.0235556), 0px 6px 6.05185px rgba(0, 0, 0, 0.0364444),
        0px 12px 12px rgba(0, 0, 0, 0.0235556), 0px 6px 6.05185px rgba(0, 0, 0, 0.0364444),
        0px 2.5px 1.54815px rgba(0, 0, 0, 0.06);
    }

    &--rounded {
      border-radius: 50px !important;
    }
  }

  .form-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.8);
  }

  textarea.form-control {
    height: auto;
  }

  .form-group {
    margin: 0 auto 24px;
  }
}

.form-wrapper {
  position: relative;

  &:before {
    content: "";

    position: absolute;
    width: 149px;
    height: 148px;
    top: 0px;
    left: -30px;

    /* Red */
    background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
    opacity: 0.2;
    filter: blur(35px);
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 0;
  }
}

.container-sm {
  max-width: 800px;
  margin: 0 auto;
}

.footer-socials {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  gap: 16px;

  &--share {
    display: inline-flex;
    margin-left: auto;
    padding-bottom: 10px;
    visibility: hidden;
  }

  &--name {
    padding-top: 10px;
    display: block;
    color: rgba(255, 255, 255, 0.8);
  }

  a {
    padding: 10px 10px 16px 24px;
    display: flex;
    flex-flow: column;
    width: 160px;
    height: 128px;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;

    &:hover {
      text-decoration: none;
    }

    @media screen and (max-width: 767px) {
      width: 100px;
      height: 100px;
      padding: 16px;
      justify-content: flex-end;
    }

    &:hover {
      .footer-socials--share {
        visibility: visible;
      }
    }
  }
}

.footer-section {
  padding: 60px 0 0;
  background: #000 url("../../../../images/landing/content/footer-bg.svg") right bottom no-repeat;
  background-size: cover;
  color: $white-color;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -50px;
    left: -50px;
    width: 111.55px;
    height: 110.8px;
    background: linear-gradient(315.33deg, #3b5aff 17.82%, #8fa1ff 101.18%);
    opacity: 0.5;
    filter: blur(35px);
    transform: matrix(0.86, 0.51, 0.51, -0.86, 0, 0);
  }

  @media screen and (max-width: 767px) {
    padding: 40px 0 0;
  }

  ul {
    li {
      &:before {
        content: normal;
      }
    }
  }

  .border-left {
    border-left: 1px solid rgba(255, 255, 255, 0.15) !important;
  }

  &__bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
  }

  &__bottom-links {
    padding: 0;
    display: flex;
    list-style: none;
    @media screen and (max-width: 570px) {
      justify-content: center;
    }

    li,
    a {
      color: $white-color;
      font-size: 14px;
      transform: 0.3s;
    }

    li {
      padding: 0 12px;
      text-align: center;

      @media screen and (max-width: 767px) {
        padding: 8px 12px;
      }
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.video-modal {
  width: 90%;
  max-width: 90%;

  .modal-content {
    background: #000;
  }

  .modal-header {
    height: 70px;
    justify-content: end;
  }

  .modal-header .close span:first-child {
    opacity: 1;
  }

  .modal-header .close span:first-child:before,
  .modal .modal-header .close span:first-child:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #fff;
  }

  .modal-header .close span:first-child:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #fff;
  }
}

.iframe-container {
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.get-demo {
  background: #0e0e10 url("../../../../images/landing/content/dark-shadow-bgr.svg") center right no-repeat !important;
  background-size: contain;
}

.border-top-md {
  @media screen and (max-width: 1024px) {
    padding: 32px 0;
    margin-top: 32px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
  }
}

.blog-banner {
  max-width: 930px;
  margin: 0 auto;
  margin-bottom: 30px;

  .api-integration-section {
    background: #000;
    padding-left: 0;
  }

  h2 {
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    color: #f0c2b3;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }

    strong {
      font-size: 24px;
    }
  }

  &--info {
    color: $white-color;
  }
}
