@mixin inputPlaceholder() {
    $selector: "";
    $prefixes: (
        moz: "::-moz",
        webkit: "::-webkit",
        ie: ":-ms",
    );

@each $prop,
$value in $prefixes {
    @if $prop !="moz" {
        $selector: #{$value}-input-placeholder;
    }

    @else {
        $selector: #{$value}-placeholder;
    }

    @if & {
        &#{$selector} {
            @content;
        }
    }

    @else {
        #{$selector} {
            @content;
        }
    }
}

&::placeholder {
    @content;
}
}

$roboto: "Roboto", sans-serif;
$montserrat: "Montserrat", sans-serif;

$white-color: #fff;
$white-color-70: rgba(255, 255, 255, 0.7);
$white-color-30: rgba(255, 255, 255, 0.3);
$black: #000;
$bg-light: #f8f9fb;

$theme-color: #ff1a1a;
$border-light-grey: #a0adba;
$border-color: #e1e1e1;

$theme-text-color-medium: #6e7482;

$grey-color-light-bg: #f4f5f8;
$grey-color-light: #eef0f5;
$grey-color-md: #a0adba;
$grey-color-secondary: #6e7482;
$dark-grey: #4f5460;

$purple-color: #7642e5;
$green-color: #03f869;
$red-color: #ea5454;
$light-blue: #4ec1d0;
$dark-blue: #3799f3;
$yellow: #ff9900;
$orange: #E9592B;
$green: #92c551;
$pink-color: #e617d1;
$blue-color: #304050;

$stroke-color: #ced7e1;

//DARK THEME COLORS
$dm-dark-grey: #1c1e24;
$dm-grey: #25272d;
$dm-primary-grey: #202227;
$dm-secondary-grey: #292c32;
$theme-grey-color: rgba(59, 59, 65, 50%);
$dark-theme-seecondary-color: #C4C4C6;
$dark-theme-seecondary-bg: #3B3B41;
$dark-theme-primary-bg: #26262C;

$dm-bg-grey: #222228;
$dm-bg-light-grey: #3c3f49;

$dm-text-grey: #666c7b;
$dm-text-light-grey: #b7bac3;
$dm-light-color: #eef0f5;

//Threat dashboard
$old-color: #eca3a3;
$recent-color: #f17878;
$latest-color: #eb3636;

//NEW COLORS
$basic-text-color: #17181c;
$theme-text-color: #4f5460;