.middle-container {
  padding-bottom: 36px;

  // &.chat-container {
  //   height: calc(100vh - 64px - 60px);
  //   overflow: auto;
  // }
}

.middle-container-lg {
  padding-top: 64px;

  @media screen and (max-width: 767px) {
    padding-top: 75px;
  }
}

.middle-container--narrow {
  max-width: 800px;
  padding: 64px 0 0;
  margin: 0 auto;
}

// .time-slider-wrapper {
//   //max-width: 300px;
// }

.overflow-hidden-100 {
  height: 100vh !important;
}

.sidebar-right {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 240px;
  height: 100vh;
}

.breach-count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 33px;
  font-size: 14px;
  background: url("../../../../images/shield-solid.svg") 0 0 no-repeat;
  background-size: cover;
  color: $white-color;
}

.collapsible-sidebar {
  width: 240px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 65px;
  transition: 0.3s;
  z-index: 200;
  background: #f4f5f8;
  border-right: 1px solid #e4e4e4;

  @media screen and (max-width: 991px) {
    transform: translateX(-240px);
    left: 0;
    border-right: none !important;
    z-index: 101;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    transform: translateX(-1000px);
  }

  &.hidden {
    transform: translateX(-240px);
    border-right: none !important;

    @media screen and (max-width: 991px) {
      transform: translateX(0px);
      border-right: 1px solid #e4e4e4;
    }

    #personal-labels {
      visibility: hidden;
    }

    .dashboard-card-left {
      border: none;
    }
  }

  &--item-section {
    width: 420px;

    &.hidden {
      transform: translateX(-420px);
    }
  }

  .sidebar-toggle {
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 80px;
    right: -21px;
    background: $grey-color-light;
    border: 1px solid $grey-color-md;
    border-left: none;
    color: $grey-color-md;
    cursor: pointer;
    z-index: 2;
    transition: 0.3s;

    @media screen and (max-width: 767px) {
      top: 6px;
      right: 8px;
      background: transparent;
      border: none;
    }

    .material-icons {
      font-size: 20px;

      @media screen and (max-width: 767px) {
        font-size: 32px;
      }
    }

    &.shrink {
      .material-icons {
        transform: rotate(180deg);
      }
    }
  }
}

.collapsible-content {
  width: calc(100% - 240px);
  min-height: 100vh;
  transition: 0.3s;
  margin-left: 240px;
  position: relative;
  background: $grey-color-light-bg;

  &--item-section {
    width: calc(100% - 420px);
    margin-left: 420px;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }

  .board-middle-pane {
    //Making the width of the boards page header 100% (for now) becuase we will hide the right side bar, will revert this when we enable the teams boards after beta
    // width: calc(100% - 240px);
    width: 100%;
    overflow: hidden;
  }

  &.hidden {
    width: 100%;
    margin-left: 0;

    .board-middle-pane {
      //Making the width of the boards page header 100% (for now) becuase we will hide the right side bar, will revert this when we enable the teams boards after beta
      // width: calc(100% - 240px);
      width: 100%;
    }
  }

  &.news {
    width: 100%;
    margin-left: 0;
    transform: translateX(240px);

    &.hidden {
      transform: translateX(0px);
    }
  }
}

.dashboard-menu-bottom-toggle {
  display: flex;
  flex-flow: column;
  gap: 8px;
  margin-top: 24px;

  .dropdown-menu {
    left: 100% !important;
    bottom: 0 !important;
    top: auto !important;
    z-index: 1000;
    transform: none !important;
  }

  .dropdown-toggle {
    border: none;


    &:hover {
      text-decoration: none;
    }

    &:after {
      content: normal;
    }

    .material-icons {
      display: block;
      color: #a0adba;
    }
  }

  .user-avatar {
    display: inline-block;
    border-radius: 50%;
    padding: 3px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.dark-mode-toggle {
  color: $grey-color-secondary;

  p {
    font-size: 10px;
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: 1px solid $grey-color-secondary;
    border-radius: 50%;
  }

  .mob-menu-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }
}

.mobile-nav {
  list-style: none;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-flow: column;
  text-align: left;
  padding: 16px 0 8px;

  li {
    display: block;
    padding-bottom: 8px;
    list-style: none;

    &:before {
      content: normal;
    }
  }
}

.dashboard-sidebar {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 65px;
  padding: 10px 0;
  background: $white-color;
  position: fixed;
  left: auto;
  top: 0;
  bottom: 0;
  z-index: 4;
  border-right: 1px solid #e4e4e4;


  &-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0;
    margin-bottom: 8px;
    color: $theme-text-color-medium;
    position: relative;

    &.active {
      color: $white-color;
      background: linear-gradient(79.65deg, rgb(71 47 36) -1.34%, rgb(74 33 36) 65.87%);

      &:before {
        content: "";
        background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
        width: 2px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
      }
    }

    &:hover {
      color: $white-color;
      background: linear-gradient(79.65deg, rgba(246, 128, 20, 0.2) -1.34%, rgba(255, 26, 26, 0.2) 65.87%);
    }

    p {
      padding: 10px 12px;
      display: none;
      font-size: 12px;
      margin-bottom: 0;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      background: $dark-grey;
      color: $white-color;
      white-space: nowrap;

      &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 6px 5px 0;
        border-color: transparent $dark-grey transparent transparent;
        position: absolute;
        left: -6px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &:hover {
      color: darken($theme-color, 10%);

      svg {
        fill: $theme-color;
      }

      p {
        display: inline-block;
      }
    }
  }

  .navbar-brand {
    margin: 0;
    padding: 0 14px;
  }
}

.height-overflow {
  max-height: calc(100vh - 57px);
  min-height: calc(100vh - 57px);
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.dashboard-content {
  min-height: 100vh;
  padding-left: 65px;

}

.time-periods {
  padding: 20px 0;
  background: #f4f4f4;

  .custom-control {
    font-size: 12px;
    padding-left: 24px;
  }
}

.dashboard-card {
  border: none;
  border-radius: 6px;
  box-shadow: none;

  &--full-height {
    height: 100%;
    min-height: 150px;
  }

  &-left {
    height: 100%;
    border-radius: 0;
    border: none;

    .card-header {
      background: #fff;
      border: none;

      .btn-link {
        color: $theme-text-color;

        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  .card-header {
    border: none;
    font-size: 13px;
    background: #fff;

    .card-title {
      margin: 0;
    }
  }

  &--table {
    .card-header {
      background: $grey-color-light;
    }
  }
}

.horizontal-card {
  padding: 12px;
  margin-bottom: 12px;
  border: 2px solid transparent;

  &:hover {
    border-color: $theme-color;
  }

  &.selected {
    border-color: $theme-color;

    h3 {
      color: $theme-color;
    }
  }
}

.vertical-cards-row {
  .card {
    margin: 0 8px;
  }
}

.stage-card {
  border-right: 1px solid #e4e4e4;
  background: #f8f8f8;
  width: 16.66%;

  &-title {
    font-weight: 500;
    font-size: 13px;
    color: #303030;
    padding: 4px;
    border-bottom: 1px solid #e4e4e4;
    border-top: 1px solid #e4e4e4;
    text-align: center;

    span {
      display: block;
      line-height: 1.3;
    }
  }

  &-content {
    padding: 4px;
    min-height: 40px;
  }
}

.custom-tooltip {
  .tooltip-inner {
    max-width: 400px;
    padding: 12px 16px;
    color: #fff;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 0.25rem;
    opacity: 1 !important;

    hr {
      opacity: 0.2;
    }
  }

  .preview-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 12px;
    border-radius: 50%;
  }
}

.table-tooltip {
  .tooltip-inner {
    max-width: 900px;
    padding: 12px 16px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 0.25rem;
    opacity: 1 !important;

    hr {
      opacity: 0.2;
    }
  }
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $grey-color-light !important;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: $grey-color-light !important;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: $grey-color-light !important;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: $grey-color-light !important;
}

.tooltip {
  color: $white-color !important;

  .tooltip-inner {
    color: #000 !important;
    opacity: 1;
  }
}

.stage-card-content-inner {
  padding: 10px 16px;
  font-size: 12px;
  white-space: pre-wrap;
  word-break: keep-all;
  text-align: center;
  position: relative;

  &.mitre {
    border-top: 3px solid $red-color !important;
  }

  &.trukno {
    border-top: 3px solid #185cee !important;
  }

  img {
    margin-bottom: 8px;
    border-radius: 4px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.25;
    text-align: left;
  }

  &--dashbord {
    padding-top: 0;

    .stage-card-content {
      padding-bottom: 14px;
    }
  }
}

.stage-card-content__header {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //background: $dm-primary-grey;
  padding: 0px 0px 10px;
  font-size: 8px;
  border-radius: 4px 4px 0 0;
}

.stage-card-content__header-threats {
  background: url("../../../../images/shield-solid-transparent-dark.svg") 0 0 no-repeat;
  width: 20px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  font-weight: bold;
  background-size: cover;
  letter-spacing: -0.5px;
}

.mittre-table-popup__table-body-td .stage-card-content__header-threats {
  background: url("../../../../images/shield-solid-transparent-dark.svg") center no-repeat;
  width: 17px;
  height: 20px;
  font-size: 7px;
  letter-spacing: -0.5px;
  background-size: contain;
}

.stage-card-content__header-date {
  opacity: 0.8;
  font-size: 11px;
}

.stage-content-list {
  flex-wrap: wrap;
  overflow: auto;
}

.dashboard-card-sm-vertical {
  padding: 16px;
  border-radius: 3px;
  border: 2px solid transparent;

  .dropdown {
    position: relative;
    right: -10px;
    top: -5px;
  }

  &.box-shadow {
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  }

  &.selected {
    border-color: $theme-color;

    h4 {
      color: $theme-color;
    }
  }

  &:hover {
    border-color: $theme-color;

    a {
      color: $theme-color;
    }
  }

  h4 {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize !important;
  }

  a {
    text-decoration: none !important;
  }

  img {
    object-fit: cover;
    width: 100%;
    max-height: 160px;
    margin-bottom: 8px;
  }
}

.more-options-collapse {
  position: relative;
  padding: 16px;
  background: $grey-color-light-bg;

  .close-dropdown {
    position: absolute;
    top: -6px;
    right: 12px;
  }
}

.left-menu-dropdown {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  color: $theme-text-color-medium;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  padding-right: 14px !important;
  text-align: left;
  white-space: nowrap;
  text-transform: uppercase;

  .material-icons {
    position: absolute;
    right: -10px;
    top: 2px;
    font-size: 24px;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}

.breach-horizontal-card-image {
  width: 64px;
  height: 64px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 3px;
  position: relative;

  @media screen and (max-width: 991px) {
    width: 48px;
    height: 48px;
  }

  .related-Count {
    top: 5px;
    padding: 0;
  }
}

.breach-horizontal-card-content {
  width: calc(100% - 64px);
  padding-right: 16px;

  @media screen and (max-width: 991px) {
    width: calc(100% - 48px);
  }

  h4 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.notification-panel {
  position: relative;
  border-radius: 10px;
  background-color: white;
  width: 300px;
  height: 100px;
  top: 100px;
  left: 100px;
  //applying shadow
  -webkit-box-shadow: 7px 6px 17px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 6px 17px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 6px 17px -7px rgba(0, 0, 0, 0.75);
}

.table-item-title {
  font-weight: 500;
  color: $black;
  flex: 1;
}

.dashboard-table-info {
  margin-bottom: 8px;
  border: 1px solid $stroke-color;

  &__head {
    background: rgba(216, 222, 231, 0.5);

    .dashboard-table-info__row {
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid $stroke-color;
    }
  }

  &__body {
    padding-top: 8px;
    padding-bottom: 8px;
    max-height: 160px;
    min-height: 160px;
    overflow: auto;

    p {
      opacity: 0.5;
      font-size: 16px;
    }

    @media screen and (max-width: 767px) {
      max-height: 100%;
    }
  }

  &__row {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-flow: wrap;
    padding: 2px 0;
  }

  &__cell {
    padding: 4px 16px;

    .copy {
      display: none;
      padding-left: 8px;
    }

    a {
      &:hover {
        color: $theme-color;

        .copy {
          display: inline-block;
        }
      }
    }

    &--info {
      width: 70%;
      text-overflow: ellipsis;
      white-space: normal;
      overflow: hidden;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &--tools {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 30%;
      text-align: center;

      @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 8px;
      }

      a {
        color: $theme-text-color-medium;
      }
    }
  }
}

.filter-tabs {
  padding-bottom: 16px;

  &__item {
    display: inline-block;
    margin-right: 24px;
    font-size: 18px;
    position: relative;
    color: $theme-text-color;

    &:hover {
      text-decoration: none;
      color: $theme-color;
    }

    &:after {
      content: "";
      height: 2px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -3px;
    }

    &--active {
      color: $black;

      &:after {
        background: $theme-color;
      }
    }
  }
}

.view-nav {
  .nav-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 0;
    margin: 4px;
    line-height: 1;
    background: $grey-color-light;
    border-radius: 4px;
    color: $border-light-grey;

    &.active {
      color: $theme-color;
    }
  }
}

.tiles-view--dashboard {
  margin: 0 -16px;
}

.technology-list {
  display: inline-flex;
  margin-bottom: 8px;
  background: $grey-color-light-bg;
  border-radius: 4px;

  &__item {
    padding: 4px 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    font-size: 10px;
    border-left: 1px solid $grey-color-md;
    color: $dark-grey;

    &:first-child {
      border-left: none;
    }

    span {
      display: inline-block;
      line-height: 1;
      margin-top: 4px;
      font-weight: 600;
    }
  }
}

.ndv-analysis-toggle {
  height: 40px;
  width: 540px;
  border-radius: 5px;
  padding: 5px 5px 5px 10px;
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  .toggles-container {
    margin-left: 16px;
    border-radius: 3px;
    display: flex;
    align-items: center;

    .nvd-toggle {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      border-radius: 3px;

      &.active {
        background-color: $grey-color-light;
        color: $theme-color;
      }

      &:hover {
        color: $theme-color;
      }

      // &:active {
      //   position: relative;
      //   top: 2px;
      // }
    }
  }
}

.threat-actors-count {
  height: 16px;
  border: 1px solid #6e7482;
  border-radius: 4px;
  padding: 0 2px;
  line-height: 1;
}