.views {
  display: inline-flex;
  font-size: 12px;
  color: #828282;
  i {
    font-size: 16px;
    margin-right: 4px;
    position: relative;
    top: 1px;
  }
}

.company-header {
    background-color: #f8f8f8;
    border-top: 2px solid #5D61E7;
    padding: 10px 16px;
    .ui.star.rating .icon {
      font-size: 14px;
      width: 16px;
    }
}
.company-firstRow {
    display: flex;
    align-items: baseline;
}
.company-category-type {
    color: #5D61E7;
    font-size: 12px;
    margin-right: 30px;
}

.company-button {
    background-color:#5D61E7;
  	border-radius:4px;
  	border:1px solid #dcdcdc;
  	display:inline-block;
  	cursor:pointer;
  	color:#fff;
  	font-size:12px;
  	padding:6px 10px;
    text-decoration:none;
    margin-left: auto;
}
.company-button:active {
	position:relative;
	top:1px;
}
.company-button.active {
	background-color:#5d4df5 !important;
	color: white;
}

.company-source {
    color: #414141;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
}

.comapny-urls a{
    color: #414141;
    font-size: 11px;
    text-decoration: underline;
    margin-right:10px;
}
