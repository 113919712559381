// Colors
$Actors : #9F55A1;
$Actors-alfa : rgba(159,85,161,0.7);
$Stories : #AD6A05;
$Stories-alfa : rgba(173,106,5,0.7);
$Threats : #FF4040;
$Threats-alfa : rgba(255,64,64,0.7);
$Solutions : #1DB534;
$Solutions-alfa : rgba(29,181,52,0.7);
$Companies : #5D61E7;
$Companies-alfa : rgba(93,97,231,0.7);
$Experts : #15AFD1;
$Experts-alfa : rgba(21,175,209,0.7);
$Breaches : #E617D1;
$Breaches-alfa : rgba(230,23,209,0.7);


.category-area {
  p {
    margin: 0;
    font-size: 10px;
    color: #7F808A;
  }
}

.table-head {
  background: #eceef2;
  text-transform: uppercase;
  font-weight: 600;

  strong {
    color: #3B5998;
  }
}

.table-card {
    background-color: #fff;
    border: 1px solid lightgray;
    padding: 5px;
}

.table-image {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0,0,0,.15);

    &--sm {
      width: 40px;
      height: 30px;
    }
}

.table-content {
  width: calc(100% - 60px);
  padding-left: 8px;
}

.card-title {
    font-size: 16px;
    line-height: 1.25;
    margin-left: 8px;
    color: #000;
    font-weight: 500;
    @media screen and (max-width: 1200px) {
      font-size: 14px;
    }
    &:hover {
      text-decoration: none;
      color: #3B5998;
    }
}

.numbers-area  {
  p {
    margin-bottom: 0;
  }
}

.table-row {
  padding: 20px 0 10px;
  background: #fff;
  border-top: 1px solid #d8dce5;
  position: relative;

  .collapse-btn {
    position: absolute;
    padding: 0;
    bottom: 0;
    right: 8px;
    color: #3B5998;
    text-shadow: 0 0 3px rgba(59,89,152,.35);
  }

  +.card-footer {
    margin: 0 -15px;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;

    .filter-stars {
      padding: 0 15px;
    }
  }
}

.leftBorder-Breaches{
  border-left: 2px solid $Breaches;
}
.leftBorder-Stories{
  border-left: 2px solid $Stories;
}
.leftBorder-Threats{
  border-left: 2px solid $Threats;
}
.leftBorder-Actors{
  border-left: 2px solid $Actors;
}
.leftBorder-Solutions{
  border-left: 2px solid $Solutions;
}
.leftBorder-Companies{
  border-left: 2px solid $Companies;
}
.leftBorder-Experts{
  border-left: 2px solid $Experts;
}

.item-card{
    background-color: #fff;
    width: 100%;
    margin-bottom: 16px;
    box-shadow: 0 0 10px rgba(0,0,0,.10);
}

.dashboard-card-left {
  background: $grey-color-light-bg!important;
  .news-search-filter {
    min-width: 100%;
  }
}

.malware-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.malware-card {
  display: flex;
  flex-flow: column;
  width: 240px;
  cursor: pointer;
  padding: 12px;
  margin: 0px 12px 12px 0;
  border: 1px solid $dm-text-light-grey;
  border-radius: 6px;
  background: $white-color;

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  &__flag-icon {
    width: 32px;
    margin-right: 12px;
    img {
      width: 100%!important;
      height: auto!important;
    }
  }

  &__bottom {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: .75;
    &-date {
      font-size: 12px;
    }
  }

  &__bottom-breach-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 10px;
    background: url("../../../images/shield-solid-transparent-dark.svg") 50% no-repeat;
    background-size: contain;
  }
}
