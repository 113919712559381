.search-header {
    background: $white-color;
}

.search-row {
    display: inline-flex;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid $border-color;
    overflow-x: hidden;
    overflow-y: auto;
    background: $white-color;
}


.search-row-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    // overflow: auto;
    flex-wrap: wrap;
    padding: 0;

    li {
        max-width: 100%;
    }

    &-item {
        padding: 0;

        &:before {
            content: normal;
        }
    }

    &-item-icon {
        display: none;
        position: relative;
        top: -1px;
    }

    a {
        padding: 4px 8px;
        display: inline-flex;
        align-items: center;
        color: $dark-grey;
        font-weight: medium;
        font-size: 14px;
        background: $grey-color-light-bg;
        border-radius: 4px;
        color: inherit;

        &:hover {
            text-decoration: none;
            color: $theme-color !important;

            .search-row-list-item-icon {
                display: inline-flex;
                align-items: baseline;
                margin-left: 4px;
            }
        }
    }
}