.react-select-container {
  &.react-select-container--solid-blue {
    .react-select__control {
      background: transparent;
      border: 1px solid $border-light-grey !important;

      .react-select__single-value {
        color: $theme-text-color-medium;
      }
    }

    .react-select__dropdown-indicator svg {
      fill: $theme-text-color-medium;
    }
  }

  .react-select {
    &__control {
      height: 45px;
      border-radius: 5px;
      border: 1px solid rgba(65, 65, 65, 0.25);
      background: none;
      font-size: 16px;

      &:hover {
        border: 1px solid $theme-color;
      }

      &--is-focused {
        border: 1px solid $theme-color;
        box-shadow: none;
      }
    }

    &__menu {}

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      font-size: 14px;
      color: rgba(119, 119, 119, 0.4);
    }

    &__option {
      &--is-focused {
        background: $grey-color-light-bg;
        color: $black;
      }
    }

    &__dropdown-indicator {
      svg {
        width: 14px;
      }
    }
  }
}