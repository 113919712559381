.dashboard-table {
  .table {
    table-layout: fixed;

    thead {
      tr {
        th {
          //height: 60px;
          padding-top: 6px;
          padding-bottom: 6px;
          background: rgba(216, 222, 231, .5);
          color: $dark-grey;
          font-size: 12px;
          font-weight: 500;
          border: 1px solid $stroke-color;
          vertical-align: top;
          position: sticky;
          top: 0;

          /* Don't forget this, required for the stickiness */
          .text-overflow {
            display: inline-block;
            max-width: calc(100% - 24px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:first-child {
            max-width: 400px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          width: auto;
          background: $white-color;
          border: 1px solid $stroke-color;
          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;
          vertical-align: middle;
          color: $dark-grey;
          white-space: pre-wrap;
          word-break: keep-all;
          //position: relative;

          img {
            width: 60px;
            height: 30px;
            margin-right: 8px;
            object-fit: cover;
          }

          .counter {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            margin-top: 16px;
            background: $theme-color;
            border-radius: 50%;
            color: $white-color;
            font-weight: bold;
            transition: .3s;

            &__label {
              margin-top: 12px;
              color: $theme-color;
              display: block;
              line-height: 1;
              font-size: 10px;
              text-align: center;
              visibility: hidden;
              opacity: 0;
              transition: .3s;
            }

            &:hover {
              background: lighten($theme-color, 10%);
            }
          }
        }


        &:nth-child(even) {
          td {
            background: $grey-color-light-bg;
          }
        }

        &.show-menu {
          &:hover {
            .counter {
              animation: pulse 1.5s infinite;
            }

            .counter__label {
              visibility: visible;
              opacity: 1;
            }
          }
        }

      }
    }
  }

  .share-block {
    //width: 140px;
    // margin-top: 8px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: auto;
    border: 1px solid $grey-color-light-bg;

    &__label {
      display: none;
    }

    ul {
      flex-flow: row;
      opacity: 1;
    }
  }
}

.my-label {
  display: inline-block;
  width: 20px;
  height: 5px;
  border-radius: 5px;

  &.red {
    background: $red-color;
  }

  &.yellow {
    background: $yellow;
  }

  &.grey {
    background: $grey-color-md;
  }

  &.green {
    background: $green;
  }
}

.table-link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: $theme-color;
  }
}

.show-menu {

  &__actions {
    display: inline-block;
    visibility: hidden;
    position: relative;
    cursor: pointer;

    .share-block {
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      min-width: 180px;

      &__icon {
        display: inline-block;
        width: 20px;
      }

      &__label {
        display: inline-block;
        margin-left: 8px;
      }

      ul {
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;

        li {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 4px;
        }
      }
    }
  }

  &:hover {

    .show-menu__actions {
      visibility: visible;

      &:hover {
        .share-block {
          display: block;
        }
      }
    }

    .admin-edit {
      display: flex;
    }
  }
}

.admin-edit {
  cursor: pointer;
  font-size: 16px;
  display: none;
  position: absolute;
  top: -50px;
  right: 5px;
}

.tooltip-row {
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid $border-color;

  &:last-of-type {
    border: none;
  }
}

.breach-industry-list {
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;

  li {
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    color: $theme-text-color-medium;

    &:before {
      content: normal;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(55, 153, 243, .35);
  }

  70% {
    -webkit-box-shadow: 0 0 0 8px rgba(55, 153, 243, .35);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(55, 153, 243, .35);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(55, 153, 243, .35);
    box-shadow: 0 0 0 0 rgba(55, 153, 243, .35);
  }

  70% {
    -moz-box-shadow: 0 0 0 8px rgba(55, 153, 243, .35);
    box-shadow: 0 0 0 8px rgba(55, 153, 243, .35);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(55, 153, 243, .35);
    box-shadow: 0 0 0 0 rgba(55, 153, 243, .35);
  }
}

.pagination {
  .page-item {
    &:before {
      content: normal;
    }

    &.active {
      .page-link {
        background: $grey-color-light;
        color: $theme-text-color;
      }
    }
  }

  .page-link {
    border: none;
    border-radius: 4px;
    color: $theme-text-color;
    background: none;

    &:hover {
      color: $theme-color !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .related-Ioc {
    color: $white-color;
    background: $dm-dark-grey;
  }

  &-sm {
    .page-link {
      padding: 2px 4px;
      font-size: 12px;
      line-height: 1;
    }
  }

}

.number-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  font-size: 12px;
  font-weight: bold;

  &--critical {
    border-color: $red-color;
    color: $red-color;
  }

  &--low {}

  &--medium {
    border-color: $orange;
    color: $orange;
  }

  &--medium-solid {
    border-color: $orange;
    background: $orange;
    color: white;
  }

  &--high {
    border-color: $yellow;
    color: $yellow;
  }

  &--green {
    border-color: rgb(58, 213, 58);
    color: rgb(58, 213, 58);
  }

  &--green-solid {
    border-color: rgb(58, 213, 58);
    background: rgb(58, 213, 58);
    color: white;
  }

  &--purple {
    border-color: #AC6AFF;
    background: #AC6AFF;
    color: #fff;
  }

  &--blue {
    border-color: #53BDF8;
    background: #53BDF8;
    color: #0E0E10;
  }

  &.small {
    width: 26px;
    height: 26px;
    font-weight: 400;
    font-size: 12px;
    border-width: 1px;
  }

}

.score {
  font-size: 12px;

  &--critical {
    color: $red-color;
  }

  &--low {}

  &--medium {
    color: $orange;
  }

  &--high {
    color: $yellow;
  }
}