.modal-backdrop {
  opacity: 0.75;
}

.modal {
  .login-signup {
    @media screen and (min-width: 768px) {
      min-width: 600px;
    }
  }

  .exist-relations {
    min-width: 80%;
  }

  .variable-width {
    min-width: var (--modal-width);
  }

  .add-details {
    min-width: 40%;
  }

  .edit-source-modal {
    min-width: 40%;
  }

  .add-board-label {
    width: 250px;
  }

  .invite-people {
    min-width: 40%;
  }

  .edit-members-role {
    min-width: 30%;
  }

  .edit-matrix {
    width: 80% !important;
  }

  //modal to add/edit sources for feed lists
  .edit-sources {
    min-width: 60%;
  }

  //model to each individual source data (image, tags, ...)
  .edit-source {
    min-width: 50%;
  }

  .edit-iocs {
    min-width: 70%;
  }

  .move-to-channel {
    min-width: 20%;
  }

  .add-card-modal {
    min-width: 90%;

    .modal-content {
      overflow: scroll;
    }
  }

  .vt-api-modal {
    min-width: 40%;
  }

  .payment-modal {
    min-width: 40%;
  }

  .add-detection-modal {
    min-width: 90%;
  }

  //Redirect Modal news page
  .redirect-modal {
    @media screen and (min-width: 767px) {
      max-width: 680px;
    }
  }

  .edit-sources-body {
    @media screen and (max-width: 767px) {
      height: 75vh;
      overflow-y: auto;
    }

    &__left {
      height: 65vh;
      overflow-y: auto;
      background: $white-color;

      @media screen and (max-width: 767px) {
        height: auto;
      }
    }

    &__right {
      height: 65vh;
      overflow-y: auto;
      padding-top: 16px;

      @media screen and (max-width: 767px) {
        height: auto;
      }
    }

    .source-item-card {
      &__logo {
        height: 80px;

        img {
          max-height: 80px;
          max-width: 40%;
        }
      }

      &__footer {
        h3 {
          font-size: 12px;
        }
      }
    }
  }

  .add-experience {
    @media screen and (min-width: 768px) {
      min-width: 760px;
    }
  }

  .modal-header {
    border: none;
    position: relative;

    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   height: 3px;
    //   background-image: linear-gradient(270deg, #8AD7E8 0%, #F28BE8 13%, #AEB0F3 27%, #D6B482 40%, #7FCAFF 52%, #8EDA99 64%, #FF9F9F 76%, #9F55A1 88%, #8AD7E8 100%);
    // }
    .close {
      position: relative;
      padding: 0;
      margin: 0;

      span:first-child {
        position: absolute;
        right: 0;
        top: 0;
        width: 32px;
        height: 32px;
        opacity: 0.75;
        text-indent: -99999px;

        &:before,
        &:after {
          position: absolute;
          left: 15px;
          content: " ";
          height: 33px;
          width: 2px;
          background-color: #333;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }

      &:hover {
        opacity: 1;
        box-shadow: none;
        outline: none;

        span {
          opacity: 1;

          &:before,
          &:after {
            background-color: #3b5998;
          }
        }
      }
    }
  }

  &.login {
    .modal-header {
      padding-top: 40px;
      padding-left: 20px !important;
      position: relative;

      .close {
        position: absolute;
        top: 16px;
        right: 16px;
      }

      .modal-title {
        font-size: 28px;
        padding-left: 15px;
        font-weight: 600;
        margin-bottom: 24px;
        font-family: $montserrat;
      }
    }
  }

  .modal-md {
    max-width: 900px;
  }

  .add-details {
    max-width: 900px;

    .modal-body {
      padding: 30px;

      h4 {
        font-size: 18px;
      }
    }

    .modal-header .modal-title {
      font-size: 20px;
      padding-left: 30px;
    }
  }

  &.modal-primary {
    .modal-header {
      .modal-title {
        font-size: 24px;
        font-family: $montserrat;
        font-weight: 600;
        letter-spacing: initial;
      }
    }

    .modal-body {
      padding: 36px;
    }

    &--light {

      //MODAL
      .modal-content {
        background: $white-color;
        color: $dm-dark-grey;
      }

      .modal-header .close span:first-child:before,
      .modal-header .close span:first-child:after {
        background-color: $dm-dark-grey;
      }

      .form-control {
        background: $white-color;
        color: $dm-primary-grey;

        &:focus {
          background: $white-color;
        }
      }
    }
  }
}

.modal-dialog {
  &.add-details {
    .modal-header {
      padding-top: 0 !important;

      .close {
        top: 0;
        right: 0;
        opacity: 1;
        z-index: 2;
        position: absolute;

        span:first-child {
          position: absolute;
          right: 0;
          top: 0;
          width: 38px;
          height: 38px;
          background: darken($grey-color-light-bg, 5%);
          border-radius: 0 0 0 6px;

          &:before,
          &:after {
            position: absolute;
            left: 18px;
            top: 6px;
            content: " ";
            height: 25px;
            width: 2px;
          }
        }

        &:hover {
          span {
            background: darken($grey-color-light-bg, 10%);

            &:before,
            &:after {
              background-color: $theme-text-color;
            }
          }
        }
      }
    }

    .modal-body {
      padding: 0 15px;
    }
  }
}

.forgot-password {
  color: #0a0a0b;
  font-weight: 600;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
  }
}

.need-account {
  padding: 36px 0 24px;
  font-size: 16px;
  text-align: center;

  a,
  span {
    display: inline-block;
    margin-left: 6px;
    color: #3b5998;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}

.login {
  .nav-tabs {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-flow: row;
    margin-bottom: 24px;

    .nav-link {
      width: 50%;
      padding: 8px;
      text-align: center;
      border: none;
      font-size: 18px;
      border-bottom: 1px solid #ccc;
      color: rgba(65, 65, 65, 0.75);

      &:hover,
      &:focus {
        box-shadow: none;
        color: #3b5998;
        outline: none;
      }

      &.active {
        border: none;
        border-bottom: 3px solid #3b5998;
        color: #3b5998;
        font-weight: 600;
      }
    }
  }
}

.modal-width {
  width: 80% !important;
}

.item-description {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  padding: 16px;
  box-shadow: 0 0 6px rgba(65, 65, 65, 0.25);
  margin-bottom: 24px;
  margin-top: 10px;
  border-radius: 4px;

  h3 {
    width: 70%;
    font-weight: 600;
    padding-right: 12px;
    line-height: 1.25;
    margin-bottom: 0;
  }
}

.modal {
  .bam-modal {
    min-width: inherit;

    &.modal-dialog {
      max-width: inherit;
    }

    .modal-content {
      width: 85%;
      margin: 0 auto;
      background: transparent;
    }

    .modal-header {
      padding: 0 !important;

      .modal-title {
        display: none;
      }

      .close {
        position: absolute;
        right: 0px;
        top: -40px;
        opacity: 1;
        z-index: 2;

        @media screen and (min-width: 614px) {
          right: -40px;
          top: -40px;
        }

        span {
          opacity: 1;

          &:before,
          &:after {
            background: #fff;
          }
        }
      }
    }

    .modal-body {
      padding: 0;
    }

    .bam-image {
      display: block;

      img {
        width: 100%;
      }
    }
  }
}

.top-description {
  position: relative;
  padding-bottom: 24px;
  padding-top: 24px;
  margin-bottom: 30px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    background: #d2d2d2;
    height: 1px;
    left: -15px;
    right: -15px;
    bottom: 0;
  }
}

.popover {
  max-width: inherit;
  z-index: 100000;
}

.popover-body {
  padding: 16px;
}

.popover-header {
  background: $grey-color-light-bg;
}

.shared-block {
  margin: 32px 0;
  background: $grey-color-light-bg;
  border-radius: 4px;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 4px 4px 0;
  }
}

.modal-right-sidebar {
  background: $grey-color-light-bg;
}

.channels-list {
  list-style: none;
  max-height: 200px;
  overflow: auto;

  li {
    display: block;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;

    &:before {
      content: normal;
    }

    &:hover,
    &.selected {
      background: $grey-color-light-bg;
    }
  }
}

.modal-image {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 6px;
  background-repeat: no-repeat;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.modal-content-left {
  width: calc(100% - 200px);
  padding-left: 24px;
  text-align: left;

  @media screen and (max-width: 991px) {
    width: 100%;
    margin-top: 16px;
    padding-left: 0;
  }
}

.add-card-modal {
  .modal-body {
    max-height: 70vh;
  }
}

.add-card {
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid $theme-color;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    border-bottom: 1px solid $grey-color-light;

    &-left {
      width: 80%;
      display: flex;

      p {
        width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &-right {
      width: 20%;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__body {
    padding: 12px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 12px;
  }
}

.upgrade-modal {
  .modal-header {
    justify-content: center;
    padding-bottom: 24px;

    .close {
      right: 16px;
      position: absolute;
    }
  }

  .modal-body {
    padding: 24px;
  }
}

.add-card {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px solid transparent;
  height: 100%;
}

.right-curtain {
  .modal-dialog {
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    min-width: 300px;
    max-width: 300px;

    .modal-content {
      height: 100vh;
      overflow: auto;
    }
  }

  .modal-close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    position: absolute;
    left: -28px;
    top: 6px;
    color: $theme-color;
    background: $white-color;
    cursor: pointer;
    opacity: .9;
    transition: .3s;

    &:hover {
      opacity: 1;
    }
  }

  .overflow-initial {
    .modal-content {
      overflow: initial;
    }
  }
}

.code-block {
  width: 98%;
  background: black;
  padding: 10px;
  border-radius: 15px;
}