.share-block {
  display: none;
  position: absolute;
  top: 0;
  right: 13px;
  left: auto;
  z-index: 8;

  ul {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    padding: 2px 6px;
    background: $white-color;
    border: 1px solid $border-color;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    li {
      display: block;
      padding: 2px;
      margin: 0 2px;
      color: $grey-color-secondary;
      border-radius: 2px;
      line-height: 1;
      cursor: pointer;
      &:before {
        content: normal;
      }

      &:hover {
        color: $dark-grey;
        background: $grey-color-light;
      }

      a {
        color: rgba(38, 38, 38, 0.75);

        &:hover {
          color: rgb(59, 89, 152);
        }
      }
    }
  }

  &__label {
    display: none;
  }
}

.share-block-general {
  ul {
    li {
      display: flex;
      align-items: center;
      padding: 2px;
      margin: 2px 4px;
      color: $grey-color-secondary;
      border-radius: 2px;
      line-height: 1;
      cursor: pointer;
      &:before {
        content: normal;
      }

      &:hover {
        color: $theme-color;
        background: $grey-color-light;
      }

      a {
        color: rgba(38, 38, 38, 0.75);

        &:hover {
          color: rgb(59, 89, 152);
        }
      }

      span {
        display: inline-block;
        padding-left: 12px;
        font-size: 14px;
      }
    }
  }
}

.news-tab-content {
  overflow: auto;
  padding: 0 24px;
}

.hidden {
  .news-page-filter-panel {
    left: 65px;

    @media screen and (max-width: 991px) {
      left: 0;
    }
  }
}

.board-middle-pane {
  .news-page-filter-panel {
    position: relative;
    left: 0;
  }
}

.news-page-filter-panel {
  padding: 12px 16px;
  background: #fff;
  border-bottom: 1px solid #e4e4e4;
  position: fixed;
  top: 0;
  right: 0;
  left: calc(65px + 240px);
  z-index: 100;
  transition: 0.3s;

  @media screen and (max-width: 991px) {
    left: 0;
    padding-top: 8px;
    padding-bottom: 4px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 8px;
    padding-bottom: 4px;
  }

  &-title {
    strong {
      display: inline-block;
      margin-left: 6px;
      font-weight: 500;
    }
  }

  .btn-link {
    color: #585858;
    text-decoration: none !important;
  }

  .dropdown-toggle {
    &:after {
      content: normal;
    }
  }

  .nav-link {
    padding: 0;
    margin: 0 4px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    color: $theme-text-color-medium;
    font-size: 14px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &.active {
      background: $grey-color-light-bg;
      color: $theme-color;
      border-radius: 3px;
    }
  }
}

.news-search-filter {
  height: 39px;
  min-width: 300px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  position: relative;
  color: $theme-text-color-medium;

  .checkbox-container {
    display: flex;
    align-items: center;
    width: 140px;
    height: 39px;
    margin-bottom: 0;
    padding-left: 50px;
    background: $white-color;

    .checkmark {
      top: 9px;
      left: 20px;
    }
  }

  &--full-view {
    width: 320px;
    .form-control {
      width: 100%;
      padding-right: 40px;
    }
  }

  input {
    width: 100%;
    padding: 8px 12px 8px 36px;
    border: 1px solid rgba(0,0,0,.10);
    border-radius: 8px;

    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid rgba(0,0,0,.50);
    }
  }

  svg,
  .material-icons {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    // @media screen and (max-width: 767px) {
    //   position: relative;
    //   top: 0;
    //   right: 0;
    //   transform: translate(0);
    // }
  }
}

.checkbox-container {
  &--mobile {
    @media screen and (max-width: 991px) {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
      height: 39px;
      width: 90%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -89px;
      padding-left: 50px;
      margin: 0 auto;
      background: $white-color;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

      .checkmark {
        top: 9px;
        left: 20px;
      }
    }
  }
}

.mobile-search {
  width: 180px;
  padding: 8px 24px 8px 12px;
  border: 1px solid $grey-color-md;
  border-radius: 3px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -55px;

  @media screen and (max-width: 991px) {
    width: 90%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -50px;
    margin: 0 auto;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid $theme-color;
  }
}

.news-height-overflow {
  height: 100vh;
  padding-left: 12px;
  padding-right: 12px;
  overflow: auto;
  overflow-x: hidden;

  @media screen and (min-width: 1600px) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.news-page {
  .list-view {
    @media screen and (min-width: 1280px) {
      padding: 0 20px;
    }

    &-item {
      max-width: 1400px;
      margin: 0 auto 16px;
      position: relative;

      .dashboard-card-sm-vertical {
        padding: 20px;
        margin-bottom: 0;
        box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);

        a {
          @media screen and (min-width: 768px) {
            height: auto;
            flex-flow: row;
          }

          .dashboard-card-sm-vertical-body {
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      .share-block {
        display: inline-block;
        visibility: hidden;
        position: relative;
        top: 0;
        left: auto;
        right: auto;
        z-index: 3;

        @media screen and (max-width: 991px) {
          visibility: visible;
        }

        ul {
          box-shadow: none;
          border: none;

          li {
            a {
              color: rgba(38, 38, 38, 0.75);
            }
          }
        }
      }

      &:hover {
        .share-block {
          visibility: visible;
        }
      }
    }
  }

  .tiles-view {
    .news-tile-card {
      width: 320px;
      padding: 0 15px;

      @media screen and (max-width: 375px) {
        max-width: 320px;
        width: 100%;
      }

      .dashboard-card-sm-vertical {
        @media screen and (max-width: 1200px) {
          max-width: 360px;
          margin: 0 auto;
        }
      }
    }
  }

  .compact-list-view-item {
    padding: 0px;
    position: relative;
    max-width: 1400px;
    margin: 0 auto;

    .compact-list-view-item-preview {
      display: inline-block;
      width: 72px;
      height: 48px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .dashboard-card-sm-vertical-title {
      width: calc(100% - 72px);
      padding-left: 16px;
    }

    .dashboard-card-sm-vertical {
      padding: 8px 16px;
      margin-bottom: 0;
      border-radius: 0;
      border-bottom: 2px solid $grey-color-light;

      &:hover {
        border: 2px solid $theme-color !important;
      }

      .dashboard-card-sm-vertical-title {
        font-size: 16px;
        margin-bottom: 0;
      }

      img {
        max-height: 42px;
      }
    }

    .share-block {
      display: inline-block;
      visibility: hidden;
      position: relative;
      top: 0;
      left: auto;
      right: auto;
      z-index: 3;
      @media screen and (max-width: 991px) {
        visibility: visible;
      }
      ul {
        box-shadow: none;
        border: none;

        li {
          a {
            color: rgba(38, 38, 38, 0.75);
          }
        }
      }
    }

    &:hover {
      .share-block {
        visibility: visible;
      }
    }
  }

  .dashboard-card-sm-vertical {
    padding-bottom: 8px;
    margin-bottom: 8px;

    &__content {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-flow: column;
      @media screen and (min-width: 768px) {
        height: 360px;
      }

      img {
        height: 120px;
      }
    }

    &-title {
      font-size: 16px;
      line-height: 1.25;
      margin-bottom: 12px;
    }

    &-body {
      font-size: 14px;
      line-height: 1.35;
      margin-bottom: 8px;
      color: $grey-color-secondary;
    }

    .share-block {
      display: inline-block;
      visibility: hidden;
      position: relative;
      top: 2px;
      left: auto;
      right: auto;
      z-index: 3;
      @media screen and (max-width: 991px) {
        visibility: visible;
      }
      ul {
        box-shadow: none;
        border: none;

        li {
          a {
            color: rgba(38, 38, 38, 0.75);
          }
        }
      }
    }

    &:hover {
      .share-block {
        visibility: visible;
      }
    }
  }

  .dashboard-card-sm-vertical-footer {
    margin-top: auto;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
    color: $grey-color-secondary;
  }

  .type {
    font-size: 12px;
    display: block;
    font-style: italic;
    &.threat {
      color: rgb(255, 64, 64);
    }

    &.breach {
      color: #e617d1;
    }
  }
}

.top-website-list {
  padding-top: 8px;
  padding-left: 0;

  &__item {
    &-left {
      width: 20px;
      height: 20px;
    }
    &-right {
      padding-left: 12px;
      width: calc(100% - 20px);
    }
  }
  li {
    display: block;
    padding: 6px;
    border-radius: 5px;
    &:hover {
      text-decoration: none;
      color: $theme-color;
    }

    &.selected {
      background: $theme-color;
      color: $white-color;

      .count {
        color: $white-color;
      }

      &:hover {
        color: $white-color;
        background: darken($theme-color, 10%);
      }
    }
    a {
      display: flex;
      align-items: center;
      font-weight: 500;
      &:hover {
        text-decoration: none;
        color: $theme-color;
      }
    }

    &:before {
      content: normal;
    }

    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;

      &:last-child {
        display: inline-block;
        text-align: right;
        width: 20%;
      }
    }

    .checkmark {
      width: 20px !important;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }

  .count {
    color: $theme-text-color-medium;
  }
}

.story-type-list {
  padding-top: 8px;
  padding-left: 0;
  li {
    display: block;
    padding: 6px;
    font-weight: 500;
    border-radius: 5px;
    &:hover {
      text-decoration: none;
      color: $theme-color;
      .edit-feed-icon {
        display: block !important;
      }
    }

    &.selected {
      background: $theme-color;
      color: $white-color;

      .count {
        color: $white-color;
      }

      &:hover {
        color: $white-color;
        background: darken($theme-color, 10%);
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      &:hover {
        text-decoration: none;
      }
    }

    &:before {
      content: normal;
    }
  }

  .count {
    color: $theme-text-color-medium;
  }
}

.filter-menu-icon {
  width: 16px;
  display: inline-block;
  margin-right: 16px;
  color: rgb(141, 153, 165);
  .material-icons {
    font-size: 20px;
  }
}

.news-tile-card {
  &:hover {
    .share-block {
      display: inline-flex;
    }
    .admin-edit {
      display: flex;
      top: -40px;
    }
  }
}

.related-Count {
  min-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px 12px 4px;
  border: 1px solid $grey-color-md;
  font-size: 13px;
  line-height: 1;
  border-radius: 4px;
  font-weight: 500;
}

.related-Ioc {
  min-height: 24px;
  min-width: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px 12px 4px;
  border: 1px solid $grey-color-md;
  font-size: 13px;
  line-height: 1;
  border-radius: 4px;
  font-weight: 500;
}

.compact-image {
  .related-Count {
    right: 5px;
    top: 5px;
  }
}

.news-source-button {
  background-color: #bdbdbd !important;
  &.active {
    background-color: #585858 !important;
  }
}

.subsription-section {
  padding: 16px 16px 8px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: $white-color;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  @media screen and (min-width: 992px) {
    left: 300px;
  }

  @media screen and (max-width: 767px) {
    padding: 24px 28px;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    &:hover {
      color: $theme-color;
    }
  }

  .form-control {
    max-width: 300px;

    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  .form-label {
    font-size: 18px;
    @media screen and (max-width: 767px) {
      text-align: center;
      margin-bottom: 16px;
    }
  }

  .btn-primary {
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 8px;
    }
  }
}

.news-tabs-sorting {
  padding: 0 0 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;

  &__items {
    display: inline-block;
    text-align: center;
    padding: 8px 12px;
    margin: 0 8px;
    border-radius: 4px;
    background: $white-color;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 767px) {
      padding: 6px 8px;
      font-size: 12px;
    }

    &--active {
      background: linear-gradient(79.65deg, #f68014 -1.34%, #ff1a1a 65.87%);
      color: $white-color;
    }
  }
}

.compact-image {
  width: 80px;
}

.back-to-top {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 80px;
  right: 10px;
  background: $theme-color;
  color: $white-color;
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  &:hover {
    background: darken($theme-color, 10%);
  }
}
