.review-card {
  &-left {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    img {
      width: 36px;
    }
  }
  &-right {
    margin-bottom: 16px;
  }
}

.review-item {
  padding: 8px;
  background: rgba(248,248,248,1);
  border-radius: 0 16px 16px 16px;
}

.review-time{
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: grey;
    margin-left: 5px;
}

.review-title{
    font-weight: 600;
    color: rgb(80, 79, 79);
    margin-top: 8px;
    margin-bottom: 0;
    font-size: 16px;
}

.comment-auther{
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    line-height: 1.25;
    color: grey;
    margin-top: 6px;
}

.comment-body {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #414141;
    margin-left: 5px;
    padding-right: 20px;
}

.ui.star.rating {
  .icon {
    font-size: 18px;
    text-shadow: none!important;
    &:hover {
      color: #3B5998!important;
    }
  }
  .active.icon {
    color: #3B5998!important;
    text-shadow: none!important;
  }
}
