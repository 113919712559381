.header-count {
   display: inline-block;
   padding: 2px 8px;
   background: $grey-color-light-bg;
   border-radius: 25px;
}

.header-search-filter {
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: space-between;
   padding: 0 12px;
   border: 1px solid #ced7e1;
   border-radius: 8px;
   background: #f4f5f8;
}

.header-search {
   width: 100%;
   display: flex;
   gap: 12px;

   input {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 12px;
      background: none;
      border: none;
      &:focus {
         outline: none;
         box-shadow: none;
      }
   }
}