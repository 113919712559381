.item-page-header {
  position: fixed;
  top: 0;
  left: 65px;
  right: 0;
  z-index: 101;
  background: $white-color;
  border-bottom: 1px solid #e4e4e4;

  @media screen and (max-width: 991px) {
    left: 0;
  }

  &__middle-buttons {
    margin-left: -67px;

    .btn-outline-primary {
      min-width: 80px;
      text-transform: inherit;
    }
  }
}

.breach-item-page {
  &__left {
    width: 110px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 16px;
    }

    &--flag {
      width: 32px;

      .breach-item-page__image {
        width: 100%;
        height: auto;
      }
    }
  }

  &__right {
    padding-left: 16px;
    width: calc(100% - 110px);

    @media screen and (max-width: 767px) {
      width: 100%;
      padding-left: 0;
    }
  }

  &__article {
    max-height: 80px;
    overflow: auto;
  }

  &__image {
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 6px;

    @media screen and (max-width: 767px) {
      width: 100%;
      height: auto;
      max-height: 200px;
    }
  }

  &__action-btns {
    width: 30px;
    padding: 4px;
    margin-left: auto;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-flow: column;
    list-style: none;
    background: $grey-color-light-bg;
    font-size: 16px;
    border-radius: 4px;

    @media screen and (max-width: 991px) {
      display: inline-flex;
      flex-flow: row;
      width: auto;
      padding: 0 8px;
      margin-left: 0;
    }

    li {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 4px;

      @media screen and (max-width: 767px) {
        padding: 2px 4px;
      }

      &:before {
        content: normal;
      }

      a {
        display: inline-block;
        text-align: center;
        color: $grey-color-secondary;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}

.procedures-html {
  img {
    width: 100% !important;
    height: auto;
    margin-bottom: 16px;
  }
}

.item-page-cards-list {
  list-style: none;

  &__item {
    width: 100%;
    padding: 4px 0;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:before {
      content: normal;
    }
  }
}

.related-threats-table__row {
  &:hover {
    background: $grey-color-light-bg;
  }
}

.technique-logo {
  position: relative;
  top: -5px;
}

.tag-item {
  padding: 2px 6px;
  font-size: 12px;
  min-width: 40px;
  background: $grey-color-light-bg;
  margin: 4px 8px 4px 0;
  border-radius: 4px;
  border: 1px solid transparent;

  &.wrap {
    word-wrap: break-word;
  }

  &.active {
    color: $white-color;
    background: $theme-color !important;
  }

  &.rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    min-width: auto;
    border-radius: 50% !important;
  }

  &:hover {
    border: 1px solid $theme-color !important;
  }
}

.tag-item-dark {
  padding: 4px 8px;
  font-size: 12px;
  min-width: 40px;
  background: $dm-dark-grey !important;
  margin: 4px 8px 4px 0;
  border-radius: 4px;

  &:hover {
    p {
      color: $theme-color !important;
    }
  }
}