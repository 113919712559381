.admin-panel {
    background-color: rgb(177, 176, 176);
    color: #fff
}

.menu-item {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: rgb(88, 88, 88);
    margin-left: 15px;
}

.delete {
    color: red;
}

.dropdown-menu {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}