.companies-side {
	display: flex;
	flex-direction: column;
  padding: 0;
	font-size:12px;
	.card {
		border-radius: 2px;
		border: none;
		background: transparent;
		margin-bottom: 6px;
		.btn-link {
			width: 100%;
			display: -webkit-flex;
			display: -ms-flex;
			display: flex;
			padding-right: 0;
			margin: 0;
			justify-content: space-between;
			color: #333;
			font-size: 12px;
			text-align: left;
			text-decoration: none!important;
			&:hover {
				text-decoration: none;
			}
		}
	}

	.card-header {
		padding: 0;
		background: #fff;
		border: none;
	}

	.card-body {
		padding: 4px 0 0;
		button {
			margin: 2px 0;
			border: none;
			background: #fff;
			border-radius: 2px;
			width: 100%;
		}
	}
}

.companiesButton {
    text-align: left;
		background-color:#ebebeb !important;
		border-radius:4px;
		// border:1px solid #5d4df5;
		display:inline-block;
		cursor:pointer;
		color:#666666;
		font-size:12px;
		padding:5px 10px !important;
		text-decoration:none;
}

.companiesButton:active {
	position:relative;
	top:1px;
}
.companiesButton.active {
	background-color:#5d4df5 !important;
	color: white !important;
}

.titleButton {
    display: flex !important;
    text-align: left;
	background-color:#ffffff;
	border-radius:4px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666 !important;
	font-family: 'Open Sans', sans-serif;
	font-size:12px;
	font-weight:regular;
	padding:3px 10px !important;
    text-decoration:none;
}

.titleButton:active {
	position:relative;
	top:1px;
}
.titleButton.active {
	background-color:#5d4df5 !important;
	color: white !important;
}
