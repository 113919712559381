body {
  color: $theme-text-color;
  background: $grey-color-light-bg;
  font-family: $roboto;
  font-size: 14px;
  letter-spacing: 0.5px;
}

ul {
  li {
    font-family: $roboto;
    color: $theme-text-color;
  }
}

iframe {
  border-radius: 8px;
}

.no-wrap {
  white-space: nowrap;
}

.gap-1 {
  gap: 16px;
}

.transition {
  transition: 0.3s;
}

.app-content {
  padding-top: 60px;
}

.container {
  max-width: 1200px;
}

.border-none {
  border: none !important;
}

.w-md-auto {
  @media screen and (min-width: 768px) {
    width: auto !important;
  }
}

.w-sm-100 {
  @media screen and (max-width: 1199px) {
    width: 100% !important;
  }
}

.bgr-grey {
  background: $grey-color-light-bg !important;
}

.border {
  border: 1px solid #d2d2d2;
}

.border-radius-sm {
  border-radius: 10px;
}

.border-bottom {
  border-bottom: 1px solid #d2d2d2;
}

.border-left {
  border-left: 1px solid #d2d2d2;
}

.border-right {
  border-right: 1px solid #d2d2d2;
}

.box-shadow {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.bgr-light {
  background: #fff;
}

.background-transparent {
  background: transparent !important;
}

.full-container-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pointer {
  cursor: pointer;
}

.link-container {
  word-break: break-all;
}

.position-relative {
  position: relative;
}

.w-md-25 {
  @media screen and (min-width: 768px) {
    width: 25% !important;
  }
}

.w-md-50 {
  width: 100% !important;

  @media screen and (min-width: 991px) {
    width: 50% !important;
  }
}

.divider {
  display: block;
  text-align: center;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    background: $stroke-color;
    z-index: 0;
  }

  span {
    padding: 4px 8px;
    display: inline-block;
    background: $white-color;
    position: relative;
    z-index: 2;
    color: $stroke-color;
    text-transform: uppercase;
  }
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-xs-100 {
  @media screen and (max-width: 991px) {
    width: 100% !important;
  }
}

.w-lg-50 {
  @media screen and (min-width: 992px) {
    width: 50% !important;
  }
}

@media screen and (min-width: 1200px) {
  .w-xl-15 {
    width: 15% !important;
  }
}

@media screen and (min-width: 1200px) {
  .w-xl-35 {
    width: 35% !important;
  }
}

@media screen and (min-width: 1200px) {
  .w-xl-30 {
    width: 30% !important;
  }
}

@media screen and (min-width: 1200px) {
  .w-xl-40 {
    width: 40% !important;
  }
}

.gap {
  gap: 16px;
}

.gap-2 {
  gap: 28px;
}

.overflow-initial {
  overflow: initial !important;
}

.overright {
  font-size: 10px;
}

.stage-card-content-inner {

  &.high {
    background: rgba(253, 74, 25, 1) !important;
    color: #fff;
  }

  &.medium {
    background: rgba(253, 74, 25, .7) !important;
    color: #fff;
  }

  &.low {
    background: rgba(253, 74, 25, .3) !important;
  }
}

.color-legend {
  border-radius: 12px;
  padding: 2px 8px;
  line-height: 1;
  font-size: 12px;
  border: 1px solid #b7bac3;
  color: $black;

  &.high {
    background: rgba(253, 74, 25, 1) !important;
    color: #fff;
  }

  &.medium {
    background: rgba(253, 74, 25, .7) !important;
    color: #fff;
  }

  &.low {
    background: rgba(253, 74, 25, .3) !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #474747;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}