.options-section {
    max-width: 1040px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 0;
    margin-top: 30px;
}

.leftSide-options {
    font-size: 14px;
    flex-basis: 15%;
    padding: 0 15px;
    align-self: flex-end;
}

.center-options {
    font-size: 16px;
    font-weight: 600;
    flex-basis: 70%;
    padding: 0 10px;
}

.info-Stories {
    color: #AD6A05;
}
.info-Actors {
    color: #9F55A1;
}
.info-Threats {
    color: #FF4040;
}
.info-Solutions {
    color: #1DB534;
}
.info-Companies {
    color: #5D61E7;
}
.info-Experts {
    color: #15AFD1;
}
.info-Breaches {
    color: #E617D1;
}