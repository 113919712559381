.left-side {
	display: flex;
	flex-direction: column;
	padding:0 10px;
}

.sideFilter-button {
	display: block;
	width: 100%;
	margin-bottom: 5px;
	background-color: #ffffff;
	border: none;
	border-radius: 2px;
	display:inline-block;
	cursor:pointer;
	color: #333;
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	padding: 6px 10px;
	text-decoration:none;
	text-align: left;
	&:hover, &:focus, &:active {
		box-shadow: none;
		outline: none;
	}
	@media screen and (max-width: 767px) {
		font-size: 14px;
		border-radius: 0!important;
		margin-bottom: 0;
		padding: 8px 16px 10px;
		border-bottom: 1px solid #efefef;
		&:last-child {
			border-bottom: none;
		}
	}
}
/* .topFilter-button:hover {
	background-color:#555555;
} */
.sideFilter-button:active {
	position:relative;
	top:1px;
}
.sideFilter-button.active {
	background-color:#5d4df5 !important;
	color: white;
}

.filter-buttons {
	@media screen and (max-width: 767px) {
		display: none;
	}
}

.mobile-filters {
	.top-filters, .selected-buttons {
		@media screen and (max-width: 767px) {
  		display: block;
  	}
	}
	.dropdown-toggle {
		padding: 0;
		margin: 0;
		width: auto;
		&:after {
			content: normal;
		}
	}
	.filter-buttons {
		display: block;
		padding: 0;
	}

	h3 {
		padding: 32px 16px 16px;
		margin: 0;
		font-size: 24px;
		img {
			max-width: 16px;
			margin-right: 8px;
		}
		@media screen and (max-width: 767px) {
  		font-size: 18px;
			margin: 0;
			padding: 24px 16px 8px;
  	}
	}

	.dropdown-menu {
		display: block!important;
		visibility: hidden;
		position: fixed!important;
		left: -310px!important;
		top: 0!important;
		bottom: 0!important;
		background: #fff;
		width: 310px;
		transition: .3s;
		transform: none!important;
		margin: 0;
		padding-top: 30px;
		z-index: 2000;
		height: 100%;
		overflow: auto;
		overflow-x: hidden;
		&.show {
			visibility: visible;
			left: 0!important;
		}

		.dropdown-toggle {
			position: absolute;
	    right: 16px;
	    top: 16px;
	    width: 24px;
	    height: 24px;
	    opacity: 0.75;
	    text-indent: -99999px;
	    background: none;
			border: none;
			padding: 0;
	    cursor: pointer;
	    &:before, &:after {
	      position: absolute;
	      left: 15px;
	      content: ' ';
	      height: 33px;
	      width: 2px;
	      background-color: #333;
				border: none;
				margin: 0;
	    }
	    &:before {
	      transform: rotate(45deg);
	    }
	    &:after {
	      transform: rotate(-45deg);
	    }
		}
	}
}
