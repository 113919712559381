.pricing-section {
  padding: 110px 0;

  @media screen and (max-width: 767px) {
    padding: 100px 0;
  }
}

.billing-cycle {
  font-size: 16px;
}

.price-cycle {
  font-size: 12px;
  color: $theme-text-color-medium;
}

.price-discount {
  position: absolute;
  top: -10px;
  left: 0;
  display: flex;
  font-size: 12px;
  color: $red-color;
  line-height: 1;
  white-space: nowrap;

  @media screen and (max-width: 991px) {
    position: relative;
    top: 0;
  }

}


.plan-description {
  padding: 24px;
  border-radius: 16px;

  img {
    width: 100%;
    border-radius: 16px;
  }
}

.pricing-table {
  margin: 36px 0;
  padding: 0 48px;
  background: $white-color;
  border-radius: 16px;
  position: relative;

  &__title {
    font-size: 20px;
    font-weight: bold;
  }

  &__plan {
    text-align: left;
    font-size: 16px;
    text-transform: capitalize;
  }

  &__info-text {
    display: block;
    text-align: center;
    font-size: 16px;
    margin-top: auto;
  }

  &__info-icon {
    cursor: pointer;

    &.active {
      color: $theme-color;
    }

    &:hover {
      color: $theme-color;
    }
  }

  &__price {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    line-height: 1;

    sup {
      font-weight: 600;
      font-size: 14px;
      top: -16px;
    }

    @media screen and (max-width: 767px) {
      padding: 12px 0;
    }

    @media screen and (min-width: 768px) and (max-width: 1200px)  {
      font-size: 32px;
    }

    @media screen and (max-width: 992px)  {
      flex-flow: column;
    }

  }

  &__row {
    display: flex;

    &:last-of-type {
      .pricing-table__col {
        border-bottom: none;
      }
    }
  }

  &__col {
    display: flex;
    flex-flow: column;
    width: 33.333%;
    padding: 6px 16px;
    border-bottom: 1px solid $stroke-color;
    border-right: 1px solid $stroke-color;

    .btn {
      max-width: 240px;
      margin: auto auto 0;
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      border-right: none;
    }
  }
}

.pricing-card {
  width: 30%;
  padding: 16px;

  &-list {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 767px) {
      flex-flow: column;
    }
  }

  &__content {
    height: 100%;
    padding-bottom: 24px;
    background: $white-color;
    border-radius: 16px;
    text-align: center;
  }

  &__content-middle {
    height: 80px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  &__header {
    padding: 8px 12px;
    font-size: 20px;
    border-radius: 16px 16px 0 0;
    font-weight: 600;
  }

  &__price {
    font-size: 24px;
    font-weight: bold;
  }

  &__description {
    padding: 16px;
    border-top: 1px solid $stroke-color;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
}
